import React, {useEffect, useState} from "react";
import styles from './index.module.css';
import Logo from "../logo";
import {getString} from "../../localization";

function LinkSection() {
    const [footerStrings, setFooterStrings] = useState({});

    useEffect(() => {
        setFooterStrings(getString("footer"));
    }, []);

    const links = [
        {title: footerStrings?.destinations, url: '/'},
        {title: footerStrings?.about_us, url: '/'},
        {title: footerStrings?.contact_us, url: '/'},
    ];

    return (
        <div className={styles.linkContainer}>
            {links?.map((link, index) => {
                return (
                    <div key={index} className={styles.link}>
                        {link.title}
                    </div>
                )
            })}
        </div>
    )
}

function ContactSection() {
    const [footerStrings, setFooterStrings] = useState({});

    useEffect(() => {
        setFooterStrings(getString("footer"));
    }, []);

    return (
        <div className={styles.contactContainer}>
            <div className={styles.contactInfo}>
                <div style={{opacity: '60%'}}>{footerStrings.contact_us}</div>
                <div>+51 999 336 048</div>
            </div>
            <div className={styles.contactInfo}>
                <div style={{opacity: '60%'}}>{footerStrings.email_us}</div>
                <div>info@taysmundo.com</div>
            </div>
        </div>
    )
}

function SocialSection() {
    const socials = [
        {title: 'Twitter', url: '/', svg: () => {return (<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" stroke="#431F25"/>
                    <path d="M23.7887 40C20.5537 40 17.5382 39.0591 15 37.4356C17.155 37.5751 20.9581 37.2411 23.3236 34.9848C19.7651 34.8215 18.1603 32.0923 17.9509 30.926C18.2533 31.0426 19.6953 31.1826 20.5093 30.856C16.4159 29.8296 15.7879 26.2373 15.9275 25.141C16.695 25.6775 17.9975 25.8641 18.5091 25.8174C14.6948 23.0882 16.067 18.9828 16.7415 18.0963C19.4788 21.8887 23.5812 24.0186 28.6564 24.137C28.5607 23.7174 28.5102 23.2804 28.5102 22.8316C28.5102 19.6109 31.1134 17 34.3247 17C36.0025 17 37.5144 17.7128 38.5757 18.8528C39.6969 18.5901 41.3843 17.9751 42.2092 17.4432C41.7934 18.9361 40.4989 20.1815 39.7159 20.6431C39.7095 20.6273 39.7224 20.6588 39.7159 20.6431C40.4037 20.539 42.2648 20.1814 43 19.6826C42.6364 20.5213 41.264 21.9157 40.1377 22.6964C40.3473 31.9381 33.2765 40 23.7887 40Z" fill="#F1BC6B"/>
                </svg>
            )}},
        {title: 'Linked in', url: '/', svg: () => {return (<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" stroke="#431F25"/>
                    <rect x="14" y="14" width="28" height="28" rx="14" fill="#F1BC6B"/>
                    <path d="M24.6186 21.6921C24.6186 22.6267 23.8085 23.3843 22.8093 23.3843C21.81 23.3843 21 22.6267 21 21.6921C21 20.7576 21.81 20 22.8093 20C23.8085 20 24.6186 20.7576 24.6186 21.6921Z" fill="#78131F"/>
                    <path d="M21.2474 24.6281H24.3402V34H21.2474V24.6281Z" fill="#78131F"/>
                    <path d="M29.3196 24.6281H26.2268V34H29.3196C29.3196 34 29.3196 31.0496 29.3196 29.2049C29.3196 28.0976 29.6977 26.9855 31.2062 26.9855C32.911 26.9855 32.9008 28.4345 32.8928 29.5571C32.8824 31.0244 32.9072 32.5219 32.9072 34H36V29.0537C35.9738 25.8954 35.1508 24.4401 32.4433 24.4401C30.8354 24.4401 29.8387 25.1701 29.3196 25.8305V24.6281Z" fill="#78131F"/>
                </svg>
            )}},
        {title: 'Instagram', url: '/', svg: () => {return (<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" stroke="#431F25"/>
                    <rect x="14" y="14" width="28" height="28" rx="6" fill="url(#paint0_radial_5921_6216)"/>
                    <rect x="14" y="14" width="28" height="28" rx="6" fill="url(#paint1_radial_5921_6216)"/>
                    <rect x="14" y="14" width="28" height="28" rx="6" fill="#F1BC6B"/>
                    <path d="M35 22.5C35 23.3284 34.3284 24 33.5 24C32.6716 24 32 23.3284 32 22.5C32 21.6716 32.6716 21 33.5 21C34.3284 21 35 21.6716 35 22.5Z" fill="#78131F"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28 33C30.7614 33 33 30.7614 33 28C33 25.2386 30.7614 23 28 23C25.2386 23 23 25.2386 23 28C23 30.7614 25.2386 33 28 33ZM28 31C29.6569 31 31 29.6569 31 28C31 26.3431 29.6569 25 28 25C26.3431 25 25 26.3431 25 28C25 29.6569 26.3431 31 28 31Z" fill="#78131F"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 27.6C18 24.2397 18 22.5595 18.654 21.2761C19.2292 20.1471 20.1471 19.2292 21.2761 18.654C22.5595 18 24.2397 18 27.6 18H28.4C31.7603 18 33.4405 18 34.7239 18.654C35.8529 19.2292 36.7708 20.1471 37.346 21.2761C38 22.5595 38 24.2397 38 27.6V28.4C38 31.7603 38 33.4405 37.346 34.7239C36.7708 35.8529 35.8529 36.7708 34.7239 37.346C33.4405 38 31.7603 38 28.4 38H27.6C24.2397 38 22.5595 38 21.2761 37.346C20.1471 36.7708 19.2292 35.8529 18.654 34.7239C18 33.4405 18 31.7603 18 28.4V27.6ZM27.6 20H28.4C30.1132 20 31.2777 20.0016 32.1779 20.0751C33.0548 20.1467 33.5032 20.2766 33.816 20.436C34.5686 20.8195 35.1805 21.4314 35.564 22.184C35.7234 22.4968 35.8533 22.9452 35.9249 23.8221C35.9984 24.7223 36 25.8868 36 27.6V28.4C36 30.1132 35.9984 31.2777 35.9249 32.1779C35.8533 33.0548 35.7234 33.5032 35.564 33.816C35.1805 34.5686 34.5686 35.1805 33.816 35.564C33.5032 35.7234 33.0548 35.8533 32.1779 35.9249C31.2777 35.9984 30.1132 36 28.4 36H27.6C25.8868 36 24.7223 35.9984 23.8221 35.9249C22.9452 35.8533 22.4968 35.7234 22.184 35.564C21.4314 35.1805 20.8195 34.5686 20.436 33.816C20.2766 33.5032 20.1467 33.0548 20.0751 32.1779C20.0016 31.2777 20 30.1132 20 28.4V27.6C20 25.8868 20.0016 24.7223 20.0751 23.8221C20.1467 22.9452 20.2766 22.4968 20.436 22.184C20.8195 21.4314 21.4314 20.8195 22.184 20.436C22.4968 20.2766 22.9452 20.1467 23.8221 20.0751C24.7223 20.0016 25.8868 20 27.6 20Z" fill="#78131F"/>
                    <defs>
                        <radialGradient id="paint0_radial_5921_6216" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24 35) rotate(-55.3758) scale(25.5196)">
                            <stop stop-color="#B13589"/>
                            <stop offset="0.79309" stop-color="#C62F94"/>
                            <stop offset="1" stop-color="#8A3AC8"/>
                        </radialGradient>
                        <radialGradient id="paint1_radial_5921_6216" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23 43) rotate(-65.1363) scale(22.5942)">
                            <stop stop-color="#E0E8B7"/>
                            <stop offset="0.444662" stop-color="#FB8A2E"/>
                            <stop offset="0.71474" stop-color="#E2425C"/>
                            <stop offset="1" stop-color="#E2425C" stop-opacity="0"/>
                        </radialGradient>
                    </defs>
                </svg>
            )}},
        {title: 'Facebook', url: '/', svg: () => {return (<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" stroke="#431F25"/>
                    <circle cx="28" cy="28" r="14" fill="#F1BC6B"/>
                    <path d="M33.2137 32.2816L33.8356 28.3301H29.9452V25.767C29.9452 24.6857 30.4877 23.6311 32.2302 23.6311H34V20.267C34 20.267 32.3945 20 30.8603 20C27.6548 20 25.5617 21.8929 25.5617 25.3184V28.3301H22V32.2816H25.5617V41.8345C26.2767 41.944 27.0082 42 27.7534 42C28.4986 42 29.2302 41.944 29.9452 41.8345V32.2816H33.2137Z" fill="#78131F"/>
                </svg>
            )}},
    ]

    return (
        <div className={styles.socialContainer}>
            {socials?.map((social, index) => {
                return (
                    <div key={index} className={styles.social}>
                        {social.svg()}
                    </div>
                )})
            }
        </div>
    )
}

export default function Footer() {
    return (
        <div className={styles.container}>
            <svg width="1440" height="65" viewBox="0 0 1440 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="-41.5" y="0.5" width="1524" height="64" stroke="#69321B"/>
                <rect x="14" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="-23" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="-23" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="-16" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="-16" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="28" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="72" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="35" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="35" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="42" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="42" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="42" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="86" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="130" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="93" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="93" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="100" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="100" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="100" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="144" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="188" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="151" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="151" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="158" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="158" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="158" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="202" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="246" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="209" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="209" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="216" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="216" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="216" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="260" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="304" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="267" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="267" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="274" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="274" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="274" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="318" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="362" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="325" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="325" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="332" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="332" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="332" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="376" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="420" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="383" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="383" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="390" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="390" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="390" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="434" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="478" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="441" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="441" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="448" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="448" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="448" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="492" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="536" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="499" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="499" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="506" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="506" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="506" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="550" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="594" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="557" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="557" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="564" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="564" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="564" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="608" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="652" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="615" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="615" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="622" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="622" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="622" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="666" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="710" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="673" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="673" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="680" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="680" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="680" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="724" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="768" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="731" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="731" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="738" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="738" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="738" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="782" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="826" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="789" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="789" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="796" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="796" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="796" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="840" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="884" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="847" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="847" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="854" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="854" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="854" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="898" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="942" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="905" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="905" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="912" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="912" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="912" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="956" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1000" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="963" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="963" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="970" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="970" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="970" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1014" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1058" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1021" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1021" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1028" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1028" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1028" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1072" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1116" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1079" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1079" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1086" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1086" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1086" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1130" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1174" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1137" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1137" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1144" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1144" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1144" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1188" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1232" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1195" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1195" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1202" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1202" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1202" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1246" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1290" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1253" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1253" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1260" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1260" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1260" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1304" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1348" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1311" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1311" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1318" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1318" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1318" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1362" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1406" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1369" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1369" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1376" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1376" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1376" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1420" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1427" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1427" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1434" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1434" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1434" y="42" width="7" height="9" fill="#69321B"/>
            </svg>
            <div style={{padding: '50px 0'}}><Logo width={'300px'}/></div>
            <LinkSection/>
            <ContactSection/>
            {/*<SocialSection/>*/}
            <div style={{
                display: "flex", justifyContent: 'space-between'
            }}>
                <svg width="527" height="72" viewBox="0 0 527 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5948_3217)">
                        <path d="M471.585 7.41072C470.233 8.58405 468.918 9.79294 467.638 11.0374C469.931 14.2374 472.651 16.7441 475.318 19.5174C478.518 17.3307 480.865 14.5574 483.531 11.8374C480.545 9.59738 477.931 6.77072 475.158 4.31738C474.038 5.43738 472.758 6.34405 471.585 7.41072Z" fill="#69321B"/>
                        <path d="M510.948 7.41072C509.597 8.58405 508.281 9.79294 507.001 11.0374C509.295 14.2374 512.015 16.7441 514.681 19.5174C517.881 17.3307 520.228 14.5574 522.895 11.8374C519.908 9.59738 517.295 6.77072 514.521 4.31738C513.401 5.43738 512.121 6.34405 510.948 7.41072Z" fill="#69321B"/>
                        <path d="M471.585 30.8248C470.233 31.9981 468.918 33.2248 467.638 34.5048C469.931 37.7048 472.651 40.1581 475.318 42.9848C478.518 40.7448 480.865 37.9714 483.531 35.2514C480.545 33.0648 477.931 30.2381 475.158 27.7314C474.038 28.8514 472.758 29.8114 471.585 30.8248Z" fill="#69321B"/>
                        <path d="M510.948 30.8248C509.597 31.9981 508.281 33.2248 507.001 34.5048C509.295 37.7048 512.015 40.1581 514.681 42.9848C517.881 40.7448 520.228 37.9714 522.895 35.2514C519.908 33.0648 517.295 30.2381 514.521 27.7314C513.401 28.8514 512.121 29.8114 510.948 30.8248Z" fill="#69321B"/>
                        <path d="M485.341 23.9443C488.328 27.251 491.421 30.451 494.248 33.811C497.394 30.6643 500.488 27.0377 504.008 24.2643C497.821 24.4777 491.528 24.1577 485.341 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M494.357 6.98438C489.664 12.7977 484.01 18.0777 478.837 23.4644C484.597 28.1577 488.917 34.291 494.41 39.251C494.41 39.1977 494.41 39.1977 494.464 39.1444C499.744 33.7577 504.97 28.2644 510.57 23.1444C507.797 20.4777 505.29 17.491 502.57 14.771C499.904 12.1044 497.077 9.59771 494.357 6.98438ZM483.157 23.8377C482.73 23.3577 483.05 22.7177 483.477 22.5577C483.584 22.5044 483.69 22.451 483.85 22.451C490.944 22.611 498.09 22.5044 505.13 23.1444C505.717 23.1977 505.824 23.8377 505.45 24.1044C502.517 28.1577 498.41 31.6244 494.944 35.1444C494.837 35.2155 494.73 35.2688 494.624 35.3044C494.41 35.411 494.197 35.411 493.984 35.1977C490.09 31.7844 486.57 27.731 483.157 23.8377Z" fill="#69321B"/>
                        <path d="M432.061 7.41072C430.71 8.58405 429.394 9.79294 428.114 11.0374C430.408 14.2374 433.128 16.7441 435.794 19.5174C438.994 17.3307 441.341 14.5574 444.008 11.8374C441.021 9.59738 438.408 6.77072 435.634 4.31738C434.514 5.43738 433.234 6.34405 432.061 7.41072Z" fill="#69321B"/>
                        <path d="M432.061 30.8248C430.71 31.9981 429.394 33.2248 428.114 34.5048C430.408 37.7048 433.128 40.1581 435.794 42.9848C438.994 40.7448 441.341 37.9714 444.008 35.2514C441.021 33.0648 438.408 30.2381 435.634 27.7314C434.514 28.8514 433.234 29.8114 432.061 30.8248Z" fill="#69321B"/>
                        <path d="M445.818 23.9443C448.804 27.251 451.898 30.451 454.724 33.811C457.871 30.6643 460.964 27.0377 464.484 24.2643C458.298 24.4777 452.004 24.1577 445.818 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M454.834 6.98438C450.14 12.7977 444.487 18.0777 439.314 23.4644C445.074 28.1577 449.394 34.291 454.887 39.251C454.887 39.1977 454.887 39.1977 454.94 39.1444C460.22 33.7577 465.447 28.2644 471.047 23.1444C468.274 20.4777 465.767 17.491 463.047 14.771C460.38 12.1044 457.554 9.59771 454.834 6.98438ZM443.634 23.8377C443.207 23.3577 443.527 22.7177 443.954 22.5577C444.06 22.5044 444.167 22.451 444.327 22.451C451.42 22.611 458.567 22.5044 465.607 23.1444C466.194 23.1977 466.3 23.8377 465.927 24.1044C462.994 28.1577 458.887 31.6244 455.42 35.1444C455.314 35.2155 455.207 35.2688 455.1 35.3044C454.887 35.411 454.674 35.411 454.46 35.1977C450.567 31.7844 447.047 27.731 443.634 23.8377Z" fill="#69321B"/>
                        <path d="M392.546 7.41072C391.194 8.58405 389.879 9.79294 388.599 11.0374C390.892 14.2374 393.612 16.7441 396.279 19.5174C399.479 17.3307 401.826 14.5574 404.492 11.8374C401.506 9.59738 398.892 6.77072 396.119 4.31738C394.999 5.43738 393.719 6.34405 392.546 7.41072Z" fill="#69321B"/>
                        <path d="M392.546 30.8248C391.194 31.9981 389.879 33.2248 388.599 34.5048C390.892 37.7048 393.612 40.1581 396.279 42.9848C399.479 40.7448 401.826 37.9714 404.492 35.2514C401.506 33.0648 398.892 30.2381 396.119 27.7314C394.999 28.8514 393.719 29.8114 392.546 30.8248Z" fill="#69321B"/>
                        <path d="M406.302 23.9443C409.289 27.251 412.382 30.451 415.209 33.811C418.355 30.6643 421.449 27.0377 424.969 24.2643C418.782 24.4777 412.489 24.1577 406.302 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M415.318 6.98438C410.625 12.7977 404.971 18.0777 399.798 23.4644C405.558 28.1577 409.878 34.291 415.371 39.251C415.371 39.1977 415.371 39.1977 415.425 39.1444C420.705 33.7577 425.931 28.2644 431.531 23.1444C428.758 20.4777 426.251 17.491 423.531 14.771C420.865 12.1044 418.038 9.59771 415.318 6.98438ZM404.118 23.8377C403.691 23.3577 404.011 22.7177 404.438 22.5577C404.545 22.5044 404.651 22.451 404.811 22.451C411.905 22.611 419.051 22.5044 426.091 23.1444C426.678 23.1977 426.785 23.8377 426.411 24.1044C423.478 28.1577 419.371 31.6244 415.905 35.1444C415.798 35.2155 415.691 35.2688 415.585 35.3044C415.371 35.411 415.158 35.411 414.945 35.1977C411.051 31.7844 407.531 27.731 404.118 23.8377Z" fill="#69321B"/>
                        <path d="M353.022 7.41072C351.671 8.58405 350.355 9.79294 349.075 11.0374C351.369 14.2374 354.089 16.7441 356.755 19.5174C359.955 17.3307 362.302 14.5574 364.969 11.8374C361.982 9.59738 359.369 6.77072 356.595 4.31738C355.475 5.43738 354.195 6.34405 353.022 7.41072Z" fill="#69321B"/>
                        <path d="M353.022 30.8248C351.671 31.9981 350.355 33.2248 349.075 34.5048C351.369 37.7048 354.089 40.1581 356.755 42.9848C359.955 40.7448 362.302 37.9714 364.969 35.2514C361.982 33.0648 359.369 30.2381 356.595 27.7314C355.475 28.8514 354.195 29.8114 353.022 30.8248Z" fill="#69321B"/>
                        <path d="M366.779 23.9443C369.765 27.251 372.859 30.451 375.685 33.811C378.832 30.6643 381.925 27.0377 385.445 24.2643C379.259 24.4777 372.965 24.1577 366.779 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M375.794 6.98438C371.101 12.7977 365.448 18.0777 360.274 23.4644C366.034 28.1577 370.354 34.291 375.848 39.251C375.848 39.1977 375.848 39.1977 375.901 39.1444C381.181 33.7577 386.408 28.2644 392.008 23.1444C389.234 20.4777 386.728 17.491 384.008 14.771C381.341 12.1044 378.514 9.59771 375.794 6.98438ZM364.594 23.8377C364.168 23.3577 364.488 22.7177 364.914 22.5577C365.021 22.5044 365.128 22.451 365.288 22.451C372.381 22.611 379.528 22.5044 386.568 23.1444C387.154 23.1977 387.261 23.8377 386.888 24.1044C383.954 28.1577 379.848 31.6244 376.381 35.1444C376.274 35.2155 376.168 35.2688 376.061 35.3044C375.848 35.411 375.634 35.411 375.421 35.1977C371.528 31.7844 368.008 27.731 364.594 23.8377Z" fill="#69321B"/>
                        <path d="M313.506 7.41072C312.155 8.58405 310.84 9.79294 309.56 11.0374C311.853 14.2374 314.573 16.7441 317.293 19.5174C320.44 17.3307 322.786 14.5574 325.453 11.8374C322.52 9.59738 319.853 6.77072 317.133 4.31738C316.013 5.43738 314.68 6.34405 313.506 7.41072Z" fill="#69321B"/>
                        <path d="M313.506 30.8248C312.155 31.9981 310.84 33.2248 309.56 34.5048C311.853 37.7048 314.573 40.1581 317.293 42.9848C320.44 40.7448 322.786 37.9714 325.453 35.2514C322.52 33.0648 319.853 30.2381 317.133 27.7314C316.013 28.8514 314.68 29.8114 313.506 30.8248Z" fill="#69321B"/>
                        <path d="M327.263 23.9443C330.25 27.251 333.396 30.451 336.17 33.811C339.316 30.6643 342.41 27.0377 345.93 24.2643C339.743 24.4777 333.503 24.1577 327.263 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M336.279 6.98438C331.586 12.7977 325.986 18.0777 320.812 23.4644C326.519 28.1577 330.839 34.291 336.332 39.251C336.332 39.1977 336.332 39.1977 336.386 39.1444C341.666 33.7577 346.892 28.2644 352.492 23.1444C349.719 20.4777 347.212 17.491 344.546 14.771C341.879 12.1044 338.999 9.59771 336.279 6.98438ZM325.079 23.8377C324.652 23.3577 324.972 22.7177 325.452 22.5577C325.559 22.5044 325.666 22.451 325.772 22.451C332.866 22.611 340.012 22.5044 347.052 23.1444C347.639 23.1977 347.746 23.8377 347.372 24.1044C344.439 28.1577 340.332 31.6244 336.866 35.1444C336.759 35.2155 336.652 35.2688 336.546 35.3044C336.386 35.411 336.119 35.411 335.906 35.1977C332.012 31.7844 328.546 27.731 325.079 23.8377Z" fill="#69321B"/>
                        <path d="M273.983 7.41072C272.632 8.58405 271.316 9.79294 270.036 11.0374C272.33 14.2374 275.05 16.7441 277.77 19.5174C280.916 17.3307 283.263 14.5574 285.93 11.8374C282.996 9.59738 280.33 6.77072 277.61 4.31738C276.49 5.43738 275.156 6.34405 273.983 7.41072Z" fill="#69321B"/>
                        <path d="M273.983 30.8248C272.632 31.9981 271.316 33.2248 270.036 34.5048C272.33 37.7048 275.05 40.1581 277.77 42.9848C280.916 40.7448 283.263 37.9714 285.93 35.2514C282.996 33.0648 280.33 30.2381 277.61 27.7314C276.49 28.8514 275.156 29.8114 273.983 30.8248Z" fill="#69321B"/>
                        <path d="M287.74 23.9443C290.726 27.251 293.873 30.451 296.646 33.811C299.793 30.6643 302.886 27.0377 306.406 24.2643C300.22 24.4777 293.98 24.1577 287.74 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M296.755 6.98438C292.062 12.7977 286.462 18.0777 281.289 23.4644C286.995 28.1577 291.315 34.291 296.809 39.251C296.809 39.1977 296.809 39.1977 296.862 39.1444C302.142 33.7577 307.369 28.2644 312.969 23.1444C310.195 20.4777 307.689 17.491 305.022 14.771C302.355 12.1044 299.475 9.59771 296.755 6.98438ZM285.555 23.8377C285.129 23.3577 285.449 22.7177 285.929 22.5577C286.035 22.5044 286.142 22.451 286.249 22.451C293.342 22.611 300.489 22.5044 307.529 23.1444C308.115 23.1977 308.222 23.8377 307.849 24.1044C304.915 28.1577 300.809 31.6244 297.342 35.1444C297.235 35.2155 297.129 35.2688 297.022 35.3044C296.862 35.411 296.595 35.411 296.382 35.1977C292.489 31.7844 289.022 27.731 285.555 23.8377Z" fill="#69321B"/>
                        <path d="M234.46 7.41072C233.108 8.58405 231.793 9.79294 230.513 11.0374C232.806 14.2374 235.526 16.7441 238.246 19.5174C241.393 17.3307 243.74 14.5574 246.406 11.8374C243.473 9.59738 240.806 6.77072 238.086 4.31738C236.966 5.43738 235.633 6.34405 234.46 7.41072Z" fill="#69321B"/>
                        <path d="M234.46 30.8248C233.108 31.9981 231.793 33.2248 230.513 34.5048C232.806 37.7048 235.526 40.1581 238.246 42.9848C241.393 40.7448 243.74 37.9714 246.406 35.2514C243.473 33.0648 240.806 30.2381 238.086 27.7314C236.966 28.8514 235.633 29.8114 234.46 30.8248Z" fill="#69321B"/>
                        <path d="M248.224 23.9443C251.211 27.251 254.357 30.451 257.131 33.811C260.277 30.6643 263.371 27.0377 266.891 24.2643C260.704 24.4777 254.464 24.1577 248.224 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M257.24 6.98438C252.546 12.7977 246.946 18.0777 241.773 23.4644C247.48 28.1577 251.8 34.291 257.293 39.251C257.293 39.1977 257.293 39.1977 257.346 39.1444C262.626 33.7577 267.853 28.2644 273.453 23.1444C270.68 20.4777 268.173 17.491 265.506 14.771C262.84 12.1044 259.96 9.59771 257.24 6.98438ZM246.04 23.8377C245.613 23.3577 245.933 22.7177 246.413 22.5577C246.52 22.5044 246.626 22.451 246.733 22.451C253.826 22.611 260.973 22.5044 268.013 23.1444C268.6 23.1977 268.706 23.8377 268.333 24.1044C265.4 28.1577 261.293 31.6244 257.826 35.1444C257.72 35.2155 257.613 35.2688 257.506 35.3044C257.346 35.411 257.08 35.411 256.866 35.1977C252.973 31.7844 249.506 27.731 246.04 23.8377Z" fill="#69321B"/>
                        <path d="M194.944 7.41072C193.593 8.58405 192.295 9.79294 191.051 11.0374C193.291 14.2374 196.064 16.7441 198.731 19.5174C201.877 17.3307 204.224 14.5574 206.891 11.8374C203.957 9.59738 201.291 6.77072 198.571 4.31738C197.451 5.43738 196.117 6.34405 194.944 7.41072Z" fill="#69321B"/>
                        <path d="M194.944 30.8248C193.593 31.9981 192.295 33.2248 191.051 34.5048C193.291 37.7048 196.064 40.1581 198.731 42.9848C201.877 40.7448 204.224 37.9714 206.891 35.2514C203.957 33.0648 201.291 30.2381 198.571 27.7314C197.451 28.8514 196.117 29.8114 194.944 30.8248Z" fill="#69321B"/>
                        <path d="M208.7 23.9443C211.687 27.251 214.834 30.451 217.661 33.811C220.754 30.6643 223.847 27.0377 227.367 24.2643C221.181 24.4777 214.94 24.1577 208.7 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M217.77 6.98438C213.023 12.7977 207.423 18.0777 202.25 23.4644C207.956 28.1577 212.276 34.291 217.77 39.251C217.823 39.1977 217.823 39.1977 217.823 39.1444C223.103 33.7577 228.33 28.2644 233.93 23.1444C231.156 20.4777 228.703 17.491 225.983 14.771C223.316 12.1044 220.436 9.59771 217.77 6.98438ZM206.516 23.8377C206.143 23.3577 206.41 22.7177 206.89 22.5577C206.996 22.5044 207.103 22.451 207.263 22.451C214.303 22.611 221.45 22.5044 228.49 23.1444C229.076 23.1977 229.183 23.8377 228.81 24.1044C225.93 28.1577 221.77 31.6244 218.303 35.1444C218.196 35.2155 218.09 35.2688 217.983 35.3044C217.823 35.411 217.556 35.411 217.343 35.1977C213.45 31.7844 209.983 27.731 206.516 23.8377Z" fill="#69321B"/>
                        <path d="M155.421 7.41072C154.069 8.58405 152.772 9.79294 151.527 11.0374C153.767 14.2374 156.541 16.7441 159.207 19.5174C162.354 17.3307 164.701 14.5574 167.367 11.8374C164.434 9.59738 161.767 6.77072 159.047 4.31738C157.927 5.43738 156.594 6.34405 155.421 7.41072Z" fill="#69321B"/>
                        <path d="M155.421 30.8248C154.069 31.9981 152.772 33.2248 151.527 34.5048C153.767 37.7048 156.541 40.1581 159.207 42.9848C162.354 40.7448 164.701 37.9714 167.367 35.2514C164.434 33.0648 161.767 30.2381 159.047 27.7314C157.927 28.8514 156.594 29.8114 155.421 30.8248Z" fill="#69321B"/>
                        <path d="M169.177 23.9443C172.164 27.251 175.31 30.451 178.137 33.811C181.23 30.6643 184.324 27.0377 187.844 24.2643C181.657 24.4777 175.417 24.1577 169.177 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M178.246 6.98438C173.5 12.7977 167.9 18.0777 162.726 23.4644C168.433 28.1577 172.753 34.291 178.246 39.251C178.3 39.1977 178.3 39.1977 178.3 39.1444C183.58 33.7577 188.806 28.2644 194.406 23.1444C191.633 20.4777 189.18 17.491 186.46 14.771C183.793 12.1044 180.913 9.59771 178.246 6.98438ZM166.993 23.8377C166.62 23.3577 166.886 22.7177 167.366 22.5577C167.473 22.5044 167.58 22.451 167.74 22.451C174.78 22.611 181.926 22.5044 188.966 23.1444C189.553 23.1977 189.66 23.8377 189.286 24.1044C186.406 28.1577 182.246 31.6244 178.78 35.1444C178.673 35.2155 178.566 35.2688 178.46 35.3044C178.3 35.411 178.033 35.411 177.82 35.1977C173.926 31.7844 170.46 27.731 166.993 23.8377Z" fill="#69321B"/>
                        <path d="M115.905 7.41072C114.554 8.58405 113.256 9.79294 112.012 11.0374C114.252 14.2374 117.025 16.7441 119.692 19.5174C122.838 17.3307 125.185 14.5574 127.852 11.8374C124.918 9.59738 122.252 6.77072 119.532 4.31738C118.412 5.43738 117.078 6.34405 115.905 7.41072Z" fill="#69321B"/>
                        <path d="M115.905 30.8248C114.554 31.9981 113.256 33.2248 112.012 34.5048C114.252 37.7048 117.025 40.1581 119.692 42.9848C122.838 40.7448 125.185 37.9714 127.852 35.2514C124.918 33.0648 122.252 30.2381 119.532 27.7314C118.412 28.8514 117.078 29.8114 115.905 30.8248Z" fill="#69321B"/>
                        <path d="M129.661 23.9443C132.648 27.251 135.795 30.451 138.621 33.811C141.715 30.6643 144.808 27.0377 148.328 24.2643C142.141 24.4777 135.901 24.1577 129.661 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M138.731 6.98438C133.984 12.7977 128.384 18.0777 123.211 23.4644C128.917 28.1577 133.237 34.291 138.731 39.251C138.784 39.1977 138.784 39.1977 138.784 39.1444C144.064 33.7577 149.291 28.2644 154.891 23.1444C152.117 20.4777 149.664 17.491 146.944 14.771C144.277 12.1044 141.397 9.59771 138.731 6.98438ZM127.477 23.8377C127.104 23.3577 127.371 22.7177 127.851 22.5577C127.957 22.5044 128.064 22.451 128.224 22.451C135.264 22.611 142.411 22.5044 149.451 23.1444C150.037 23.1977 150.144 23.8377 149.771 24.1044C146.891 28.1577 142.731 31.6244 139.264 35.1444C139.157 35.2155 139.051 35.2688 138.944 35.3044C138.784 35.411 138.517 35.411 138.304 35.1977C134.411 31.7844 130.944 27.731 127.477 23.8377Z" fill="#69321B"/>
                        <path d="M76.3814 7.41072C75.0303 8.58405 73.7326 9.79294 72.4882 11.0374C74.7282 14.2374 77.5014 16.7441 80.1681 19.5174C83.3148 17.3307 85.6615 14.5574 88.3281 11.8374C85.3948 9.59738 82.7281 6.77072 80.0081 4.31738C78.8881 5.43738 77.5548 6.34405 76.3814 7.41072Z" fill="#69321B"/>
                        <path d="M76.3814 30.8248C75.0303 31.9981 73.7326 33.2248 72.4882 34.5048C74.7282 37.7048 77.5014 40.1581 80.1681 42.9848C83.3148 40.7448 85.6615 37.9714 88.3281 35.2514C85.3948 33.0648 82.7281 30.2381 80.0081 27.7314C78.8881 28.8514 77.5548 29.8114 76.3814 30.8248Z" fill="#69321B"/>
                        <path d="M90.138 23.9443C93.1247 27.251 96.2713 30.451 99.098 33.811C102.191 30.6643 105.285 27.0377 108.805 24.2643C102.618 24.4777 96.378 24.1577 90.138 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M99.2072 6.98438C94.4606 12.7977 88.8606 18.0777 83.6872 23.4644C89.3939 28.1577 93.7139 34.291 99.2072 39.251C99.2606 39.1977 99.2605 39.1977 99.2605 39.1444C104.541 33.7577 109.767 28.2644 115.367 23.1444C112.594 20.4777 110.141 17.491 107.421 14.771C104.754 12.1044 101.874 9.59771 99.2072 6.98438ZM87.9539 23.8377C87.5806 23.3577 87.8472 22.7177 88.3272 22.5577C88.4339 22.5044 88.5405 22.451 88.7005 22.451C95.7405 22.611 102.887 22.5044 109.927 23.1444C110.514 23.1977 110.621 23.8377 110.247 24.1044C107.367 28.1577 103.207 31.6244 99.7406 35.1444C99.6339 35.2155 99.5272 35.2688 99.4206 35.3044C99.2606 35.411 98.9939 35.411 98.7805 35.1977C94.8872 31.7844 91.4206 27.731 87.9539 23.8377Z" fill="#69321B"/>
                        <path d="M36.8658 7.41072C35.5147 8.58405 34.2169 9.79294 32.9725 11.0374C35.2125 14.2374 37.9858 16.7441 40.6525 19.5174C43.7991 17.3307 46.1992 14.5574 48.8125 11.8374C45.8792 9.59738 43.2125 6.77072 40.4925 4.31738C39.3725 5.43738 38.0925 6.34405 36.8658 7.41072Z" fill="#69321B"/>
                        <path d="M36.8658 30.8248C35.5147 31.9981 34.2169 33.2248 32.9725 34.5048C35.2125 37.7048 37.9858 40.1581 40.6525 42.9848C43.7991 40.7448 46.1992 37.9714 48.8125 35.2514C45.8792 33.0648 43.2125 30.2381 40.4925 27.7314C39.3725 28.8514 38.0925 29.8114 36.8658 30.8248Z" fill="#69321B"/>
                        <path d="M50.6757 23.9443C53.6624 27.251 56.7557 30.451 59.5824 33.811C62.6757 30.6643 65.8224 27.0377 69.2891 24.2643C63.1024 24.4777 56.8624 24.1577 50.6757 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M59.6916 6.98438C54.9983 12.7977 49.3449 18.0777 44.1715 23.4644C49.9315 28.1577 54.2516 34.291 59.6916 39.251C59.7449 39.1977 59.7449 39.1977 59.7449 39.1444C65.0783 33.7577 70.2516 28.2644 75.8516 23.1444C73.1316 20.4777 70.6249 17.491 67.9049 14.771C65.2382 12.1044 62.3583 9.59771 59.6916 6.98438ZM48.4916 23.8377C48.0649 23.3577 48.3849 22.7177 48.8116 22.5577C48.9183 22.5044 49.0249 22.451 49.1849 22.451C56.2249 22.611 63.4249 22.5044 70.4649 23.1444C70.9982 23.1977 71.1049 23.8377 70.7316 24.1044C67.8516 28.1577 63.7449 31.6244 60.2249 35.1444C60.1183 35.2155 60.0115 35.2688 59.9049 35.3044C59.7449 35.411 59.5316 35.411 59.3182 35.1977C55.3716 31.7844 51.9049 27.731 48.4916 23.8377Z" fill="#69321B"/>
                        <path d="M-2.65762 7.41072C-3.97317 8.58405 -5.27098 9.79294 -6.55098 11.0374C-4.31098 14.2374 -1.53763 16.7441 1.12904 19.5174C4.2757 17.3307 6.67573 14.5574 9.28906 11.8374C6.35573 9.59738 3.68901 6.77072 0.96901 4.31738C-0.15099 5.43738 -1.43095 6.34405 -2.65762 7.41072Z" fill="#69321B"/>
                        <path d="M-2.65762 30.8248C-3.97317 31.9981 -5.27098 33.2248 -6.55098 34.5048C-4.31098 37.7048 -1.53763 40.1581 1.12904 42.9848C4.2757 40.7448 6.67573 37.9714 9.28906 35.2514C6.35573 33.0648 3.68901 30.2381 0.96901 27.7314C-0.15099 28.8514 -1.43095 29.8114 -2.65762 30.8248Z" fill="#69321B"/>
                        <path d="M11.1523 23.9443C14.139 27.251 17.2323 30.451 20.059 33.811C23.1523 30.6643 26.299 27.0377 29.7656 24.2643C23.579 24.4777 17.339 24.1577 11.1523 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1681 6.98438C15.4748 12.7977 9.82144 18.0777 4.64811 23.4644C10.4081 28.1577 14.7281 34.291 20.1681 39.251C20.2214 39.1977 20.2215 39.1977 20.2215 39.1444C25.5548 33.7577 30.7281 28.2644 36.3281 23.1444C33.6081 20.4777 31.1014 17.491 28.3814 14.771C25.7148 12.1044 22.8348 9.59771 20.1681 6.98438ZM8.9681 23.8377C8.54143 23.3577 8.86148 22.7177 9.28815 22.5577C9.39482 22.5044 9.50146 22.451 9.66146 22.451C16.7015 22.611 23.9015 22.5044 30.9415 23.1444C31.4748 23.1977 31.5815 23.8377 31.2081 24.1044C28.3281 28.1577 24.2214 31.6244 20.7014 35.1444C20.5948 35.2155 20.4881 35.2688 20.3814 35.3044C20.2214 35.411 20.0081 35.411 19.7948 35.1977C15.8481 31.7844 12.3814 27.731 8.9681 23.8377Z" fill="#69321B"/>
                        <path d="M451.639 54.5029C444.599 54.9829 437.399 54.8229 430.306 54.8229C433.613 58.6096 437.133 62.0763 440.653 65.6496C444.119 61.9163 447.746 58.0763 451.746 54.8229C451.675 54.7163 451.639 54.6096 451.639 54.5029Z" fill="#BE6B1D"/>
                        <path d="M474.893 54.5029C467.8 54.9829 460.653 54.8229 453.56 54.8229C456.867 58.6096 460.333 62.0763 463.907 65.6496C467.373 61.9163 471 58.0763 475 54.8229C474.929 54.7163 474.893 54.6096 474.893 54.5029Z" fill="#BE6B1D"/>
                        <path d="M498.625 54.4502C491.425 55.0369 484.065 54.8235 476.812 54.8235C480.065 58.6102 483.585 62.0769 487.105 65.6502C490.732 61.7569 494.465 57.7569 498.625 54.4502Z" fill="#BE6B1D"/>
                        <path d="M382.413 54.5029C375.32 54.9829 368.173 54.8229 361.08 54.8229C364.386 58.6096 367.853 62.0763 371.426 65.6496C374.893 61.9163 378.52 58.0763 382.52 54.8229C382.448 54.7163 382.413 54.6096 382.413 54.5029Z" fill="#BE6B1D"/>
                        <path d="M405.613 54.5029C398.573 54.9829 391.427 54.8229 384.333 54.8229C387.587 58.6096 391.107 62.0763 394.627 65.6496C398.147 61.9163 401.773 58.0763 405.773 54.8229C405.702 54.7163 405.649 54.6096 405.613 54.5029Z" fill="#BE6B1D"/>
                        <path d="M429.398 54.4502C422.198 55.0369 414.838 54.8235 407.585 54.8235C410.838 58.6102 414.358 62.0769 417.878 65.6502C421.505 61.7569 425.238 57.7569 429.398 54.4502Z" fill="#BE6B1D"/>
                        <path d="M313.133 54.5029C306.093 54.9829 298.946 54.8229 291.853 54.8229C295.16 58.6096 298.626 62.0763 302.146 65.6496C305.666 61.9163 309.293 58.0763 313.293 54.8229C313.222 54.7163 313.169 54.6096 313.133 54.5029Z" fill="#BE6B1D"/>
                        <path d="M336.386 54.5029C329.346 54.9829 322.146 54.8229 315.106 54.8229C318.359 58.6096 321.879 62.0763 325.399 65.6496C328.919 61.9163 332.546 58.0763 336.492 54.8229C336.421 54.7163 336.386 54.6096 336.386 54.5029Z" fill="#BE6B1D"/>
                        <path d="M360.172 54.4502C352.972 55.0369 345.559 54.8235 338.305 54.8235C341.612 58.6102 345.132 62.0769 348.652 65.6502C352.279 61.7569 356.012 57.7569 360.172 54.4502Z" fill="#BE6B1D"/>
                        <path d="M243.905 54.5029C236.865 54.9829 229.665 54.8229 222.625 54.8229C225.878 58.6096 229.398 62.0763 232.918 65.6496C236.438 61.9163 240.012 58.0763 244.012 54.8229C243.941 54.7163 243.905 54.6096 243.905 54.5029Z" fill="#BE6B1D"/>
                        <path d="M267.159 54.5029C260.066 54.9829 252.919 54.8229 245.826 54.8229C249.132 58.6096 252.599 62.0763 256.172 65.6496C259.639 61.9163 263.266 58.0763 267.266 54.8229C267.195 54.7163 267.159 54.6096 267.159 54.5029Z" fill="#BE6B1D"/>
                        <path d="M290.945 54.4502C283.692 55.0369 276.332 54.8235 269.079 54.8235C272.385 58.6102 275.852 62.0769 279.372 65.6502C282.999 61.7569 286.785 57.7569 290.945 54.4502Z" fill="#BE6B1D"/>
                        <path d="M174.679 54.5029C167.639 54.9829 160.439 54.8229 153.345 54.8229C156.652 58.6096 160.172 62.0763 163.692 65.6496C167.159 61.9163 170.785 58.0763 174.785 54.8229C174.714 54.7163 174.679 54.6096 174.679 54.5029Z" fill="#BE6B1D"/>
                        <path d="M197.932 54.5029C190.839 54.9829 183.692 54.8229 176.599 54.8229C179.906 58.6096 183.372 62.0763 186.946 65.6496C190.412 61.9163 194.039 58.0763 198.039 54.8229C197.968 54.7163 197.932 54.6096 197.932 54.5029Z" fill="#BE6B1D"/>
                        <path d="M221.664 54.4502C214.464 55.0369 207.104 54.8235 199.851 54.8235C203.104 58.6102 206.624 62.0769 210.144 65.6502C213.771 61.7569 217.504 57.7569 221.664 54.4502Z" fill="#BE6B1D"/>
                        <path d="M105.452 54.5029C98.3586 54.9829 91.2119 54.8229 84.1186 54.8229C87.4252 58.6096 90.8919 62.0763 94.4652 65.6496C97.9319 61.9163 101.559 58.0763 105.559 54.8229C105.487 54.7163 105.452 54.6096 105.452 54.5029Z" fill="#BE6B1D"/>
                        <path d="M128.652 54.5029C121.612 54.9829 114.466 54.8229 107.372 54.8229C110.626 58.6096 114.146 62.0763 117.666 65.6496C121.186 61.9163 124.813 58.0763 128.812 54.8229C128.741 54.7163 128.688 54.6096 128.652 54.5029Z" fill="#BE6B1D"/>
                        <path d="M152.438 54.4502C145.237 55.0369 137.878 54.8235 130.624 54.8235C133.878 58.6102 137.398 62.0769 140.918 65.6502C144.544 61.7569 148.277 57.7569 152.438 54.4502Z" fill="#BE6B1D"/>
                        <path d="M36.172 54.5029C29.132 54.9829 21.9854 54.8229 14.8921 54.8229C18.1987 58.6096 21.6654 62.0763 25.1854 65.6496C28.7054 61.9163 32.332 58.0763 36.332 54.8229C36.2609 54.7163 36.2076 54.6096 36.172 54.5029Z" fill="#BE6B1D"/>
                        <path d="M59.4246 54.5029C52.3846 54.9829 45.1846 54.8229 38.1446 54.8229C41.3979 58.6096 44.9179 62.0763 48.4379 65.6496C51.9579 61.9163 55.5846 58.0763 59.5312 54.8229C59.4601 54.7163 59.4246 54.6096 59.4246 54.5029Z" fill="#BE6B1D"/>
                        <path d="M83.2109 54.4502C76.0109 55.0369 68.5976 54.8235 61.3443 54.8235C64.6509 58.6102 68.1709 62.0769 71.691 65.6502C75.3176 61.7569 79.0509 57.7569 83.2109 54.4502Z" fill="#BE6B1D"/>
                        <path d="M13.9844 54.4502C6.73104 55.0369 -0.628959 54.8235 -7.88229 54.8235C-4.57563 58.6102 -1.10895 62.0769 2.46439 65.6502C6.09105 61.7569 9.82437 57.7569 13.9844 54.4502Z" fill="#BE6B1D"/>
                        <path d="M3.47526 72.317C10.5686 71.837 17.7153 71.997 24.8086 71.997C21.5019 68.2636 18.0352 64.7436 14.4619 61.2236C10.9952 64.957 7.36856 68.797 3.36856 71.997C3.43967 72.1036 3.47526 72.2103 3.47526 72.317Z" fill="#69321B"/>
                        <path d="M72.7552 72.317C79.7952 71.837 86.9418 71.997 94.0352 71.997C90.7285 68.2636 87.2619 64.7436 83.7419 61.2236C80.2219 64.957 76.5952 68.797 72.5952 71.997C72.6663 72.1036 72.7197 72.2103 72.7552 72.317Z" fill="#69321B"/>
                        <path d="M49.5012 72.317C56.5412 71.837 63.7412 71.997 70.7812 71.997C67.5279 68.2636 64.008 64.7436 60.488 61.2236C56.968 64.957 53.3413 68.797 49.3946 71.997C49.4657 72.1036 49.5012 72.2103 49.5012 72.317Z" fill="#69321B"/>
                        <path d="M25.7154 72.3703C32.9154 71.837 40.3287 71.997 47.582 71.997C44.2754 68.2636 40.7554 64.7436 37.2354 61.2236C33.6087 65.0636 29.8754 69.0636 25.7154 72.3703Z" fill="#69321B"/>
                        <path d="M141.982 72.317C149.022 71.837 156.222 71.997 163.262 71.997C160.008 68.2636 156.488 64.7436 152.968 61.2236C149.448 64.957 145.875 68.797 141.875 71.997C141.946 72.1036 141.982 72.2103 141.982 72.317Z" fill="#69321B"/>
                        <path d="M118.729 72.317C125.823 71.837 132.969 71.997 140.062 71.997C136.756 68.2636 133.289 64.7436 129.716 61.2236C126.249 64.957 122.623 68.797 118.623 71.997C118.694 72.1036 118.729 72.2103 118.729 72.317Z" fill="#69321B"/>
                        <path d="M94.9419 72.3703C102.195 71.837 109.555 71.997 116.809 71.997C113.502 68.2636 110.035 64.7436 106.515 61.2236C102.889 65.0636 99.1019 69.0636 94.9419 72.3703Z" fill="#69321B"/>
                        <path d="M211.21 72.317C218.25 71.837 225.45 71.997 232.543 71.997C229.236 68.2636 225.716 64.7436 222.196 61.2236C218.73 64.957 215.103 68.797 211.103 71.997C211.174 72.1036 211.21 72.2103 211.21 72.317Z" fill="#69321B"/>
                        <path d="M187.956 72.317C195.049 71.837 202.196 71.997 209.289 71.997C205.982 68.2636 202.516 64.7436 198.942 61.2236C195.476 64.957 191.849 68.797 187.849 71.997C187.92 72.1036 187.956 72.2103 187.956 72.317Z" fill="#69321B"/>
                        <path d="M164.226 72.3703C171.426 71.837 178.786 71.997 186.039 71.997C182.786 68.2636 179.266 64.7436 175.746 61.2236C172.119 65.0636 168.386 69.0636 164.226 72.3703Z" fill="#69321B"/>
                        <path d="M280.436 72.317C287.53 71.837 294.676 71.997 301.77 71.997C298.463 68.2636 294.996 64.7436 291.423 61.2236C287.956 64.957 284.33 68.797 280.33 71.997C280.401 72.1036 280.436 72.2103 280.436 72.317Z" fill="#69321B"/>
                        <path d="M257.236 72.317C264.276 71.837 271.422 71.997 278.516 71.997C275.262 68.2636 271.742 64.7436 268.222 61.2236C264.702 64.957 261.076 68.797 257.076 71.997C257.147 72.1036 257.2 72.2103 257.236 72.317Z" fill="#69321B"/>
                        <path d="M233.452 72.3703C240.652 71.837 248.012 71.997 255.266 71.997C252.012 68.2636 248.492 64.7436 244.972 61.2236C241.346 65.0636 237.612 69.0636 233.452 72.3703Z" fill="#69321B"/>
                        <path d="M349.716 72.317C356.756 71.837 363.903 71.997 370.996 71.997C367.689 68.2636 364.223 64.7436 360.703 61.2236C357.183 64.957 353.556 68.797 349.556 71.997C349.627 72.1036 349.681 72.2103 349.716 72.317Z" fill="#69321B"/>
                        <path d="M326.462 72.317C333.502 71.837 340.702 71.997 347.742 71.997C344.489 68.2636 340.969 64.7436 337.449 61.2236C333.929 64.957 330.302 68.797 326.355 71.997C326.427 72.1036 326.462 72.2103 326.462 72.317Z" fill="#69321B"/>
                        <path d="M302.676 72.3703C309.876 71.837 317.29 71.997 324.543 71.997C321.236 68.2636 317.716 64.7436 314.196 61.2236C310.57 65.0636 306.836 69.0636 302.676 72.3703Z" fill="#69321B"/>
                        <path d="M418.943 72.317C425.983 71.837 433.183 71.997 440.223 71.997C436.969 68.2636 433.449 64.7436 429.929 61.2236C426.409 64.957 422.783 68.797 418.783 71.997C418.889 72.1036 418.943 72.2103 418.943 72.317Z" fill="#69321B"/>
                        <path d="M395.69 72.317C402.73 71.837 409.93 71.997 417.023 71.997C413.717 68.2636 410.197 64.7436 406.677 61.2236C403.157 64.957 399.583 68.797 395.583 71.997C395.655 72.1036 395.69 72.2103 395.69 72.317Z" fill="#69321B"/>
                        <path d="M371.903 72.3703C379.156 71.837 386.516 71.997 393.77 71.997C390.463 68.2636 386.996 64.7436 383.423 61.2236C379.796 65.0636 376.063 69.0636 371.903 72.3703Z" fill="#69321B"/>
                        <path d="M488.169 72.317C495.209 71.837 502.409 71.997 509.449 71.997C506.196 68.2636 502.676 64.7436 499.156 61.2236C495.636 64.957 492.063 68.797 488.063 71.997C488.134 72.1036 488.169 72.2103 488.169 72.317Z" fill="#69321B"/>
                        <path d="M464.917 72.317C472.01 71.837 479.157 71.997 486.25 71.997C482.943 68.2636 479.477 64.7436 475.903 61.2236C472.437 64.957 468.81 68.797 464.81 71.997C464.881 72.1036 464.917 72.2103 464.917 72.317Z" fill="#69321B"/>
                        <path d="M441.129 72.3703C448.383 71.837 455.743 71.997 462.996 71.997C459.689 68.2636 456.223 64.7436 452.703 61.2236C449.076 65.0636 445.289 69.0636 441.129 72.3703Z" fill="#69321B"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_5948_3217">
                            <rect width="539" height="72" fill="white" transform="matrix(-1 0 0 1 527 0)"/>
                        </clipPath>
                    </defs>
                </svg>

                <svg width="527" height="72" viewBox="0 0 527 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5948_3653)">
                        <path d="M55.4154 7.41072C56.7665 8.58405 58.0821 9.79294 59.3621 11.0374C57.0687 14.2374 54.3488 16.7441 51.6821 19.5174C48.4821 17.3307 46.1354 14.5574 43.4688 11.8374C46.4554 9.59738 49.0688 6.77072 51.8421 4.31738C52.9621 5.43738 54.2421 6.34405 55.4154 7.41072Z" fill="#69321B"/>
                        <path d="M15.9467 7.41072C17.2978 8.58405 18.6133 9.79294 19.8933 11.0374C17.6 14.2374 14.88 16.7441 12.2133 19.5174C9.01335 17.3307 6.66667 14.5574 4 11.8374C6.98667 9.59738 9.60001 6.77072 12.3733 4.31738C13.4933 5.43738 14.7733 6.34405 15.9467 7.41072Z" fill="#69321B"/>
                        <path d="M55.4154 30.8248C56.7665 31.9981 58.0821 33.2248 59.3621 34.5048C57.0687 37.7048 54.3488 40.1581 51.6821 42.9848C48.4821 40.7448 46.1354 37.9714 43.4688 35.2514C46.4554 33.0648 49.0688 30.2381 51.8421 27.7314C52.9621 28.8514 54.2421 29.8114 55.4154 30.8248Z" fill="#69321B"/>
                        <path d="M15.9467 30.8248C17.2978 31.9981 18.6133 33.2248 19.8933 34.5048C17.6 37.7048 14.88 40.1581 12.2133 42.9848C9.01335 40.7448 6.66667 37.9714 4 35.2514C6.98667 33.0648 9.60001 30.2381 12.3733 27.7314C13.4933 28.8514 14.7733 29.8114 15.9467 30.8248Z" fill="#69321B"/>
                        <path d="M41.6588 23.9443C38.6722 27.251 35.5789 30.451 32.7522 33.811C29.6055 30.6643 26.5122 27.0377 22.9922 24.2643C29.1789 24.4777 35.4722 24.1577 41.6588 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M32.643 6.98438C37.3364 12.7977 42.9897 18.0777 48.163 23.4644C42.403 28.1577 38.083 34.291 32.5897 39.251C32.5897 39.1977 32.5897 39.1977 32.5364 39.1444C27.2564 33.7577 22.0297 28.2644 16.4297 23.1444C19.203 20.4777 21.7097 17.491 24.4297 14.771C27.0964 12.1044 29.923 9.59771 32.643 6.98438ZM43.843 23.8377C44.2697 23.3577 43.9497 22.7177 43.523 22.5577C43.4163 22.5044 43.3097 22.451 43.1497 22.451C36.0564 22.611 28.9097 22.5044 21.8697 23.1444C21.283 23.1977 21.1764 23.8377 21.5497 24.1044C24.483 28.1577 28.5897 31.6244 32.0563 35.1444C32.163 35.2155 32.2697 35.2688 32.3764 35.3044C32.5897 35.411 32.803 35.411 33.0164 35.1977C36.9097 31.7844 40.4297 27.731 43.843 23.8377Z" fill="#69321B"/>
                        <path d="M94.9389 7.41072C96.29 8.58405 97.6055 9.79294 98.8855 11.0374C96.5922 14.2374 93.8722 16.7441 91.2055 19.5174C88.0055 17.3307 85.6589 14.5574 82.9922 11.8374C85.9789 9.59738 88.5922 6.77072 91.3655 4.31738C92.4855 5.43738 93.7655 6.34405 94.9389 7.41072Z" fill="#69321B"/>
                        <path d="M94.9389 30.8248C96.29 31.9981 97.6055 33.2248 98.8855 34.5048C96.5922 37.7048 93.8722 40.1581 91.2055 42.9848C88.0055 40.7448 85.6589 37.9714 82.9922 35.2514C85.9789 33.0648 88.5922 30.2381 91.3655 27.7314C92.4855 28.8514 93.7655 29.8114 94.9389 30.8248Z" fill="#69321B"/>
                        <path d="M81.1823 23.9443C78.1956 27.251 75.1023 30.451 72.2756 33.811C69.129 30.6643 66.0356 27.0377 62.5156 24.2643C68.7023 24.4777 74.9956 24.1577 81.1823 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M72.1664 6.98438C76.8598 12.7977 82.5131 18.0777 87.6865 23.4644C81.9265 28.1577 77.6065 34.291 72.1131 39.251C72.1131 39.1977 72.1131 39.1977 72.0598 39.1444C66.7798 33.7577 61.5531 28.2644 55.9531 23.1444C58.7265 20.4777 61.2331 17.491 63.9531 14.771C66.6198 12.1044 69.4464 9.59771 72.1664 6.98438ZM83.3664 23.8377C83.7931 23.3577 83.4731 22.7177 83.0465 22.5577C82.9398 22.5044 82.8331 22.451 82.6731 22.451C75.5798 22.611 68.4331 22.5044 61.3931 23.1444C60.8064 23.1977 60.6998 23.8377 61.0731 24.1044C64.0064 28.1577 68.1131 31.6244 71.5798 35.1444C71.6865 35.2155 71.7931 35.2688 71.8998 35.3044C72.1131 35.411 72.3264 35.411 72.5398 35.1977C76.4331 31.7844 79.9531 27.731 83.3664 23.8377Z" fill="#69321B"/>
                        <path d="M134.454 7.41072C135.806 8.58405 137.121 9.79294 138.401 11.0374C136.108 14.2374 133.388 16.7441 130.721 19.5174C127.521 17.3307 125.174 14.5574 122.508 11.8374C125.494 9.59738 128.108 6.77072 130.881 4.31738C132.001 5.43738 133.281 6.34405 134.454 7.41072Z" fill="#69321B"/>
                        <path d="M134.454 30.8248C135.806 31.9981 137.121 33.2248 138.401 34.5048C136.108 37.7048 133.388 40.1581 130.721 42.9848C127.521 40.7448 125.174 37.9714 122.508 35.2514C125.494 33.0648 128.108 30.2381 130.881 27.7314C132.001 28.8514 133.281 29.8114 134.454 30.8248Z" fill="#69321B"/>
                        <path d="M120.698 23.9443C117.711 27.251 114.618 30.451 111.791 33.811C108.645 30.6643 105.551 27.0377 102.031 24.2643C108.218 24.4777 114.511 24.1577 120.698 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M111.682 6.98438C116.375 12.7977 122.029 18.0777 127.202 23.4644C121.442 28.1577 117.122 34.291 111.629 39.251C111.629 39.1977 111.629 39.1977 111.575 39.1444C106.295 33.7577 101.069 28.2644 95.4688 23.1444C98.2421 20.4777 100.749 17.491 103.469 14.771C106.135 12.1044 108.962 9.59771 111.682 6.98438ZM122.882 23.8377C123.309 23.3577 122.989 22.7177 122.562 22.5577C122.455 22.5044 122.349 22.451 122.189 22.451C115.095 22.611 107.949 22.5044 100.909 23.1444C100.322 23.1977 100.215 23.8377 100.589 24.1044C103.522 28.1577 107.629 31.6244 111.095 35.1444C111.202 35.2155 111.309 35.2688 111.415 35.3044C111.629 35.411 111.842 35.411 112.055 35.1977C115.949 31.7844 119.469 27.731 122.882 23.8377Z" fill="#69321B"/>
                        <path d="M173.978 7.41072C175.329 8.58405 176.645 9.79294 177.925 11.0374C175.631 14.2374 172.911 16.7441 170.245 19.5174C167.045 17.3307 164.698 14.5574 162.031 11.8374C165.018 9.59738 167.631 6.77072 170.405 4.31738C171.525 5.43738 172.805 6.34405 173.978 7.41072Z" fill="#69321B"/>
                        <path d="M173.978 30.8248C175.329 31.9981 176.645 33.2248 177.925 34.5048C175.631 37.7048 172.911 40.1581 170.245 42.9848C167.045 40.7448 164.698 37.9714 162.031 35.2514C165.018 33.0648 167.631 30.2381 170.405 27.7314C171.525 28.8514 172.805 29.8114 173.978 30.8248Z" fill="#69321B"/>
                        <path d="M160.221 23.9443C157.235 27.251 154.141 30.451 151.315 33.811C148.168 30.6643 145.075 27.0377 141.555 24.2643C147.741 24.4777 154.035 24.1577 160.221 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M151.206 6.98438C155.899 12.7977 161.552 18.0777 166.726 23.4644C160.966 28.1577 156.646 34.291 151.152 39.251C151.152 39.1977 151.152 39.1977 151.099 39.1444C145.819 33.7577 140.592 28.2644 134.992 23.1444C137.766 20.4777 140.272 17.491 142.992 14.771C145.659 12.1044 148.486 9.59771 151.206 6.98438ZM162.406 23.8377C162.832 23.3577 162.512 22.7177 162.086 22.5577C161.979 22.5044 161.872 22.451 161.712 22.451C154.619 22.611 147.472 22.5044 140.432 23.1444C139.846 23.1977 139.739 23.8377 140.112 24.1044C143.046 28.1577 147.152 31.6244 150.619 35.1444C150.726 35.2155 150.832 35.2688 150.939 35.3044C151.152 35.411 151.366 35.411 151.579 35.1977C155.472 31.7844 158.992 27.731 162.406 23.8377Z" fill="#69321B"/>
                        <path d="M213.494 7.41072C214.845 8.58405 216.16 9.79294 217.44 11.0374C215.147 14.2374 212.427 16.7441 209.707 19.5174C206.56 17.3307 204.214 14.5574 201.547 11.8374C204.48 9.59738 207.147 6.77072 209.867 4.31738C210.987 5.43738 212.32 6.34405 213.494 7.41072Z" fill="#69321B"/>
                        <path d="M213.494 30.8248C214.845 31.9981 216.16 33.2248 217.44 34.5048C215.147 37.7048 212.427 40.1581 209.707 42.9848C206.56 40.7448 204.214 37.9714 201.547 35.2514C204.48 33.0648 207.147 30.2381 209.867 27.7314C210.987 28.8514 212.32 29.8114 213.494 30.8248Z" fill="#69321B"/>
                        <path d="M199.737 23.9443C196.75 27.251 193.604 30.451 190.83 33.811C187.684 30.6643 184.59 27.0377 181.07 24.2643C187.257 24.4777 193.497 24.1577 199.737 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M190.721 6.98438C195.414 12.7977 201.014 18.0777 206.188 23.4644C200.481 28.1577 196.161 34.291 190.668 39.251C190.668 39.1977 190.668 39.1977 190.614 39.1444C185.334 33.7577 180.108 28.2644 174.508 23.1444C177.281 20.4777 179.788 17.491 182.454 14.771C185.121 12.1044 188.001 9.59771 190.721 6.98438ZM201.921 23.8377C202.348 23.3577 202.028 22.7177 201.548 22.5577C201.441 22.5044 201.334 22.451 201.228 22.451C194.134 22.611 186.988 22.5044 179.948 23.1444C179.361 23.1977 179.254 23.8377 179.628 24.1044C182.561 28.1577 186.668 31.6244 190.134 35.1444C190.241 35.2155 190.348 35.2688 190.454 35.3044C190.614 35.411 190.881 35.411 191.094 35.1977C194.988 31.7844 198.454 27.731 201.921 23.8377Z" fill="#69321B"/>
                        <path d="M253.017 7.41072C254.368 8.58405 255.684 9.79294 256.964 11.0374C254.67 14.2374 251.95 16.7441 249.23 19.5174C246.084 17.3307 243.737 14.5574 241.07 11.8374C244.004 9.59738 246.67 6.77072 249.39 4.31738C250.51 5.43738 251.844 6.34405 253.017 7.41072Z" fill="#69321B"/>
                        <path d="M253.017 30.8248C254.368 31.9981 255.684 33.2248 256.964 34.5048C254.67 37.7048 251.95 40.1581 249.23 42.9848C246.084 40.7448 243.737 37.9714 241.07 35.2514C244.004 33.0648 246.67 30.2381 249.39 27.7314C250.51 28.8514 251.844 29.8114 253.017 30.8248Z" fill="#69321B"/>
                        <path d="M239.26 23.9443C236.274 27.251 233.127 30.451 230.354 33.811C227.207 30.6643 224.114 27.0377 220.594 24.2643C226.78 24.4777 233.02 24.1577 239.26 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M230.245 6.98438C234.938 12.7977 240.538 18.0777 245.711 23.4644C240.005 28.1577 235.685 34.291 230.191 39.251C230.191 39.1977 230.191 39.1977 230.138 39.1444C224.858 33.7577 219.631 28.2644 214.031 23.1444C216.805 20.4777 219.311 17.491 221.978 14.771C224.645 12.1044 227.525 9.59771 230.245 6.98438ZM241.445 23.8377C241.871 23.3577 241.551 22.7177 241.071 22.5577C240.965 22.5044 240.858 22.451 240.751 22.451C233.658 22.611 226.511 22.5044 219.471 23.1444C218.885 23.1977 218.778 23.8377 219.151 24.1044C222.085 28.1577 226.191 31.6244 229.658 35.1444C229.765 35.2155 229.871 35.2688 229.978 35.3044C230.138 35.411 230.405 35.411 230.618 35.1977C234.511 31.7844 237.978 27.731 241.445 23.8377Z" fill="#69321B"/>
                        <path d="M292.54 7.41072C293.892 8.58405 295.207 9.79294 296.487 11.0374C294.194 14.2374 291.474 16.7441 288.754 19.5174C285.607 17.3307 283.26 14.5574 280.594 11.8374C283.527 9.59738 286.194 6.77072 288.914 4.31738C290.034 5.43738 291.367 6.34405 292.54 7.41072Z" fill="#69321B"/>
                        <path d="M292.54 30.8248C293.892 31.9981 295.207 33.2248 296.487 34.5048C294.194 37.7048 291.474 40.1581 288.754 42.9848C285.607 40.7448 283.26 37.9714 280.594 35.2514C283.527 33.0648 286.194 30.2381 288.914 27.7314C290.034 28.8514 291.367 29.8114 292.54 30.8248Z" fill="#69321B"/>
                        <path d="M278.776 23.9443C275.789 27.251 272.643 30.451 269.869 33.811C266.723 30.6643 263.629 27.0377 260.109 24.2643C266.296 24.4777 272.536 24.1577 278.776 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M269.76 6.98438C274.454 12.7977 280.054 18.0777 285.227 23.4644C279.52 28.1577 275.2 34.291 269.707 39.251C269.707 39.1977 269.707 39.1977 269.654 39.1444C264.374 33.7577 259.147 28.2644 253.547 23.1444C256.32 20.4777 258.827 17.491 261.494 14.771C264.16 12.1044 267.04 9.59771 269.76 6.98438ZM280.96 23.8377C281.387 23.3577 281.067 22.7177 280.587 22.5577C280.48 22.5044 280.374 22.451 280.267 22.451C273.174 22.611 266.027 22.5044 258.987 23.1444C258.4 23.1977 258.294 23.8377 258.667 24.1044C261.6 28.1577 265.707 31.6244 269.174 35.1444C269.28 35.2155 269.387 35.2688 269.494 35.3044C269.654 35.411 269.92 35.411 270.134 35.1977C274.027 31.7844 277.494 27.731 280.96 23.8377Z" fill="#69321B"/>
                        <path d="M332.056 7.41072C333.407 8.58405 334.705 9.79294 335.949 11.0374C333.709 14.2374 330.936 16.7441 328.269 19.5174C325.123 17.3307 322.776 14.5574 320.109 11.8374C323.043 9.59738 325.709 6.77072 328.429 4.31738C329.549 5.43738 330.883 6.34405 332.056 7.41072Z" fill="#69321B"/>
                        <path d="M332.056 30.8248C333.407 31.9981 334.705 33.2248 335.949 34.5048C333.709 37.7048 330.936 40.1581 328.269 42.9848C325.123 40.7448 322.776 37.9714 320.109 35.2514C323.043 33.0648 325.709 30.2381 328.429 27.7314C329.549 28.8514 330.883 29.8114 332.056 30.8248Z" fill="#69321B"/>
                        <path d="M318.3 23.9443C315.313 27.251 312.166 30.451 309.339 33.811C306.246 30.6643 303.153 27.0377 299.633 24.2643C305.819 24.4777 312.06 24.1577 318.3 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M309.23 6.98438C313.977 12.7977 319.577 18.0777 324.75 23.4644C319.044 28.1577 314.724 34.291 309.23 39.251C309.177 39.1977 309.177 39.1977 309.177 39.1444C303.897 33.7577 298.67 28.2644 293.07 23.1444C295.844 20.4777 298.297 17.491 301.017 14.771C303.684 12.1044 306.564 9.59771 309.23 6.98438ZM320.484 23.8377C320.857 23.3577 320.59 22.7177 320.11 22.5577C320.004 22.5044 319.897 22.451 319.737 22.451C312.697 22.611 305.55 22.5044 298.51 23.1444C297.924 23.1977 297.817 23.8377 298.19 24.1044C301.07 28.1577 305.23 31.6244 308.697 35.1444C308.804 35.2155 308.91 35.2688 309.017 35.3044C309.177 35.411 309.444 35.411 309.657 35.1977C313.55 31.7844 317.017 27.731 320.484 23.8377Z" fill="#69321B"/>
                        <path d="M371.579 7.41072C372.931 8.58405 374.228 9.79294 375.473 11.0374C373.233 14.2374 370.459 16.7441 367.793 19.5174C364.646 17.3307 362.299 14.5574 359.633 11.8374C362.566 9.59738 365.233 6.77072 367.953 4.31738C369.073 5.43738 370.406 6.34405 371.579 7.41072Z" fill="#69321B"/>
                        <path d="M371.579 30.8248C372.931 31.9981 374.228 33.2248 375.473 34.5048C373.233 37.7048 370.459 40.1581 367.793 42.9848C364.646 40.7448 362.299 37.9714 359.633 35.2514C362.566 33.0648 365.233 30.2381 367.953 27.7314C369.073 28.8514 370.406 29.8114 371.579 30.8248Z" fill="#69321B"/>
                        <path d="M357.823 23.9443C354.836 27.251 351.69 30.451 348.863 33.811C345.77 30.6643 342.676 27.0377 339.156 24.2643C345.343 24.4777 351.583 24.1577 357.823 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M348.754 6.98438C353.5 12.7977 359.1 18.0777 364.274 23.4644C358.567 28.1577 354.247 34.291 348.754 39.251C348.7 39.1977 348.7 39.1977 348.7 39.1444C343.42 33.7577 338.194 28.2644 332.594 23.1444C335.367 20.4777 337.82 17.491 340.54 14.771C343.207 12.1044 346.087 9.59771 348.754 6.98438ZM360.007 23.8377C360.38 23.3577 360.114 22.7177 359.634 22.5577C359.527 22.5044 359.42 22.451 359.26 22.451C352.22 22.611 345.074 22.5044 338.034 23.1444C337.447 23.1977 337.34 23.8377 337.714 24.1044C340.594 28.1577 344.754 31.6244 348.22 35.1444C348.327 35.2155 348.434 35.2688 348.54 35.3044C348.7 35.411 348.967 35.411 349.18 35.1977C353.074 31.7844 356.54 27.731 360.007 23.8377Z" fill="#69321B"/>
                        <path d="M411.095 7.41072C412.446 8.58405 413.744 9.79294 414.988 11.0374C412.748 14.2374 409.975 16.7441 407.308 19.5174C404.162 17.3307 401.815 14.5574 399.148 11.8374C402.082 9.59738 404.748 6.77072 407.468 4.31738C408.588 5.43738 409.922 6.34405 411.095 7.41072Z" fill="#69321B"/>
                        <path d="M411.095 30.8248C412.446 31.9981 413.744 33.2248 414.988 34.5048C412.748 37.7048 409.975 40.1581 407.308 42.9848C404.162 40.7448 401.815 37.9714 399.148 35.2514C402.082 33.0648 404.748 30.2381 407.468 27.7314C408.588 28.8514 409.922 29.8114 411.095 30.8248Z" fill="#69321B"/>
                        <path d="M397.339 23.9443C394.352 27.251 391.205 30.451 388.379 33.811C385.285 30.6643 382.192 27.0377 378.672 24.2643C384.859 24.4777 391.099 24.1577 397.339 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M388.269 6.98438C393.016 12.7977 398.616 18.0777 403.789 23.4644C398.083 28.1577 393.763 34.291 388.269 39.251C388.216 39.1977 388.216 39.1977 388.216 39.1444C382.936 33.7577 377.709 28.2644 372.109 23.1444C374.883 20.4777 377.336 17.491 380.056 14.771C382.723 12.1044 385.603 9.59771 388.269 6.98438ZM399.523 23.8377C399.896 23.3577 399.629 22.7177 399.149 22.5577C399.043 22.5044 398.936 22.451 398.776 22.451C391.736 22.611 384.589 22.5044 377.549 23.1444C376.963 23.1977 376.856 23.8377 377.229 24.1044C380.109 28.1577 384.269 31.6244 387.736 35.1444C387.843 35.2155 387.949 35.2688 388.056 35.3044C388.216 35.411 388.483 35.411 388.696 35.1977C392.589 31.7844 396.056 27.731 399.523 23.8377Z" fill="#69321B"/>
                        <path d="M450.619 7.41072C451.97 8.58405 453.267 9.79294 454.512 11.0374C452.272 14.2374 449.499 16.7441 446.832 19.5174C443.685 17.3307 441.339 14.5574 438.672 11.8374C441.605 9.59738 444.272 6.77072 446.992 4.31738C448.112 5.43738 449.445 6.34405 450.619 7.41072Z" fill="#69321B"/>
                        <path d="M450.619 30.8248C451.97 31.9981 453.267 33.2248 454.512 34.5048C452.272 37.7048 449.499 40.1581 446.832 42.9848C443.685 40.7448 441.339 37.9714 438.672 35.2514C441.605 33.0648 444.272 30.2381 446.992 27.7314C448.112 28.8514 449.445 29.8114 450.619 30.8248Z" fill="#69321B"/>
                        <path d="M436.862 23.9443C433.875 27.251 430.729 30.451 427.902 33.811C424.809 30.6643 421.715 27.0377 418.195 24.2643C424.382 24.4777 430.622 24.1577 436.862 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M427.793 6.98438C432.539 12.7977 438.139 18.0777 443.313 23.4644C437.606 28.1577 433.286 34.291 427.793 39.251C427.739 39.1977 427.739 39.1977 427.739 39.1444C422.459 33.7577 417.233 28.2644 411.633 23.1444C414.406 20.4777 416.859 17.491 419.579 14.771C422.246 12.1044 425.126 9.59771 427.793 6.98438ZM439.046 23.8377C439.419 23.3577 439.153 22.7177 438.673 22.5577C438.566 22.5044 438.459 22.451 438.299 22.451C431.259 22.611 424.113 22.5044 417.073 23.1444C416.486 23.1977 416.379 23.8377 416.753 24.1044C419.633 28.1577 423.793 31.6244 427.259 35.1444C427.366 35.2155 427.473 35.2688 427.579 35.3044C427.739 35.411 428.006 35.411 428.219 35.1977C432.113 31.7844 435.579 27.731 439.046 23.8377Z" fill="#69321B"/>
                        <path d="M490.134 7.41072C491.485 8.58405 492.783 9.79294 494.028 11.0374C491.788 14.2374 489.014 16.7441 486.348 19.5174C483.201 17.3307 480.801 14.5574 478.188 11.8374C481.121 9.59738 483.787 6.77072 486.507 4.31738C487.627 5.43738 488.908 6.34405 490.134 7.41072Z" fill="#69321B"/>
                        <path d="M490.134 30.8248C491.485 31.9981 492.783 33.2248 494.028 34.5048C491.788 37.7048 489.014 40.1581 486.348 42.9848C483.201 40.7448 480.801 37.9714 478.188 35.2514C481.121 33.0648 483.787 30.2381 486.507 27.7314C487.627 28.8514 488.908 29.8114 490.134 30.8248Z" fill="#69321B"/>
                        <path d="M476.324 23.9443C473.338 27.251 470.244 30.451 467.418 33.811C464.324 30.6643 461.178 27.0377 457.711 24.2643C463.898 24.4777 470.138 24.1577 476.324 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M467.308 6.98438C472.002 12.7977 477.655 18.0777 482.828 23.4644C477.068 28.1577 472.748 34.291 467.308 39.251C467.255 39.1977 467.255 39.1977 467.255 39.1444C461.922 33.7577 456.748 28.2644 451.148 23.1444C453.868 20.4777 456.375 17.491 459.095 14.771C461.762 12.1044 464.642 9.59771 467.308 6.98438ZM478.508 23.8377C478.935 23.3577 478.615 22.7177 478.188 22.5577C478.082 22.5044 477.975 22.451 477.815 22.451C470.775 22.611 463.575 22.5044 456.535 23.1444C456.002 23.1977 455.895 23.8377 456.268 24.1044C459.148 28.1577 463.255 31.6244 466.775 35.1444C466.882 35.2155 466.988 35.2688 467.095 35.3044C467.255 35.411 467.468 35.411 467.682 35.1977C471.628 31.7844 475.095 27.731 478.508 23.8377Z" fill="#69321B"/>
                        <path d="M529.658 7.41072C530.973 8.58405 532.271 9.79294 533.551 11.0374C531.311 14.2374 528.538 16.7441 525.871 19.5174C522.724 17.3307 520.324 14.5574 517.711 11.8374C520.644 9.59738 523.311 6.77072 526.031 4.31738C527.151 5.43738 528.431 6.34405 529.658 7.41072Z" fill="#69321B"/>
                        <path d="M529.658 30.8248C530.973 31.9981 532.271 33.2248 533.551 34.5048C531.311 37.7048 528.538 40.1581 525.871 42.9848C522.724 40.7448 520.324 37.9714 517.711 35.2514C520.644 33.0648 523.311 30.2381 526.031 27.7314C527.151 28.8514 528.431 29.8114 529.658 30.8248Z" fill="#69321B"/>
                        <path d="M515.848 23.9443C512.861 27.251 509.768 30.451 506.941 33.811C503.848 30.6643 500.701 27.0377 497.234 24.2643C503.421 24.4777 509.661 24.1577 515.848 23.9443Z" fill="#BE6B1D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M506.832 6.98438C511.525 12.7977 517.179 18.0777 522.352 23.4644C516.592 28.1577 512.272 34.291 506.832 39.251C506.779 39.1977 506.779 39.1977 506.779 39.1444C501.445 33.7577 496.272 28.2644 490.672 23.1444C493.392 20.4777 495.899 17.491 498.619 14.771C501.285 12.1044 504.165 9.59771 506.832 6.98438ZM518.032 23.8377C518.459 23.3577 518.139 22.7177 517.712 22.5577C517.605 22.5044 517.499 22.451 517.339 22.451C510.299 22.611 503.099 22.5044 496.059 23.1444C495.525 23.1977 495.419 23.8377 495.792 24.1044C498.672 28.1577 502.779 31.6244 506.299 35.1444C506.405 35.2155 506.512 35.2688 506.619 35.3044C506.779 35.411 506.992 35.411 507.205 35.1977C511.152 31.7844 514.619 27.731 518.032 23.8377Z" fill="#69321B"/>
                        <path d="M75.3606 54.5029C82.4006 54.9829 89.6006 54.8229 96.6939 54.8229C93.3872 58.6096 89.8673 62.0763 86.3473 65.6496C82.8806 61.9163 79.2539 58.0763 75.2539 54.8229C75.325 54.7163 75.3606 54.6096 75.3606 54.5029Z" fill="#BE6B1D"/>
                        <path d="M52.1067 54.5029C59.2 54.9829 66.3467 54.8229 73.44 54.8229C70.1333 58.6096 66.6667 62.0763 63.0933 65.6496C59.6267 61.9163 56 58.0763 52 54.8229C52.0711 54.7163 52.1067 54.6096 52.1067 54.5029Z" fill="#BE6B1D"/>
                        <path d="M28.375 54.4502C35.575 55.0369 42.935 54.8235 50.1883 54.8235C46.935 58.6102 43.415 62.0769 39.895 65.6502C36.2683 61.7569 32.535 57.7569 28.375 54.4502Z" fill="#BE6B1D"/>
                        <path d="M144.587 54.5029C151.68 54.9829 158.827 54.8229 165.92 54.8229C162.614 58.6096 159.147 62.0763 155.574 65.6496C152.107 61.9163 148.48 58.0763 144.48 54.8229C144.552 54.7163 144.587 54.6096 144.587 54.5029Z" fill="#BE6B1D"/>
                        <path d="M121.387 54.5029C128.427 54.9829 135.573 54.8229 142.667 54.8229C139.413 58.6096 135.893 62.0763 132.373 65.6496C128.853 61.9163 125.227 58.0763 121.227 54.8229C121.298 54.7163 121.351 54.6096 121.387 54.5029Z" fill="#BE6B1D"/>
                        <path d="M97.6016 54.4502C104.802 55.0369 112.162 54.8235 119.415 54.8235C116.162 58.6102 112.642 62.0769 109.122 65.6502C105.495 61.7569 101.762 57.7569 97.6016 54.4502Z" fill="#BE6B1D"/>
                        <path d="M213.867 54.5029C220.907 54.9829 228.054 54.8229 235.147 54.8229C231.84 58.6096 228.374 62.0763 224.854 65.6496C221.334 61.9163 217.707 58.0763 213.707 54.8229C213.778 54.7163 213.831 54.6096 213.867 54.5029Z" fill="#BE6B1D"/>
                        <path d="M190.614 54.5029C197.654 54.9829 204.854 54.8229 211.894 54.8229C208.641 58.6096 205.121 62.0763 201.601 65.6496C198.081 61.9163 194.454 58.0763 190.508 54.8229C190.579 54.7163 190.614 54.6096 190.614 54.5029Z" fill="#BE6B1D"/>
                        <path d="M166.828 54.4502C174.028 55.0369 181.441 54.8235 188.695 54.8235C185.388 58.6102 181.868 62.0769 178.348 65.6502C174.721 61.7569 170.988 57.7569 166.828 54.4502Z" fill="#BE6B1D"/>
                        <path d="M283.095 54.5029C290.135 54.9829 297.335 54.8229 304.375 54.8229C301.122 58.6096 297.602 62.0763 294.082 65.6496C290.562 61.9163 286.988 58.0763 282.988 54.8229C283.059 54.7163 283.095 54.6096 283.095 54.5029Z" fill="#BE6B1D"/>
                        <path d="M259.841 54.5029C266.934 54.9829 274.081 54.8229 281.174 54.8229C277.868 58.6096 274.401 62.0763 270.828 65.6496C267.361 61.9163 263.734 58.0763 259.734 54.8229C259.805 54.7163 259.841 54.6096 259.841 54.5029Z" fill="#BE6B1D"/>
                        <path d="M236.055 54.4502C243.308 55.0369 250.668 54.8235 257.921 54.8235C254.615 58.6102 251.148 62.0769 247.628 65.6502C244.001 61.7569 240.215 57.7569 236.055 54.4502Z" fill="#BE6B1D"/>
                        <path d="M352.321 54.5029C359.361 54.9829 366.561 54.8229 373.655 54.8229C370.348 58.6096 366.828 62.0763 363.308 65.6496C359.841 61.9163 356.215 58.0763 352.215 54.8229C352.286 54.7163 352.321 54.6096 352.321 54.5029Z" fill="#BE6B1D"/>
                        <path d="M329.068 54.5029C336.161 54.9829 343.308 54.8229 350.401 54.8229C347.094 58.6096 343.628 62.0763 340.054 65.6496C336.588 61.9163 332.961 58.0763 328.961 54.8229C329.032 54.7163 329.068 54.6096 329.068 54.5029Z" fill="#BE6B1D"/>
                        <path d="M305.336 54.4502C312.536 55.0369 319.896 54.8235 327.149 54.8235C323.896 58.6102 320.376 62.0769 316.856 65.6502C313.229 61.7569 309.496 57.7569 305.336 54.4502Z" fill="#BE6B1D"/>
                        <path d="M421.548 54.5029C428.641 54.9829 435.788 54.8229 442.881 54.8229C439.575 58.6096 436.108 62.0763 432.535 65.6496C429.068 61.9163 425.441 58.0763 421.441 54.8229C421.513 54.7163 421.548 54.6096 421.548 54.5029Z" fill="#BE6B1D"/>
                        <path d="M398.348 54.5029C405.388 54.9829 412.534 54.8229 419.628 54.8229C416.374 58.6096 412.854 62.0763 409.334 65.6496C405.814 61.9163 402.187 58.0763 398.188 54.8229C398.259 54.7163 398.312 54.6096 398.348 54.5029Z" fill="#BE6B1D"/>
                        <path d="M374.562 54.4502C381.763 55.0369 389.122 54.8235 396.376 54.8235C393.122 58.6102 389.602 62.0769 386.082 65.6502C382.456 61.7569 378.723 57.7569 374.562 54.4502Z" fill="#BE6B1D"/>
                        <path d="M490.828 54.5029C497.868 54.9829 505.015 54.8229 512.108 54.8229C508.801 58.6096 505.335 62.0763 501.815 65.6496C498.295 61.9163 494.668 58.0763 490.668 54.8229C490.739 54.7163 490.792 54.6096 490.828 54.5029Z" fill="#BE6B1D"/>
                        <path d="M467.575 54.5029C474.615 54.9829 481.815 54.8229 488.855 54.8229C485.602 58.6096 482.082 62.0763 478.562 65.6496C475.042 61.9163 471.415 58.0763 467.469 54.8229C467.54 54.7163 467.575 54.6096 467.575 54.5029Z" fill="#BE6B1D"/>
                        <path d="M443.789 54.4502C450.989 55.0369 458.402 54.8235 465.656 54.8235C462.349 58.6102 458.829 62.0769 455.309 65.6502C451.682 61.7569 447.949 57.7569 443.789 54.4502Z" fill="#BE6B1D"/>
                        <path d="M513.016 54.4502C520.269 55.0369 527.629 54.8235 534.882 54.8235C531.576 58.6102 528.109 62.0769 524.536 65.6502C520.909 61.7569 517.176 57.7569 513.016 54.4502Z" fill="#BE6B1D"/>
                        <path d="M523.525 72.317C516.431 71.837 509.285 71.997 502.191 71.997C505.498 68.2636 508.965 64.7436 512.538 61.2236C516.005 64.957 519.631 68.797 523.631 71.997C523.56 72.1036 523.525 72.2103 523.525 72.317Z" fill="#69321B"/>
                        <path d="M454.245 72.317C447.205 71.837 440.058 71.997 432.965 71.997C436.272 68.2636 439.738 64.7436 443.258 61.2236C446.778 64.957 450.405 68.797 454.405 71.997C454.334 72.1036 454.28 72.2103 454.245 72.317Z" fill="#69321B"/>
                        <path d="M477.499 72.317C470.459 71.837 463.259 71.997 456.219 71.997C459.472 68.2636 462.992 64.7436 466.512 61.2236C470.032 64.957 473.659 68.797 477.605 71.997C477.534 72.1036 477.499 72.2103 477.499 72.317Z" fill="#69321B"/>
                        <path d="M501.285 72.3703C494.085 71.837 486.671 71.997 479.418 71.997C482.725 68.2636 486.245 64.7436 489.765 61.2236C493.391 65.0636 497.125 69.0636 501.285 72.3703Z" fill="#69321B"/>
                        <path d="M385.018 72.317C377.978 71.837 370.778 71.997 363.738 71.997C366.992 68.2636 370.512 64.7436 374.032 61.2236C377.552 64.957 381.125 68.797 385.125 71.997C385.054 72.1036 385.018 72.2103 385.018 72.317Z" fill="#69321B"/>
                        <path d="M408.271 72.317C401.177 71.837 394.031 71.997 386.938 71.997C390.244 68.2636 393.711 64.7436 397.284 61.2236C400.751 64.957 404.377 68.797 408.377 71.997C408.306 72.1036 408.271 72.2103 408.271 72.317Z" fill="#69321B"/>
                        <path d="M432.058 72.3703C424.805 71.837 417.445 71.997 410.191 71.997C413.498 68.2636 416.965 64.7436 420.485 61.2236C424.111 65.0636 427.898 69.0636 432.058 72.3703Z" fill="#69321B"/>
                        <path d="M315.79 72.317C308.75 71.837 301.55 71.997 294.457 71.997C297.764 68.2636 301.284 64.7436 304.804 61.2236C308.27 64.957 311.897 68.797 315.897 71.997C315.826 72.1036 315.79 72.2103 315.79 72.317Z" fill="#69321B"/>
                        <path d="M339.044 72.317C331.951 71.837 324.804 71.997 317.711 71.997C321.018 68.2636 324.484 64.7436 328.058 61.2236C331.524 64.957 335.151 68.797 339.151 71.997C339.08 72.1036 339.044 72.2103 339.044 72.317Z" fill="#69321B"/>
                        <path d="M362.774 72.3703C355.574 71.837 348.214 71.997 340.961 71.997C344.214 68.2636 347.734 64.7436 351.254 61.2236C354.881 65.0636 358.614 69.0636 362.774 72.3703Z" fill="#69321B"/>
                        <path d="M246.564 72.317C239.47 71.837 232.324 71.997 225.23 71.997C228.537 68.2636 232.004 64.7436 235.577 61.2236C239.044 64.957 242.67 68.797 246.67 71.997C246.599 72.1036 246.564 72.2103 246.564 72.317Z" fill="#69321B"/>
                        <path d="M269.764 72.317C262.724 71.837 255.578 71.997 248.484 71.997C251.738 68.2636 255.258 64.7436 258.778 61.2236C262.298 64.957 265.924 68.797 269.924 71.997C269.853 72.1036 269.8 72.2103 269.764 72.317Z" fill="#69321B"/>
                        <path d="M293.548 72.3703C286.348 71.837 278.988 71.997 271.734 71.997C274.988 68.2636 278.508 64.7436 282.028 61.2236C285.654 65.0636 289.388 69.0636 293.548 72.3703Z" fill="#69321B"/>
                        <path d="M177.284 72.317C170.244 71.837 163.097 71.997 156.004 71.997C159.311 68.2636 162.777 64.7436 166.297 61.2236C169.817 64.957 173.444 68.797 177.444 71.997C177.373 72.1036 177.319 72.2103 177.284 72.317Z" fill="#69321B"/>
                        <path d="M200.538 72.317C193.498 71.837 186.298 71.997 179.258 71.997C182.511 68.2636 186.031 64.7436 189.551 61.2236C193.071 64.957 196.698 68.797 200.645 71.997C200.573 72.1036 200.538 72.2103 200.538 72.317Z" fill="#69321B"/>
                        <path d="M224.324 72.3703C217.124 71.837 209.71 71.997 202.457 71.997C205.764 68.2636 209.284 64.7436 212.804 61.2236C216.43 65.0636 220.164 69.0636 224.324 72.3703Z" fill="#69321B"/>
                        <path d="M108.057 72.317C101.017 71.837 93.8173 71.997 86.7773 71.997C90.0307 68.2636 93.5507 64.7436 97.0707 61.2236C100.591 64.957 104.217 68.797 108.217 71.997C108.111 72.1036 108.057 72.2103 108.057 72.317Z" fill="#69321B"/>
                        <path d="M131.31 72.317C124.27 71.837 117.07 71.997 109.977 71.997C113.283 68.2636 116.803 64.7436 120.323 61.2236C123.843 64.957 127.417 68.797 131.417 71.997C131.345 72.1036 131.31 72.2103 131.31 72.317Z" fill="#69321B"/>
                        <path d="M155.097 72.3703C147.844 71.837 140.484 71.997 133.23 71.997C136.537 68.2636 140.004 64.7436 143.577 61.2236C147.204 65.0636 150.937 69.0636 155.097 72.3703Z" fill="#69321B"/>
                        <path d="M38.8308 72.317C31.7908 71.837 24.5908 71.997 17.5508 71.997C20.8041 68.2636 24.3241 64.7436 27.8441 61.2236C31.3641 64.957 34.9375 68.797 38.9375 71.997C38.8663 72.1036 38.8308 72.2103 38.8308 72.317Z" fill="#69321B"/>
                        <path d="M62.0833 72.317C54.99 71.837 47.8433 71.997 40.75 71.997C44.0567 68.2636 47.5233 64.7436 51.0966 61.2236C54.5633 64.957 58.19 68.797 62.19 71.997C62.1189 72.1036 62.0833 72.2103 62.0833 72.317Z" fill="#69321B"/>
                        <path d="M85.8706 72.3703C78.6172 71.837 71.2572 71.997 64.0039 71.997C67.3106 68.2636 70.7772 64.7436 74.2972 61.2236C77.9239 65.0636 81.7106 69.0636 85.8706 72.3703Z" fill="#69321B"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_5948_3653">
                            <rect width="539" height="72" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

            </div>
        </div>
    )
}