import React, {useEffect, useState} from "react";
import styles from './index.module.css';
import {getString, peru_name} from "../../localization";
import Logo from "../logo";

export default function Welcome() {
    const [aboutUs, setAboutUs] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [awardIndex, setAwardIndex] = useState(0);

    const award_count = 6;

    useEffect(() => {
        //with a module of 4 we can cycle through the names every seccond
        setAboutUs(getString("about_us"));

        const interval = setInterval(() => {
            setCurrentIndex((prev) => (prev + 1) % 4);
        }, 3000);

        const awardInterval = setInterval(() => {
            setAwardIndex((prev) => (prev + 1) % award_count);
        }, 10000);

        return () => {
            clearInterval(interval);
            clearInterval(awardInterval);
        }
    }, []);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.container}>
                <img src={'/landscapes/main.png'} alt={'main'} width={'100%'} className={styles.croppedImg}/>
                <div className={styles.logoContainer}>
                    <Logo width={'200px'}/>
                </div>
                <div className={styles.languageContainer}>
                    <div onClick={() => {
                        localStorage.setItem("language_index", "2");
                        window.location.reload();
                    }}>CH</div>
                    <div onClick={() => {
                        localStorage.setItem("language_index", "1");
                        window.location.reload();
                    }}>EN</div>
                    <div onClick={() => {
                        localStorage.setItem("language_index", "0");
                        window.location.reload();
                    }}>ES</div>
                </div>
                <div className={styles.textContainer}>
                    <div>{peru_name[currentIndex]}</div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.aboutUsContainer}>
                    <div>
                        <img src={'/decoration/about_us_1.png'} alt={'about_us_1'} width={'100%'}/>
                    </div>
                    <div className={styles.aboutUs}>
                        <img src={'/decoration/about_us_2.png'} alt={'about_us_2'} width={'100%'}/>
                        <div className={styles.aboutUsInfo}>
                            <div>{aboutUs.title}</div>
                            <div>{aboutUs.name}</div>
                            <div>{aboutUs.description}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <img src={'/landscapes/machupicchu_fade2.png'} alt={'macchu_picchu'} width={'100%'} className={styles.imageHighlight}/>
                <div className={styles.highlightContainer}>
                    <div className={styles.highlight}>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <div>#1</div>
                            <img src={'/star.png'} alt={'star'} width={'40px'} height={'auto'}/>
                        </div>
                        <div className={'py-2'}>{getString("awards", awardIndex).title}</div>
                        <div>{getString("awards", awardIndex).description}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}