const about_us = {
    title: "关于我们",
    name: "戴氏集团",
    description: "戴氏集团成立于1998年，由戴丽心· 戴创立，旗下包括戴氏世界和戴氏环球。戴丽心在旅游业的丰富经历激励她致力于提升旅行服务的质量。我们专注于为教育者和学者提供丰富的旅行体验，连接全球目的地，促进跨文化理解。“戴”这个姓氏是对家族传承的致敬，特别是向乔治· 戴的致敬，他是一名移民，因其卓越贡献获得了荣誉勋章。"
};

const awards = [
    {title: "世界旅行奖将秘鲁评为 “最佳文化目的地", description: "秘鲁拥有令人惊叹的考古遗址，如马丘比丘、纳斯卡线和卡拉尔圣城，是历史和文化爱好者的天堂。沉浸在千年文明的遗产中，感受过去的魔力！"},
    {title: "马丘比丘被多次评为 “最佳旅游景点”", description: "这个位于安第斯山脉高处的令人惊叹的考古遗址，让所有游客都目瞪口呆。漫步在古老的梯田间，发现印加文明的秘密，在这个神奇的目的地中。"},
    {title: "秘鲁被评为 “最佳冒险目的地”", description: "多亏了其令人难以置信的地理位置和多样化的活动。从印加古道徒步旅行到曼科拉海滩冲浪，再到瓦卡奇纳沙丘的滑沙和乌鲁班巴河的漂流，秘鲁是冒险爱好者的理想之地。"},
    {title: "最佳历史目的地", description: "秘鲁以其众多且保存完好的考古遗址荣膺 “最佳历史目的地” 之称。从占·占遗址，世界上最大的泥城，到神秘的库埃拉普要塞，这个国家的每一个角落都充满了待发掘的故事。在南美洲的心脏重新体验历史！"},
    {title: "最佳观鸟目的地", description: "秘鲁是观鸟者的天堂，被誉为 “最佳观鸟目的地” 。拥有超过1800种鸟类，包括蜂鸟、秃鹰和特有鸟类，秘鲁为鸟类爱好者提供了令人惊叹的观鸟机会。准备好欣赏色彩和鸣叫的盛宴！"},
    {title: "南美洲最佳美食目的地", description: "除了全球奖项，秘鲁还被评为 “南美洲最佳美食目的地” 。其厨艺将土著传统与欧洲、亚洲和非洲的影响融合在一起，创造出独特的美食体验。品尝那些让秘鲁成为世界美食地图上的标志性味道！"}
]

const destinations = [
    {title: "Cuzco", description: "惊叹于古老印加帝国的心脏——库斯科，这座城市本身就是一件活着的艺术品。印加和殖民建筑的辉煌融合，每条街道和每个广场都在讲述着一个辉煌的过去。探索壮观的太阳神殿——科里坎查，并探索萨克赛华曼的遗址，在那里你可以感受到触手可及的历史。"},
    {title: "Machu Picchu", description: "不要错过皇冠上的明珠——马丘比丘，这座世界奇迹巍然矗立于云雾缭绕的群山之间，提供令人叹为观止的景色和难得一见的魔幻氛围。这个神圣的圣地可以从库斯科乘坐壮观的火车之旅或通过难忘的印加古道到达，承诺一场无与伦比的冒险。库斯科和马丘比丘不仅为我们提供了探索印加过去的窗口，还带来了一种触及每位访客心灵的深刻体验。"},
    {title: "Puno", description: "探索壮丽的普诺，这里是通往神秘的的的喀喀湖——世界上海拔最高的可航行湖泊的大门。沉浸在丰富的文化中，体验令人难忘的热闹节庆。普诺不仅仅是一个目的地，它是一座充满活力的文化奇观，在每一支舞蹈和传统中，过去与现在交织相遇。"},
    {title: "Lima", description: "秘鲁充满活力的首都利马是一幅壮丽的历史、文化与现代化的拼图。从其历史悠久的市中心的宏伟，到米拉弗洛雷斯和巴兰科的现代海岸线，利马提供无与伦比的都市体验。尽情享受其享誉全球的美食，开启一场壮丽的美食之旅。"},
    {title: "Paracas - Ica", description: "帕拉卡斯是秘鲁沙漠海岸上的一片海洋绿洲，令人叹为观止。这里有壮丽的国家自然保护区，拥有丰富的生物多样性；附近还有神秘的纳斯卡线。帕拉卡斯是完美结合冒险与放松的理想自然胜地。 \n" +
            "\n" +
            "不远处的伊卡欢迎你探索广袤的葡萄园，并品尝秘鲁标志性的皮斯科酒。这座城市还是通往神秘的瓦卡奇纳绿洲的大门。瓦卡奇纳是一个被巨大沙丘环绕的壮丽绿洲，在这里你可以体验滑沙和沙漠越野车之旅。"},
    {title: "Iquitos", description: "城市边界，开启激动人心的生态之旅：乘船穿梭于神秘的河流，观赏粉红海豚，与当地原住民社区接触，这些体验将深化你对亚马逊生态系统的理解。伊基托斯为自然爱好者和冒险者提供了一次必不可少且壮观的体验。"},
    {title: "Huaraz", description: "瓦拉斯是冒险者的天堂，在瓦斯卡兰国家公园内，拥有巍峨的雪峰和碧绿的湖泊，为你提供了壮观的自然体验。这里是徒步、登山和自然爱好者的理想目的地，每一条小径都承诺着难忘的冒险。"}
]

const tours = [
    {title: "库斯科梦幻计划 + 圣谷之旅", d: "3天", n: "2夜", days: [
            {title: "第1天 - 马丘比丘游览", description: "在库斯科接待并将乘客从机场送往火车站，乘客可乘坐火车前往马丘比丘。\n" +
                    "在旅途中，您将欣赏到高山丛林的美丽植被和雄伟的雪山，穿过隧道，最终到达阿瓜 斯卡连特斯车站。从那里，我们将前往巴士站，乘坐旅游巴士前往马丘比丘古城（大 约30分钟的车程）。随后，将提供大约2小时的马丘比丘导览游览。在考古遗址内，我 们将参观最重要的地方，如广场、寺庙、仓库、楼梯、水源、梯田、瞭望塔、墓地， 以及太阳神庙、因提瓦坦纳（日晷）、三窗寺等景点。导览结束后，您将有时间在旅 游餐厅享用午餐。按计划的时间，乘坐返回火车并转移到库斯科的酒店。 库斯科酒店过夜。" },
            {title: "第2天 - 印加圣谷之旅", description: "这次旅行从酒店接送乘客开始，约早上7:30。然后，我们前往圣谷（1小时半的车程） ，参观圣谷最重要的地方。我们将从参观皮萨克考古中心开始，您可以欣赏到印加时 期令人印象深刻的梯田和该镇本身。继续穿越印加圣谷的旅程，我们在旅游餐厅停下 来享用自助午餐。午餐后（大约30分钟的车程），我们将欣赏奥亚坦坦博堡垒，并观 察到农业区域，其中最突出的是没有灌溉的玉米种植，以及安第斯山脉的块茎和谷物 种植。然后，我们将前往金切罗观景台，这是旅程的最后一个点。这个小镇是梅斯蒂 \n" +
                    "索和殖民风格的美丽典范。在指定的时间返回库斯科，大约下午6点到达。 库斯科酒店过夜。"},
            {title: "第3天 - 上午城市观光", description: "上午08:00至08:20之间，我们的接送将在您的酒店接您，将您送往旅游的起点。游览从 库斯科大教堂开始，然后在历史中心的街道上短暂步行，前往Qoricancha或太阳神庙， 被认为是一个印第安宗教圣地。然后，我们将乘坐车辆前往萨克萨瓦曼，距离库斯科 市3公里，为我们提供了一览城市的全景。接下来，我们将参观Qenqo，意思是迷宫， 位于萨克萨瓦曼一公里之外。之后，我们将探索普卡普卡拉，在克丘亚语中意为“红色 堡垒”。最后，我们将参观坦博马恰伊。游览将于下午约1:45结束。 \n" +
                    "在指定时间，进行出发接送。 \n" +
                    "服务结束。\n"},
        ]},
    {
        title: "库斯科魅力", d: "4天", n: "3夜", days: [
            {
                title: "第1天 - 城市观光之旅",
                description: "在库斯科接待并将乘客从机场送往他们选择的酒店。 \n 在12:45至13:00之间，我们的接送将在您的酒店接您，将您送往旅游的起点。游览从库 斯科大教堂开始，然后在历史中心的街道上短暂步行，前往Qoricancha或太阳神庙，被 认为是一个印第安宗教圣地。然后，我们将乘坐车辆前往萨克萨瓦曼，距离库斯科市3 公里，为我们提供了一览城市的全景。接下来，我们将参观Qenqo，意思是迷宫，位于 萨克萨瓦曼一公里之外。之后，我们将探索普卡普卡拉，在克丘亚语中意为“红色堡垒 \n" +
                    "”。最后，我们将参观坦博马恰伊。游览将于晚上约18:30结束。 \n" +
                    "库斯科酒店过夜。\n"
            },
            {
                title: "第2天 - 彩虹山",
                description: "• 04:30：从酒店接送，开始全天的彩虹山之旅。 \n" +
                    "• 05:00：乘坐旅游巴士前往Cusipata地区。 \n" +
                    "• 06:30：到达Chirihuani（Cusipata），享用早餐并休息。 \n" +
                    "• 08:30：到达Phulawasipata。 \n" +
                    "• 08:45：开始徒步穿越Vinicunca彩虹山。 \n" +
                    "• 10:30：在Vinicunca山探索并拍照，由导游介绍和解释。 \n" +
                    "• 11:30：返回Phulawasipata并返回Cusipata。 \n" +
                    "• 14:00：自由用餐时间（包括在内）。 \n" +
                    "• 15:00：从Cusipata返回库斯科的巴士出发。 \n" +
                    "• 16:30：抵达库斯科市。 \n" +
                    "库斯科酒店过夜。\n"
            },
            {
                title: "第3天 - 马丘比丘之旅",
                description: "酒店早餐 \n 在指定时间，从您的酒店前往火车站，开始您的前往马丘比丘的火车之旅。在旅途中 ，您将欣赏到高山丛林的美丽植被和雄伟的雪山，穿过隧道，最终到达阿瓜斯卡连特 斯车站。从那里，我们将前往巴士站，乘坐旅游巴士前往马丘比丘古城（大约30分钟 \n" +
                    "的车程）。随后，将提供大约2小时的马丘比丘导览游览。在考古遗址内，我们将参观 最重要的地方，如广场、寺庙、仓库、楼梯、水源、梯田、瞭望塔、墓地，以及太阳 神庙、因提瓦坦纳（日晷）、三窗寺等景点。导览结束后，您将有时间在旅游餐厅享 用午餐。按计划的时间，乘坐返回火车并转移到库斯科的酒店。 \n" +
                    "库斯科酒店过夜。\n"
            },
            {
                title: "第4天 - 机场接送", description: "酒店早餐\n" +
                    "在指定时间，进行出发接送。\n" +
                    "服务结束。"

            }
        ]
    },
    {
        title: "TAQUILE梦幻 (普诺)", d: "3天", n: "2夜", days: [
            {title: "第1天 - 下午参观CHULLPAS DE SILLUSTANI", description: "抵达普诺市并转移到所选酒店。\n 这是前印加时期的纪念性墓葬建筑，有些高达12米。它们 是用火山岩雕刻而成的，包括Lagarto Chullpa和12角Chullpa等。整个考古遗址位于UMAYO湖畔 ，这个湖与地下的Titicaca湖相通。在指定的时间返回普诺并转移到您的酒店。 普诺酒店过夜。"},
            {title: "第2天 - 参观UROS和TAQUILE ", description: "酒店早餐。\n 早上7:00从酒店前往码头，搭乘我们的机动艇游览乌洛斯浮岛，大约30分钟的航程。在简短的 参观后（约40分钟），我们将继续前往Taquile自然岛。该岛的居民主要从事农业、手工艺和渔 业。当地人继续按照他们古老的印加传统和法律生活，包括习俗、宗教仪式和民间艺术，如婚 姻（Sirvinacuy）和向大地的供奉（Pacha mama）。 \n" +
                    "中午12:00，岛上享用典型午餐。 \n" +
                    "午餐后：乘客将有两个小时的自由时间参观村庄的手工艺品中心并拍摄全景照片，然后返回普 诺市（返回到各自的酒店）。 \n" +
                    "普诺酒店过夜。 \n"},
            {title: "第3天 - 机场接送", description: "酒店早餐。\n 在指定时间前往机场或汽车站。"}
        ]
    },
    {
        title: "亚马逊雨林探险", d: "3天", n: "2夜", days: [
            {title: "第1天 - 伊基托斯 - 塞巴顶 ", description: "抵达机场或酒店后，前往Explorama码头。沿着亚马逊河40公里的船程抵达塞巴顶，那 里提供设备齐全的带空调和热水的私人浴室，为您在雨林中的住宿提供舒适的家一般\n" +
                    "的感觉。下午，您可以在被原始森林包围的环境中徒步旅行，探索高大的木棉树，这 也是塞巴顶旅馆的名字来源。晚餐后，进行夜间徒步活动，观察昆虫、青蛙、蜘蛛， 并聆听雨林的声音。 \n" +
                    "餐食：午餐，晚餐 \n" +
                    "住宿：雨林豪华小屋\n"},
            {title: "第2天 - 塞巴顶", description: "清晨乘船观鸟，欣赏亚马逊河的日出。返回塞巴顶享用早餐后，参观灰色河和粉红河 的交汇点。然后，前往雅瓜(Yagua)小社区，导游将为您介绍雅瓜文化。您将有机会观 看当地手工艺品展示和吹箭猎杀技术。下午，进行钓鱼活动，并欣赏亚马逊河上的日 落，如果天气允许的话。 \n" +
                    "餐食：早餐，午餐，晚餐 \n" +
                    "住宿：雨林豪华小屋\n"},
            {title: "第3天 - 塞巴顶 - 伊基托斯", description: "乘船前往河边小镇，导游将向您介绍秘鲁亚马逊河流域的文化，并参观当地学校。返 回伊基托斯，然后前往利马或您选择的酒店。 \n" +
                    "餐食：早餐，午餐（如果您的航班是下午） \n" +
                    "备注：行程可能会因天气条件而变更。 \n"}
        ]
    }
]

const footer = {
    contact_us: "联系我们",
    about_us: "关于我们",
    destinations: "目的地",
    email_us: "给我们发邮件",
}

export {
    awards as awards_cn,
    about_us as about_us_cn,
    destinations as destinations_cn,
    tours as tours_cn,
    footer as footer_cn
}