const about_us = {
    title: "Acerca de Nosotros",
    name: "Tay’s Mundo",
    description: "Fundado en 1998, Grupo Tay’s, que incluye Tay’s World y Tay’s Mundo, fue establecido por nuestra fundadora, Alicia Tay, quien, motivada por su propia experiencia en el sector turístico, se propuso elevar la calidad de los servicios de viaje. Nos especializamos en ofrecer experiencias de viaje enriquecedoras para educadores y académicos, conectándolos con destinos globales y fomentando el entendimiento intercultural. El apellido Tay es un tributo a la herencia familiar, en particular a Jorge Tay, un inmigrante que fue galardonado con una medalla de honor por sus notables contribuciones."
};

const awards = [
    {title: "Mejor Destino Cultural del Mundo", description: "Perú ha sido galardonado como el \"Mejor Destino Cultural del Mundo\" en los World Travel Awards. Con sus impresionantes sitios arqueológicos como Machu Picchu, las Líneas de Nazca y la ciudad sagrada de Caral, Perú es un paraíso para los amantes de la historia y la cultura. ¡Sumérgete en el legado de civilizaciones milenarias y vive la magia del pasado!"},
    {title: "Mejor Atracción Turística del Mundo", description: "Machu Picchu ha sido nombrado la \"Mejor Atracción Turística del Mundo\" en múltiples ocasiones. Este asombroso sitio arqueológico, situado en las alturas de los Andes, es una maravilla que deja sin aliento a todos sus visitantes. Camina por sus antiguas terrazas y descubre los secretos de la civilización inca en este destino mágico."},
    {title: "Mejor Destino de Aventura del Mundo", description: "Perú ha sido premiado como el \"Mejor Destino de Aventura del Mundo\" gracias a su increíble geografía y diversidad de actividades. Desde el senderismo en el Camino Inca y el surf en las playas de Máncora, hasta el sandboarding en las dunas de Huacachina y el rafting en el río Urubamba, Perú es el lugar perfecto para los amantes de la adrenalina."},
    {title: "Mejor Destino Histórico", description: "Perú ha ganado el título de \"Mejor Destino Histórico\" por sus numerosos y bien preservados sitios arqueológicos. Desde las ruinas de Chan Chan, la ciudad de barro más grande del mundo, hasta la misteriosa Fortaleza de Kuelap, cada rincón del país está lleno de historias por descubrir. ¡Revive la historia en el corazón de Sudamérica!"},
    {title: "Mejor Destino Gastronómico de Sudamérica", description: "Además de los premios globales, Perú ha sido nombrado el \"Mejor Destino Gastronómico de Sudamérica\". Su cocina fusiona tradiciones indígenas con influencias europeas, asiáticas y africanas, creando una experiencia culinaria única. ¡Degusta la riqueza de sabores que han puesto a Perú en el mapa gastronómico mundial!"},
    {title: "Mejor Destino para la Observación de Aves", description: "Perú es un paraíso para los observadores de aves y ha sido reconocido como el \"Mejor Destino para la Observación de Aves\". Con más de 1,800 especies de aves, incluidos colibríes, cóndores y aves endémicas, Perú ofrece increíbles oportunidades para los amantes de la ornitología. ¡Prepárate para un espectáculo de colores y cantos!"}
]

const destinations = [
    {title: "Cuzco", description: "Maravíllate con Cuzco, el corazón del antiguo Imperio Inca, una ciudad que es en sí misma una obra de arte viviente. Espléndido en su mezcla de arquitectura Inca y colonial, cada calle y cada plaza te cuentan historias de un pasado glorioso. Descubre el espectacular Templo del Sol, Qoricancha, y explora las ruinas de Sacsayhuamán, donde la historia se siente palpable."},
    {title: "Machu Picchu", description: "No te pierdas la joya de la corona, Machu Picchu, una maravilla del mundo que se alza majestuosamente entre montañas neblinosas, ofreciendo vistas que cortan la respiración y un ambiente mágico que pocas veces se puede experimentar en la vida. Este santuario sagrado, accesible desde Cuzco a través de un viaje espectacular en tren o siguiendo el inolvidable Camino Inca, promete una aventura que es, sin duda, inolvidable. Cuzco y Machu Picchu juntos no solo ofrecen una ventana al pasado Inca, sino también una experiencia profunda que toca el alma de cada visitante."},
    {title: "Puno", description: "Descubre el espléndido Puno, la puerta de entrada al místico Lago Titicaca, el lago navegable más alto del mundo. Sumérgete en una cultura rica y presencia festividades vibrantes que son simplemente inolvidables. Puno no es solo un destino, es una maravilla cultural viva donde el pasado y presente se encuentran en cada danza y tradición."},
    {title: "Lima", description: "La vibrante capital del Perú, Lima es un espléndido mosaico de historia, cultura y modernidad. Desde la majestuosidad de su centro histórico hasta la moderna costa de Miraflores y Barranco, Lima ofrece una experiencia urbana sin igual. Deléitate con su reconocida gastronomía, que te llevará en un viaje culinario espectacular."},
    {title: "Paracas - Ica", description: "Un oasis marino en la costa del desierto peruano, Paracas es simplemente maravilloso. Con su impresionante Reserva Nacional que alberga una diversidad biológica asombrosa y las misteriosas Líneas de Nazca cercanas, Paracas es perfecto para aquellos que buscan combinar aventura y relajación en un entorno natural único. \n" +
            "\n" +
            "A poca distancia, Ica te invita a descubrir sus vastos viñedos y a probar el pisco, el emblemático licor peruano. La ciudad es también la puerta de entrada a la misteriosa Laguna de Huacachina, un oasis espectacular rodeado de enormes dunas de arena donde puedes practicar sandboarding y paseos en buggy."},
    {title: "Iquitos", description: "Más allá de sus límites, embárcate en emocionantes excursiones ecológicas: navega por ríos misteriosos, observa delfines rosados, y conecta con comunidades indígenas que enriquecerán tu comprensión del ecosistema amazónico. Iquitos ofrece una experiencia esencial y espectacular para los aventureros y amantes de la naturaleza."},
    {title: "Huaraz", description: "El paraíso de los aventureros, Huaraz ofrece una experiencia natural espectacular con sus imponentes picos nevados y lagunas de color turquesa en el Parque Nacional Huascarán. Es un destino ideal para los amantes del trekking, montañismo y naturaleza, prometiendo aventuras inolvidables en cada sendero."}
]

const tours = [
    {title: "Programa Cusco ensueño + Tour Valle Sagrado", d: "3D", n: "2N", days: [
            {title: "Día 1: Excursión Machu Picchu", description: "Recepción en Cusco y traslado del aeropuerto a la Estación de Tren, para que pasajeros  puedan tomar el tren rumbo a Machupicchu \n" +
                    "Durante el recorrido apreciará la hermosa vegetación característica de la Selva Alta y  majestuosos nevados, atravesará túneles y finalmente llegara a la estación  de Aguas Calientes, nos dirigirnos a la estación de Buses donde abordaremos al bus de  transporte turístico que nos llevara hasta la ciudadela de Machupicchu¸ (½ horas de viaje  aprox.) posteriormente se les brindará el guiado dentro de Machu Picchu por aprox. 2hs,  dentro del sitio Arqueológico visitaremos los lugares más importantes como las Plazas,  templos, depósitos, escalinatas, fuentes de agua, andenes y puestos de vigilancias,  cementerios, así como el templo del sol, el Intiwatana (Reloj Solar) el templo de las tres  ventanas entre otros encantos. Después la de la visita guiada tendrán tiempo para el almuerzo  en restaurante turístico. A hora indicada abordar el tren de retorno y transfer a  su hotel en Cusco." },
            {title: "Día 2: Excursión Valle Sagrado de los Incas", description: "Este tour inicia con el recojo de los pasajeros de su hotel a las 7:30 am aprox. Luego nos  dirigimos al Valle Sagrado¸ (1 ½ horas de viaje) para visitar los lugares más importantes del  Valle, empezaremos con visitar el centro arqueológico de Pisac, desde donde se puede  apreciar los impresionantes andenes de la época incaica y el pueblo de la misma. Continuando  con el viaje por el Valle Sagrado de los Incas, hacemos una parada, para el almuerzo buffet en  restaurante turístico. Después del Almuerzo a unos (30 min. de viaje) disfrutaremos de la  fortaleza de Ollantaytambo y observaremos las áreas agrícolas; lo que más resalta es el cultivo  de maíz sin riego, también el cultivo de tubérculos andinos y de cereales. Luego nos  trasladaremos al mirador de Chinchero, el último punto del recorrido. Este pueblo es un bello  ejemplo del estilo mestizo y colonial, a hora indicada regreso a Cusco Arribando a las 18.00hrs  aproximadamente."},
            {title: "Día 3: AM City Tours", description: "Entre las 08:00 hrs a 08:20 hrs nuestro transfer lo visitara en su Hotel para trasladarlo al lugar  de inicio de su Tour,. El tour inicia en La Catedral del Cusco, Seguidamente realizaremos una  breve caminata por las calles del centro histórico rumbo al Qoricancha o Templo del Sol  considerado un santuario religioso indígena. Luego abordaremos la movilidad que nos llevara  a Sacsayhuaman ubicada a 3km. de la ciudad del Cusco, Sacsayhuaman nos ofrece una visión  panorámica de la ciudad de Cusco Luego nos dirigiremos a Qenqo, que significa laberinto, se  encuentra ubicado a un kilómetro de Sacsayhuaman, despues conoceremos Puca Pucara:  proveniente de dos palabras quechua que significa Puca (rojo) Pucara (fortaleza) “fortaleza  roja. Finalmente visitaremos Tambomachay. Finalizando en la ciudad del Cusco a las 13:45 hrs  aproximadamente."},
        ]},
    {
        title: "Cusco Encanto", d: "4D", n: "3N", days: [
            {
                title: "Día 1: Excursión city tours",
                description: "Recepción en Cusco y traslado del aeropuerto al hotel de su elección. Entre las 12:45 hrs y 13:00 hrs nuestro transfer lo visitara en su Hotel para trasladarlo al lugar  de inicio de su Tour,. El tour inicia en La Catedral del Cusco, Seguidamente realizaremos una  breve caminata por las calles del centro histórico rumbo al Qoricancha o Templo del Sol  considerado un santuario religioso indígena. Luego abordaremos la movilidad que nos llevara  a Sacsayhuaman ubicada a 3km. de la ciudad del Cusco, Sacsayhuaman nos ofrece una visión  panorámica de la ciudad de Cusco Luego nos dirigiremos a Qenqo, que significa laberinto, se  encuentra ubicado a un kilómetro de Sacsayhuaman, despues conoceremos Puca Pucara:  proveniente de dos palabras quechua que significa Puca (rojo) Pucara (fortaleza) “fortaleza  roja. Finalmente visitaremos Tambomachay. Finalizando en la ciudad del Cusco a las 18:30 hrs  aproximadamente."
            },
            {
                title: "Día 2: Montaña de colores",
                description: "• 04:30 hrs: Recojo desde su hotel, inicio del tour al Full Day Montaña de Colores . • 05:00 hrs: Traslado en bus turístico hacia el distrito de Cusipata. \n" +
                    "• 06:30 hrs: Arribo a Chirihuani (Cusipata), desayuno y receso. \n" +
                    "• 08:30 hrs: Arribo a Phulawasipata. \n" +
                    "• 08:45 hrs: Inicio de la caminata hacia la Montaña de Colores Vinicunca. • 10:30 hrs: Exploración como fotografias en Vinicunca, reseña y explicación del guía. • 11:30 hrs: Caminata hacia Phulawasipata y retorno a Cusipata. \n" +
                    "• 14:00 hrs: Tiempo libre para almorzar (incluido) . \n" +
                    "• 15:00 hrs: Hora de salida del bus desde Cusipata a Cusco. \n" +
                    "• 16:30 hrs: Arribo a la ciudad del Cusco.\n"
            },
            {
                title: "Día 3: Excursión Machu Picchu",
                description: "Desayuno en el Hotel. A hora indicada traslado de su Hotel a la estación de tren donde se inicia su viaje a Machu  Picchu en tren de su elección. Durante el recorrido apreciará la hermosa vegetación  característica de la Selva Alta y majestuosos nevados, atravesará túneles y finalmente llegara  a la estación de Aguas Calientes, nos dirigirnos a la estación de Buses donde abordaremos al  bus de transporte turístico que nos llevara hasta la ciudadela de Machupicchu¸ (½ horas de  viaje aprox.) posteriormente se les brindará el guiado dentro de Machu Picchu por aprox. 2hs,  dentro del sitio Arqueológico visitaremos los lugares más importantes como las Plazas,  templos, depósitos, escalinatas, fuentes de agua, andenes y puestos de vigilancias,  cementerios, así como el templo del sol, el Intiwatana (Reloj Solar) el templo de las tres  ventanas entre otros encantos. Después la de la visita guiada tendrán tiempo para el almuerzo  en restaurante turístico. A hora indicada abordar el tren de retorno y transfer a  su hotel en Cusco."
            },
            {
                title: "Día 4: Traslado al aeropuerto", description: "Desayuno en el Hotel  \n" +
                    "A hora indicada traslado de salida. \n" +
                    "Fin de los servicios.\n"
            }
        ]
    },
    {
        title: "Taquile Ensueño (Puno)", d: "3D", n: "2N", days: [
            {title: "Día 1: PM Exc. CHULLPAS DE SILLUSTANI", description: "Arribo a la ciudad de Puno y traslado a hotel elegido. CHULLPAS DE SILLUSTANI Son monumentales edificaciones de carácter funerario pre-incas  la elevación de algunas hasta de 12 Mts. Labradas en piedra volcánica, La Chullpa del Lagarto, La de  los 12 ángulos. Todo el complejo arqueológico se encuentra a orillas del Lago UMAYO, el mismo que  subterráneamente se comunica con el Lago Titicaca, a hora indicada regreso a Puno y transfer su  Hotel. "},
            {title: "Día 2: Exc. UROS Y TAQUILE", description: "07:00 Hrs. Traslado del hotel con dirección al puerto, para abordar nuestra lancha a motor y visitar las  islas flotantes de Los Uros, que están a 30 minutos aproximadamente. Después de una visita breve (40  min. apróx.), Continuaremos el viaje hacia la Isla Natural de Taquile. Cuyos pobladores se dedican a la  Agricultura, artesanía y pesca principalmente. Los lugareños continúan viviendo de acuerdo a sus  antiguas tradiciones y leyes Incas con manifestaciones costumbristas, rituales religiosos y folklóricas  como: El matrimonio (Sirvinacuy), el pago a la tierra (Pacha mama)." +
                "12.00 Hrs. (medio día), Almuerzo típico en la isla." +
                "Después del almuerzo: Los Pasajeros, tendrán dos horas libres para visitar el centro artesanal del  pueblo y tomar fotografías con vistas panorámicas y posteriormente retornar a la ciudad de Puno (a  sus respectivos hoteles)."},
            {title: "Día 3: Traslado al aeropuerto", description: "Desayuno en el Hotel  \n A hora indicada traslado al aeropuerto o estación de bus. "}

        ]
    },
    {
        title: "Aventura en la selva amazónica", d: "3D", n: "2N", days: [
            {title: "Día 1: Iquitos - Ceiba Tops", description: "Llegada al aeropuerto o hotel y traslado al muelle de Explorama. Un viaje en bote de 40 km a  lo largo del río Amazonas te lleva a Ceiba Tops, donde habitaciones con aire acondicionado y  baños privados con agua caliente brindan una comodidad similar a la de casa en la selva. Por \n" +
                    "la tarde, disfruta de una caminata por la jungla rodeada de bosque prístino, hogar de los  gigantescos árboles de ceiba, después de los cuales se nombra el lodge. Después de la cena,  realiza una caminata nocturna para observar insectos, ranas, tarántulas y escuchar los sonidos  de la jungla. \n" +
                    "• Comidas: Almuerzo, Cena \n" +
                    "• Alojamiento: Lodge de Lujo en la Selva \n"},
            {title: "Día 2: Ceiba Tops", description: "Observación de aves temprano en la mañana en bote y amanecer sobre el río Amazonas.  Regreso a Ceiba Tops para desayunar. Visita al punto de encuentro de dos ríos, los ríos gris y  rosa. Luego, visita una pequeña comunidad indígena Yagua donde el guía explicará la cultura  Yagua. Disfruta de demostraciones de artesanías locales y técnicas de caza con cerbatana. Por  la tarde, realiza un viaje de pesca y, si el clima lo permite, disfruta de una puesta de sol sobre  el río Amazonas. \n" +
                    "• Comidas: Desayuno, Almuerzo, Cena\n" +
                    "• Alojamiento: Lodge de Lujo en la Selva"},
            {title: "Día 3: Ceiba Tops - Iquitos", description: "Viaje en bote a un pueblo ribereño donde el guía explicará la cultura de los pueblos del río  Amazonas peruano y visitará la escuela del pueblo. Regreso a Iquitos, luego a Lima o al hotel  de su elección.\n" +
                    "• Comidas: Desayuno, Almuerzo (si tu vuelo es por la tarde)\n" +
                    "Nota: El itinerario puede cambiar debido a condiciones climáticas."}
            ]
    }
]

const footer = {
    contact_us: "Contáctenos",
    about_us: "Sobre Nosotros",
    destinations: "Destinos",
    email_us: "Escríbanos",
}

export {
    awards as awards_es,
    about_us as about_us_es,
    destinations as destinations_es,
    tours as tours_es,
    footer as footer_es
}