const about_us = {
    title: "关于我们",
    name: "戴氏集团",
    description: "戴氏麗景集团 在秘鲁成立于 1998 年，由我们的创始人- 自1983年移民到秘鲁的戴麗心女士创立。她在中国以及秘鲁都受过良好的教育基础上，以及在秘鲁41年的生活阅历， 憑借豐富的26載旅遊經驗，無論您是來訪充滿活力的首都利馬還是落腳庫斯科和令人印像深刻的印加遺址馬丘比丘又或者是登上訂製的河船順著強大的叢林探索亞馬遜熱帶雨林的奧秘，每一个旅程都会让您感受魔力，豐富體驗，享受樂趣！\n" +
		"服務您的精神26年不變 :  誠信，專業，認真 " +
		"\"戴 \" 这个姓氏是对祖父戴宗漢精神的傳承和延續，1968年秘鲁首位获得政府颁发的”农业勋章”获獎者，被时任总统赞誉为 “秘鲁农业功臣”.  1988年,被授予 “广州市名誉市民”称号."
};

const awards = [
    {title: "世界旅行奖将秘鲁评为 “最佳文化目的地", description: "秘鲁拥有令人惊叹的考古遗址，如马丘比丘、纳斯卡线和卡拉尔圣城，是历史和文化爱好者的天堂。沉浸在千年文明的遗产中，感受过去的魔力！"},
    {title: "马丘比丘被多次评为 “最佳旅游景点”", description: "这个位于安第斯山脉高处的令人惊叹的考古遗址，让所有游客都目瞪口呆。漫步在古老的梯田间，发现印加文明的秘密，在这个神奇的目的地中。"},
    {title: "秘鲁被评为 “最佳冒险目的地”", description: "多亏了其令人难以置信的地理位置和多样化的活动。从印加古道徒步旅行到曼科拉海滩冲浪，再到瓦卡奇纳沙丘的滑沙和乌鲁班巴河的漂流，秘鲁是冒险爱好者的理想之地。"},
    {title: "最佳历史目的地", description: "秘鲁以其众多且保存完好的考古遗址荣膺 “最佳历史目的地” 之称。从占·占遗址，世界上最大的泥城，到神秘的库埃拉普要塞，这个国家的每一个角落都充满了待发掘的故事。在南美洲的心脏重新体验历史！"},
    {title: "最佳观鸟目的地", description: "秘鲁是观鸟者的天堂，被誉为 “最佳观鸟目的地” 。拥有超过1800种鸟类，包括蜂鸟、秃鹰和特有鸟类，秘鲁为鸟类爱好者提供了令人惊叹的观鸟机会。准备好欣赏色彩和鸣叫的盛宴！"},
    {title: "南美洲最佳美食目的地", description: "除了全球奖项，秘鲁还被评为 “南美洲最佳美食目的地” 。其厨艺将土著传统与欧洲、亚洲和非洲的影响融合在一起，创造出独特的美食体验。品尝那些让秘鲁成为世界美食地图上的标志性味道！"}
]

const destinations = [
    {title: "Cuzco", description: "惊叹于古老印加帝国的心脏——库斯科，这座城市本身就是一件活着的艺术品。印加和殖民建筑的辉煌融合，每条街道和每个广场都在讲述着一个辉煌的过去。探索壮观的太阳神殿——科里坎查，并探索萨克赛华曼的遗址，在那里你可以感受到触手可及的历史。"},
    {title: "Machu Picchu", description: "不要错过皇冠上的明珠——马丘比丘，这座世界奇迹巍然矗立于云雾缭绕的群山之间，提供令人叹为观止的景色和难得一见的魔幻氛围。这个神圣的圣地可以从库斯科乘坐壮观的火车之旅或通过难忘的印加古道到达，承诺一场无与伦比的冒险。库斯科和马丘比丘不仅为我们提供了探索印加过去的窗口，还带来了一种触及每位访客心灵的深刻体验。"},
    {title: "Puno", description: "探索壮丽的普诺，这里是通往神秘的的的喀喀湖——世界上海拔最高的可航行湖泊的大门。沉浸在丰富的文化中，体验令人难忘的热闹节庆。普诺不仅仅是一个目的地，它是一座充满活力的文化奇观，在每一支舞蹈和传统中，过去与现在交织相遇。"},
    {title: "Lima", description: "秘鲁充满活力的首都利马是一幅壮丽的历史、文化与现代化的拼图。从其历史悠久的市中心的宏伟，到米拉弗洛雷斯和巴兰科的现代海岸线，利马提供无与伦比的都市体验。尽情享受其享誉全球的美食，开启一场壮丽的美食之旅。"},
    {title: "Paracas - Ica", description: "帕拉卡斯是秘鲁沙漠海岸上的一片海洋绿洲，令人叹为观止。这里有壮丽的国家自然保护区，拥有丰富的生物多样性；附近还有神秘的纳斯卡线。帕拉卡斯是完美结合冒险与放松的理想自然胜地。 \n" +
            "\n" +
            "不远处的伊卡欢迎你探索广袤的葡萄园，并品尝秘鲁标志性的皮斯科酒。这座城市还是通往神秘的瓦卡奇纳绿洲的大门。瓦卡奇纳是一个被巨大沙丘环绕的壮丽绿洲，在这里你可以体验滑沙和沙漠越野车之旅。"},
    {title: "Iquitos", description: "城市边界，开启激动人心的生态之旅：乘船穿梭于神秘的河流，观赏粉红海豚，与当地原住民社区接触，这些体验将深化你对亚马逊生态系统的理解。伊基托斯为自然爱好者和冒险者提供了一次必不可少且壮观的体验。"},
    {title: "Huaraz", description: "瓦拉斯是冒险者的天堂，在瓦斯卡兰国家公园内，拥有巍峨的雪峰和碧绿的湖泊，为你提供了壮观的自然体验。这里是徒步、登山和自然爱好者的理想目的地，每一条小径都承诺着难忘的冒险。"}
]

const tours = [
    {title: "库斯科梦幻计划 + 圣谷之旅", d: "3天", n: "2夜", days: [
            {title: "第1天 - 马丘比丘游览", description: "在库斯科接待并将乘客从机场送往火车站，乘客可乘坐火车前往马丘比丘。\n" +
                    "在旅途中，您将欣赏到高山丛林的美丽植被和雄伟的雪山，穿过隧道，最终到达阿瓜 斯卡连特斯车站。从那里，我们将前往巴士站，乘坐旅游巴士前往马丘比丘古城（大 约30分钟的车程）。随后，将提供大约2小时的马丘比丘导览游览。在考古遗址内，我 们将参观最重要的地方，如广场、寺庙、仓库、楼梯、水源、梯田、瞭望塔、墓地， 以及太阳神庙、因提瓦坦纳（日晷）、三窗寺等景点。导览结束后，您将有时间在旅 游餐厅享用午餐。按计划的时间，乘坐返回火车并转移到库斯科的酒店。 库斯科酒店过夜。" },
            {title: "第2天 - 印加圣谷之旅", description: "这次旅行从酒店接送乘客开始，约早上7:30。然后，我们前往圣谷（1小时半的车程） ，参观圣谷最重要的地方。我们将从参观皮萨克考古中心开始，您可以欣赏到印加时 期令人印象深刻的梯田和该镇本身。继续穿越印加圣谷的旅程，我们在旅游餐厅停下 来享用自助午餐。午餐后（大约30分钟的车程），我们将欣赏奥亚坦坦博堡垒，并观 察到农业区域，其中最突出的是没有灌溉的玉米种植，以及安第斯山脉的块茎和谷物 种植。然后，我们将前往金切罗观景台，这是旅程的最后一个点。这个小镇是梅斯蒂 \n" +
                    "索和殖民风格的美丽典范。在指定的时间返回库斯科，大约下午6点到达。 库斯科酒店过夜。"},
            {title: "第3天 - 上午城市观光", description: "上午08:00至08:20之间，我们的接送将在您的酒店接您，将您送往旅游的起点。游览从 库斯科大教堂开始，然后在历史中心的街道上短暂步行，前往Qoricancha或太阳神庙， 被认为是一个印第安宗教圣地。然后，我们将乘坐车辆前往萨克萨瓦曼，距离库斯科 市3公里，为我们提供了一览城市的全景。接下来，我们将参观Qenqo，意思是迷宫， 位于萨克萨瓦曼一公里之外。之后，我们将探索普卡普卡拉，在克丘亚语中意为“红色 堡垒”。最后，我们将参观坦博马恰伊。游览将于下午约1:45结束。 \n" +
                    "在指定时间，进行出发接送。 \n" +
                    "服务结束。\n"},
        ]},
    {
        title: "库斯科魅力", d: "4天", n: "3夜", days: [
            {
                title: "第1天 - 城市观光之旅",
                description: "在库斯科接待并将乘客从机场送往他们选择的酒店。 \n 在12:45至13:00之间，我们的接送将在您的酒店接您，将您送往旅游的起点。游览从库 斯科大教堂开始，然后在历史中心的街道上短暂步行，前往Qoricancha或太阳神庙，被 认为是一个印第安宗教圣地。然后，我们将乘坐车辆前往萨克萨瓦曼，距离库斯科市3 公里，为我们提供了一览城市的全景。接下来，我们将参观Qenqo，意思是迷宫，位于 萨克萨瓦曼一公里之外。之后，我们将探索普卡普卡拉，在克丘亚语中意为“红色堡垒 \n" +
                    "”。最后，我们将参观坦博马恰伊。游览将于晚上约18:30结束。 \n" +
                    "库斯科酒店过夜。\n"
            },
            {
                title: "第2天 - 彩虹山",
                description: "• 04:30：从酒店接送，开始全天的彩虹山之旅。 \n" +
                    "• 05:00：乘坐旅游巴士前往Cusipata地区。 \n" +
                    "• 06:30：到达Chirihuani（Cusipata），享用早餐并休息。 \n" +
                    "• 08:30：到达Phulawasipata。 \n" +
                    "• 08:45：开始徒步穿越Vinicunca彩虹山。 \n" +
                    "• 10:30：在Vinicunca山探索并拍照，由导游介绍和解释。 \n" +
                    "• 11:30：返回Phulawasipata并返回Cusipata。 \n" +
                    "• 14:00：自由用餐时间（包括在内）。 \n" +
                    "• 15:00：从Cusipata返回库斯科的巴士出发。 \n" +
                    "• 16:30：抵达库斯科市。 \n" +
                    "库斯科酒店过夜。\n"
            },
            {
                title: "第3天 - 马丘比丘之旅",
                description: "酒店早餐 \n 在指定时间，从您的酒店前往火车站，开始您的前往马丘比丘的火车之旅。在旅途中 ，您将欣赏到高山丛林的美丽植被和雄伟的雪山，穿过隧道，最终到达阿瓜斯卡连特 斯车站。从那里，我们将前往巴士站，乘坐旅游巴士前往马丘比丘古城（大约30分钟 \n" +
                    "的车程）。随后，将提供大约2小时的马丘比丘导览游览。在考古遗址内，我们将参观 最重要的地方，如广场、寺庙、仓库、楼梯、水源、梯田、瞭望塔、墓地，以及太阳 神庙、因提瓦坦纳（日晷）、三窗寺等景点。导览结束后，您将有时间在旅游餐厅享 用午餐。按计划的时间，乘坐返回火车并转移到库斯科的酒店。 \n" +
                    "库斯科酒店过夜。\n"
            },
            {
                title: "第4天 - 机场接送", description: "酒店早餐\n" +
                    "在指定时间，进行出发接送。\n" +
                    "服务结束。"

            }
        ]
    },
    {
        title: "TAQUILE梦幻 (普诺)", d: "3天", n: "2夜", days: [
            {title: "第1天 - 下午参观CHULLPAS DE SILLUSTANI", description: "抵达普诺市并转移到所选酒店。\n 这是前印加时期的纪念性墓葬建筑，有些高达12米。它们 是用火山岩雕刻而成的，包括Lagarto Chullpa和12角Chullpa等。整个考古遗址位于UMAYO湖畔 ，这个湖与地下的Titicaca湖相通。在指定的时间返回普诺并转移到您的酒店。 普诺酒店过夜。"},
            {title: "第2天 - 参观UROS和TAQUILE ", description: "酒店早餐。\n 早上7:00从酒店前往码头，搭乘我们的机动艇游览乌洛斯浮岛，大约30分钟的航程。在简短的 参观后（约40分钟），我们将继续前往Taquile自然岛。该岛的居民主要从事农业、手工艺和渔 业。当地人继续按照他们古老的印加传统和法律生活，包括习俗、宗教仪式和民间艺术，如婚 姻（Sirvinacuy）和向大地的供奉（Pacha mama）。 \n" +
                    "中午12:00，岛上享用典型午餐。 \n" +
                    "午餐后：乘客将有两个小时的自由时间参观村庄的手工艺品中心并拍摄全景照片，然后返回普 诺市（返回到各自的酒店）。 \n" +
                    "普诺酒店过夜。 \n"},
            {title: "第3天 - 机场接送", description: "酒店早餐。\n 在指定时间前往机场或汽车站。"}
        ]
    },
    {
        title: "亚马逊雨林探险", d: "3天", n: "2夜", days: [
            {title: "第1天 - 伊基托斯 - 塞巴顶 ", description: "抵达机场或酒店后，前往Explorama码头。沿着亚马逊河40公里的船程抵达塞巴顶，那 里提供设备齐全的带空调和热水的私人浴室，为您在雨林中的住宿提供舒适的家一般\n" +
                    "的感觉。下午，您可以在被原始森林包围的环境中徒步旅行，探索高大的木棉树，这 也是塞巴顶旅馆的名字来源。晚餐后，进行夜间徒步活动，观察昆虫、青蛙、蜘蛛， 并聆听雨林的声音。 \n" +
                    "餐食：午餐，晚餐 \n" +
                    "住宿：雨林豪华小屋\n"},
            {title: "第2天 - 塞巴顶", description: "清晨乘船观鸟，欣赏亚马逊河的日出。返回塞巴顶享用早餐后，参观灰色河和粉红河 的交汇点。然后，前往雅瓜(Yagua)小社区，导游将为您介绍雅瓜文化。您将有机会观 看当地手工艺品展示和吹箭猎杀技术。下午，进行钓鱼活动，并欣赏亚马逊河上的日 落，如果天气允许的话。 \n" +
                    "餐食：早餐，午餐，晚餐 \n" +
                    "住宿：雨林豪华小屋\n"},
            {title: "第3天 - 塞巴顶 - 伊基托斯", description: "乘船前往河边小镇，导游将向您介绍秘鲁亚马逊河流域的文化，并参观当地学校。返 回伊基托斯，然后前往利马或您选择的酒店。 \n" +
                    "餐食：早餐，午餐（如果您的航班是下午） \n" +
                    "备注：行程可能会因天气条件而变更。 \n"}
        ]
    }
]

const footer = {
    contact_us: "联系我们",
    about_us: "关于我们",
    destinations: "目的地",
    email_us: "给我们发邮件",
}

const gastronomy = {
    title: "Gastronomy",
    awards: [
        { title: "“THE WORLD’S BEST FEMALE CHEF AWARD 2021”", subtitle: "Pía León", description: "The co-owner of the exclusive rest. Central and owner of Kjolle in Lima, and Mil in Cuzco received the best chef award in 2021, in the female category."}
    ],
	dishes: {
		dishes_costa: [
			{
				title: "Ceviche",
				description: "Dive into the freshness of ceviche, the star dish of the Peruvian coast. This delicacy is made with fresh fish, such as corvina or flounder, marinated in lime juice, which \"cooks\" the fish, giving it a firm texture and unmatched tangy flavor. Accompanied by red onion, spicy ají limo, and fresh cilantro, each bite is an explosion of flavor. Served with cooked sweet potato and corn, ceviche is a refreshing and vibrant culinary experience that you cannot miss during your visit to Peru."
			},
			{
				title: "Lomo Saltado",
				description: "Discover lomo saltado, an iconic dish of Peruvian cuisine that combines the best of Creole and Chinese traditions. This delicious dish features juicy pieces of beef tenderloin sautéed with red onion, tomato, and ají amarillo, all infused with soy sauce and vinegar for an irresistible flavor. Served with crispy French fries and white rice, each bite offers an explosion of textures and flavors that will delight your palate."
			},
			{
				title: "Causa Limeña",
				description: "Enjoy causa limeña, a gem of Peruvian gastronomy that combines tradition and flavor in every bite. This colorful dish is made with layers of yellow potato puree seasoned with ají amarillo and lime, filled with chicken or tuna, avocado, and mayonnaise. Causa limeña is a feast of textures and flavors, where the smoothness of the potato blends with the creamy filling and the fresh touch of avocado. Garnished with hard-boiled egg and olives, it is as delicious as it is visually appealing."
			},
		],
		dishes_sierra: [
			{
				title: "Rocoto Relleno",
				description: "Dare to try rocoto relleno, an explosion of flavor and spiciness typical of Arequipa. This dish features rocotos (Peruvian peppers) stuffed with a savory mix of ground meat, onion, peanuts, olives, and hard-boiled egg. Baked and topped with melted cheese, these small volcanoes of flavor are served with potato cake, offering a perfect contrast between spicy and smooth. Rocoto relleno is an intense culinary experience that will make you fall in love with Arequipean cuisine."
			},
			{
				title: "Pachamanca",
				description: "Immerse yourself in ancestral tradition with pachamanca, an authentic feast from the Peruvian highlands. This dish is cooked in an underground oven with hot stones, where marinated meats (beef, lamb, pork, and chicken), potatoes, sweet potatoes, broad beans, and corn are placed, all wrapped in banana leaves. The result is a meal rich in smoky and earthy flavors, with juicy meats and tender vegetables that absorb the natural aromas of the earth. Pachamanca is not just a dish; it is a cultural experience celebrating the connection to the land and ancient Andean traditions."
			},
			{
				title: "Cuy Chactado",
				description: "Cuy chactado is one of the most traditional and symbolic preparations of the Peruvian highlands. The guinea pig is flattened and fried in abundant oil until crispy on the outside and juicy on the inside. This dish is seasoned with garlic and cumin, typically served with boiled potatoes and salad. In many Andean communities, cuy is more than food; it is part of celebrations and rituals. Its unique flavor and crunchy texture make it a cherished dish for those seeking to experience authentic highland Peruvian cuisine."
			},
		],
		dishes_selva: [
			{
				title: "Tacacho con Cecina",
				description: "Delight in tacacho con cecina, a robust and flavorful dish typical of the Peruvian jungle. Tacacho is made from grilled and mashed green plantains, mixed with pork lard, and served alongside cecina, salted and sun-dried pork. The combination of the smooth tacacho and the intense cecina creates an unforgettable culinary experience. This dish is an authentic representation of the intense flavors and culinary heritage of the Amazon."
			},
			{
				title: "Inchicapi",
				description: "Immerse yourself in the unique flavors of inchicapi, a traditional Amazonian soup made with chicken, ground corn, peanuts, yucca, and cilantro. This thick and creamy dish combines the earthy flavor of peanuts with the freshness of cilantro. Inchicapi is a comforting meal that reflects the rich biodiversity and unique ingredients of the Peruvian jungle. Ideal for those seeking an authentic and nutritious culinary experience."
			},
			{
				title: "Juane",
				description: "Discover juane, an emblematic dish of the Peruvian jungle that will delight your senses. This delicacy is made with rice seasoned with turmeric, mixed with juicy pieces of chicken, olives, and hard-boiled egg, all wrapped in bijao leaves and steamed. Juane is an explosion of flavors and aromas that will transport you to the heart of the Amazon. The bijao leaves infuse the rice and chicken with a distinctive and aromatic touch, creating an unforgettable culinary experience. This dish is traditionally enjoyed during the San Juan festival but is so delicious that it is enjoyed all year round."
			},
		]
	},
	restaurants: {
		lima: {
			name: "利马",
			data: [
				{
					name: "La Rosa Náutica",
					photo: "restaurants/image20.png",
					description: "利马的地标，位于米拉弗洛雷斯的码头，提供秘鲁和国际美食，并享有壮丽的太平洋景观。"
				},
				{
					name: "Huaca Pucllana",
					photo: "restaurants/image26.png",
					description: "餐厅位于Huaca Pucllana考古遗址旁，提供现代秘鲁菜，享有前哥伦布时期考古遗址的美景。"
				},
				{
					name: "Panchita",
					photo: "restaurants/image2.png",
					description: "是Gastón Acurio集团的一部分，提供带有家庭风味的秘鲁克里奥尔菜肴，主打串烧、粽子和炸猪肉等特色菜。"
				},
				{
					name: "Maido",
					photo: "restaurants/image18.png",
					description: "由厨师Mitsuharu Tsumura领导的日秘料理餐厅，以其融合日本和秘鲁料理而闻名于世。"
				},
				{
					name: "Osaka",
					photo: "restaurants/image10.png",
					description: "专注于日秘料理，结合日本技术和秘鲁食材，创造出创新和精致的菜肴。"
				},
				{
					name: "Astrid & Gastón",
					photo: "restaurants/image27.png",
					description: "由Gastón Acurio创立，是秘鲁高级料理的象征，专注于本地食材和现代烹饪技术。"
				},
				{
					name: "Rafael Restaurante",
					photo: "restaurants/image6.png",
					description: "由Rafael Osterling主厨领导，提供秘鲁和国际融合料理，菜品摆盘优雅。"
				},
				{
					name: "La Mar",
					photo: "restaurants/image15.png",
					description: "Gastón Acurio的生鱼片餐厅，以其新鲜的生鱼片和其他海鲜菜肴而闻名，是秘鲁料理的代表。"
				},
				{
					name: "Isolina Taberna Peruana",
					photo: "restaurants/image12.png",
					description: "位于巴兰科的酒馆，提供传统的秘鲁克里奥尔大份菜肴，氛围家庭温馨。"
				},
				{
					name: "Mayta",
					photo: "restaurants/image1.png",
					description: "由Jaime Pesaque主厨经营，将现代秘鲁菜与当代技术和艺术呈现相融合。"
				},
				{
					name: "El Charrúa Restaurante Parrilla",
					photo: "restaurants/image14.png",
					description: "专营乌拉圭和阿根廷风格的烧烤肉类，提供优质的肉类切块，环境经典。"
				},
				{
					name: "Cosme",
					photo: "restaurants/image23.png",
					description: "一家现代餐厅，融合了秘鲁和国际风味，氛围时尚而放松。"
				},
				{
					name: "Amoramar",
					photo: "restaurants/image13.png",
					description: "位于巴兰科，提供现代秘鲁菜，注重新鲜和创意海鲜，环境休闲。"
				},
				{
					name: "Kjolle",
					photo: "restaurants/image21.png",
					description: "由Pía León主厨领导，专注于秘鲁生物多样性，使用本地食材创造独特而现代的菜肴。"
				},
				{
					name: "Fiesta Restaurant Gourmet Lima",
					photo: "restaurants/image29.png",
					description: "将奇克拉约的传统北部菜肴带到利马，以鸭肉饭和其他北方菜肴而闻名。"
				},
				{
					name: "Troppo",
					photo: "restaurants/image11.png",
					description: "休闲餐厅，提供秘鲁和意大利菜的融合料理，以意面、披萨和秘鲁菜肴闻名。"
				},
				{
					name: "Titi",
					photo: "restaurants/image22.png",
					description: "利马最具代表性的中餐馆之一，结合了粤菜风味和秘鲁食材，是融合美食的代表。"
				},
				{
					name: "Four Seas International",
					photo: "restaurants/image4.png",
					description: "专注于传统粤菜的中餐馆。"
				}
			]
		},
		cusco: {
			name: "库斯科",
			data: [
				{
					name: "Cicciolina",
					photo: "restaurants/image5.png",
					description: "以融合秘鲁和国际料理而闻名，位于库斯科的市中心。",
				},
				{
					name: "Chicha por Gastón Acurio",
					photo: "restaurants/image7.png",
					description: "由著名秘鲁厨师创立的餐厅，提供带有现代感的传统安第斯美食。"
				},
				{
					name: "MAP Café",
					photo: "restaurants/image28.png",
					description: "位于前哥伦布时期艺术博物馆内，这家餐厅提供优雅的秘鲁风味菜肴。"
				},
				{
					name: "Limo Cocina Peruana & Pisco Bar",
					photo: "restaurants/image19.png",
					description: "以其融合日本风味的秘鲁菜和出色的皮斯科酒吧而著称。"
				},
				{
					name: "Incanto",
					photo: "restaurants/image8.png",
					description: "结合了意大利和秘鲁料理，使用新鲜的本地食材。"
				},
				{
					name: "Uchu Peruvian Steakhouse",
					photo: "restaurants/image3.png",
					description: "专门提供秘鲁风格的牛排和烧烤。"
				},
				{
					name: "Kion Peruvian Chinese",
					photo: "restaurants/image25.png",
					description: "位于库斯科历史中心，融合了秘鲁和中国（中餐）料理。"
				}
			]
		},
		arequipa: {
			name: "阿雷基帕",
			data: [
				{
					name: "La Nueva Palomino",
					photo: "restaurants/image17.png",
					description: "以其传统的阿雷基帕美食，如辣椒填馅和Ocopa而闻名。"
				},
				{
					name: "KAO",
					photo: "restaurants/image30.png",
					description: "以其秘鲁-泰国融合菜肴而闻名，带来丰富的风味体验。"
				},
				{
					name: "Zig Zag",
					photo: "restaurants/image16.png",
					description: "专注于肉类和烧烤，提供羊驼肉、牛肉和羊肉等特色菜肴。"
				},
				{
					name: "Sol de Mayo",
					photo: "restaurants/image24.png",
					description: "阿雷基帕最传统的餐厅之一，以家庭氛围和典型的阿雷基帕菜肴而著称。"
				},
				{
					name: "La Trattoria del Monasterio",
					photo: "restaurants/image9.png",
					description: "位于圣卡塔利娜修道院内，这家餐厅提供意大利和秘鲁菜的混合料理。"
				},
				{
					name: "El Tío Darío",
					photo: "restaurants/image31.png",
					description: "以其海鲜菜肴和欣赏阿雷基帕火山景观的怡人环境而闻名。"
				}
			]
		}
	}
}

const menu = [
	{name: "-", url: "/?l=1"}, {name: "-", url: "/destinations"}, {name: "-", url: "/gastronomy"}
];

const world_destinations = {
	title: "---FALTA---",
	header: "- ",
	destinations: [
		{name: '-', value: 0},
		{name: '-', value: 1},
		{name: '-', value: 2},
	],
	asia: {
		name: "-",
		data: [
			{
				name: "-",
				flag: "china.png",
				data: [
					{
						name: "---FALTA---",
						duration: "",
						options: [
							{
								name: "---FALTA---",
								description: "",
								days: [
									{
										name: "01 星期四. 上海",
										description: "到达。欢迎来到中国！前往酒店并享受自由时间。您将在下午收到有关行程开始的信息。"
									},
									{
										name: "02 星期五. 上海",
										description: "参观上海。我们将参观浦东地区，并乘坐渡轮横渡黄浦江。参观豫园，参加茶艺表演，并漫步在新天地。包含午餐。我们还将参观热闹的田子坊和外滩。自由活动时间。"
									},
									{
										name: "03 星期六. 上海 - 洛阳.",
										description: "前往车站。乘火车前往洛阳，沿途欣赏美丽的风景。包含午餐。参观龙门石窟并乘船游览。随后漫步于洛阳古城。"
									},
									{
										name: "04 星期日. 洛阳 - 白马寺 - 三门峡 - 西安.",
										description: "参观白马寺。继续前往位于黄河旁的三门峡。参观宝轮寺和郭家博物馆。包含午餐。下午晚些时候抵达西安。"
									},
									{
										name: "05 星期一. 西安.",
										description: "出发参观秦始皇兵马俑博物馆，观赏成千上万的兵马俑。之后参观西安，包括大雁塔、中国书法课、回民街、清真寺和鼓楼。"
									},
									{
										name: "06 星期二. 西安.",
										description: "早餐后，我们的服务结束。"
									},
									{
										name: "06 星期二. 西安, 临汾, 王家大院, 平遥.",
										description: "乘火车前往临汾，参观王家大院。包含午餐。抵达并参观中国保存最完好的古城——平遥。"
									}
								]
							},
							{
								name: "（选项2）经典中国",
								description: "第1至第5天同选项1",
								days: [
									{
										name: "07 星期三. 平遥 - 五台山.",
										description: "我们前往中国最具灵性的地方之一：五台山，参观南山寺。青山绿水，森林环绕。自由活动时间。包含晚餐。"
									},
									{
										name: "08 星期四. 五台山 - 雁门关 - 应县 - 悬空寺 - 大同.",
										description: "这是一条风景优美的道路。抵达雁门关并参观长城的一部分。前往应县，参观大型木塔。包含午餐。参观悬空寺，这是一座建在悬崖上的令人惊叹的建筑。傍晚抵达大同。"
									},
									{
										name: "09 星期五. 大同 - 云冈 - 鸡鸣驿 - 北京.",
										description: "我们参观中国最令人印象深刻的石窟群——云冈石窟。之后前往鸡鸣驿，一个由强大城墙保护的传统小镇。继续前往北京。下午中途抵达。我们的服务结束。请检查您的航班时间，以防需要额外一晚。离境机场的接送服务不包括在内。"
									},
									{
										name: "10 星期六. 北京.",
										description: "全天参观北京，游览故宫、景山公园、煤山、天安门广场和颐和园。乘坐三轮车游览传统街区——胡同。包含午餐。"
									},
									{
										name: "11 星期日. 北京.",
										description: "前往令人印象深刻的长城（慕田峪段）进行游览。随后继续参观北京的798艺术区和天坛。体验足底按摩并参加太极课。包含烤鸭晚餐。"
									},
									{
										name: "12 星期一. 北京.",
										description: "早餐后，我们的服务结束。"
									}
								]
							}
						],
						services_included: "乘坐巴士旅行配有英语导游，基本旅行保险和自助早餐。\n" +
							"包括接机服务\n" +
							"船：龙门的游船。\n" +
							"城市游览：上海，西安。\n" +
							"晚间接送：洛阳古城。\n" +
							"门票包括：上海的豫园和茶艺表演，洛阳的白马寺，龙门的龙门石窟，三门峡的宝轮寺和郭家博物馆，兵马俑博物馆，小雁塔，中国书法课程，清真寺，西安鼓楼的传统乐器表演（不一定保证）。\n" +
							"渡轮：上海的黄浦江。\n" +
							"高铁：上海 - 洛阳。\n" +
							"3次午餐或晚餐包含在：上海，洛阳，三门峡。\n" +
							"（额外服务选项2）\n" +
							"短途旅行：北京胡同的三轮车游览，中国长城慕田峪段的游览。\n" +
							"城市游览：北京。\n" +
							"晚间接送：北京王府井大街。\n" +
							"门票包括：平遥的王家大院，寺庙、府邸和古城墙，五台山的南山寺，雁门关的长城，悬空寺，大同的云冈石窟，鸡鸣驿的中国传统小村落，北京的故宫，景山公园，颐和园，中国长城慕田峪段，天坛，足底按摩，北京的太极课。\n" +
							"缆车：北京中国长城。\n" +
							"高铁：西安 - 临汾。\n" +
							"5次午餐或晚餐包含在：王家大院，五台山，应县，北京，北京。"
					},
					{
						name: "---FALTA---",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 周五. 北京.",
								description: "欢迎来到中国。接机后前往酒店。 傍晚，我们将首次感受这座令人印象深刻的城市。我们安排了前往著名的王府井大街的接送服务。这条街是一个充满活力的步行商业区，拥有许多商店和餐馆。晚餐已包含。晚餐后返回酒店。"
							},
							{
								name: "02 - 周六. 北京.",
								description: "今天我们将参观：紫禁城，世界遗产之一，也被称为“故宫”。我们还将有机会参观景山公园，那里有煤山，这是北京保存最完好的皇家园林之一。如果天气晴朗，我们将登上煤山山顶，俯瞰故宫的最佳景观。继续前往天安门广场，这是世界上最大的广场之一，并从外部欣赏国家大剧院，北京的歌剧院。午餐已包含。下午，我们将参观颐和园，这是清朝皇室的夏季花园。下午，我们安排了三轮车（传统的三轮自行车）游览北京的胡同，这些是北京的传统街区。在这里，我们可以看到居民们独特的生活方式，参观传统商店，甚至可以参观一户传统的四合院，四合院中的各个房间围绕着一个院子。返回酒店，入住。"
							},
							{
								name: "03 - 周日. 北京 - 西安.",
								description: "前往著名的长城（慕田峪段）游览，这是一项宏伟的建筑工程，其历史可追溯至2000多年前。我们将乘坐缆车上下长城。返回市区。大约15:45，我们将乘坐中国超现代的高铁列车。在以300公里/小时的速度行驶下，约5小时内完成了两座城市之间的1100公里旅程。晚上到达西安，接送至酒店。"
							},
							{
								name: "04 - 周一. 西安.",
								description: "我们来到这个迷人的城市，它是丝绸之路的起点。清晨，我们将前往参观著名的兵马俑，这里有成千上万的真人大小的陶俑。返回西安。随后，参观小雁塔，我们还将在这里进行简短的中国书法课程。接着，我们将穿过古城墙内的穆斯林区，进入清真寺，观察各种文化和宗教在这个国家留下的印记。我们还将参观鼓楼，欣赏一场传统乐器的短暂表演（不一定能保证）。自由活动时间。"
							},
							{
								name: "05 - 周二. 西安 - 成都.",
								description: "乘坐现代化的高铁列车，从西安出发，约3小时内完成700公里的行程，抵达成都。抵达后，我们将前往大熊猫繁育研究基地。这座保护区中生活着120只大熊猫和76只小熊猫。午餐已包含。下午，我们将在人民公园散步，这里有小湖，当地居民在此练习太极拳，还有茶馆。"
							},
							{
								name: "06 - 周三. 成都.",
								description: "今天我们将安排一天的四川省全日游，欣赏优美的风景和传统村庄。在都江堰，我们将参观传统的灌溉系统，该系统已经运行了2000多年，是世界文化遗产。青城山，“绿色之城的山”，是一座道教圣山。我们将沿着森林小径，发现洞穴、亭台楼阁和百年木制寺庙。午餐已包含。最后，我们将参观具有千年历史的古镇街子古镇。返回成都。"
							},
							{
								name: "07 - 周四. 成都 - 苗族村寨 - 肇兴.",
								description: "我们将在早晨乘坐火车出发（计划于7:38出发），经过五个半小时的旅程，穿越壮丽的山间风光（火车上提供便当午餐），完成近1000公里的旅程。抵达从江，我们来到这个中国少数民族居住的神秘而未知的地区。我们将参观巴沙苗族村寨，位于森林中，拥有木质房屋。它是唯一被允许携带枪支的民族，因为他们曾是传统的猎熊人。随后前往肇兴，这是侗族的重要聚居地，拥有800多座传统建筑，被许多人誉为中国最美丽的村庄。入住酒店。"
							},
							{
								name: "08 - 周五. 肇兴 - 贯洞 - 程阳 - 龙胜 - 桂林.",
								description: "今天将是您旅程中最美丽、最令人兴奋的一段。贯洞，穿越侗族美丽的木质建筑村庄，这个充满魅力的村庄几乎未被国际游客所发现。我们将参观悬索桥、鼓楼和歌剧院。我们还将在程阳停留，参观壮丽的“风雨桥”。午餐已包含。在龙胜，我们将前往龙脊梯田，这是世界遗产。我们将乘坐小型巴士前往观景台，您可以沿着梯田小径漫步，欣赏美丽的风景。之后，我们将继续前往桂林——晚上抵达。"
							},
							{
								name: "09 - 周六. 桂林.",
								description: "全天游览桂林，桂林是中国最受欢迎的城市之一，环境优美，依傍着漓江湖畔，市中心商业街繁华热闹。我们将参观芦笛岩，这是一座壮丽的洞穴，内部有众多奇形怪状的钟乳石和石笋，彩色灯光照亮了这个地下的奇妙世界。下午自由活动，建议参观靖江王府，“袖珍故宫”。入住酒店。"
							},
							{
								name: "10 - 周日. 桂林 - 漓江游船 - 阳朔 - 黄姚 - 广州.",
								description: "我们将从桂林乘船出发，进行漓江游船，沿途的风景壮丽，绿油油的山峦，河岸上的村庄，奇石峭壁，形状奇特的山峰。午餐已包含。抵达阳朔，这是一座非常受欢迎且充满活力的旅游城市。我们在此短暂停留，随后继续前往黄姚，这是一座位于乡村的古城，保存完好，拥有狭窄的街道、古老的建筑和寺庙。其居民的生活方式将带我们穿越时光。我们将有时间在这里漫步。之后继续前往广州——晚上抵达。"
							},
							{
								name: "11 - 周一. 广州 - 珠海 - 澳门.",
								description: "广州，我们安排了为期两小时的游览，参观这座位于中国南方的繁荣大都市。我们将在上下九步行街漫步，参观传统市场，了解晚清时期的陈家祠，漫步珠江沿岸。自由活动时间并且包含午餐。下午，我们前往珠海，停留在中国海旁的壮丽歌剧院，它建在填海而成的土地上，于2016年竣工，其壮丽的外形象征着太阳和月亮。过境并进入澳门，我们将在这里参观赌场区，其中许多赌场的风格与拉斯维加斯相似，其活力令人印象深刻。 重要提示：如果您在澳门和香港停留后再次进入中国大陆，您需要双次入境签证。"
							},
							{
								name: "12 - 周二. 澳门 - 香港.",
								description: "澳门。我们安排了城市游览，澳门的历史中心已被列为世界文化遗产。从16世纪至1999年，澳门由葡萄牙人管理，这里的古老教堂、民用建筑上的瓷砖、城堡以及中国最古老的灯塔都证明了这一点。自由活动并且包含午餐。下午我们将乘坐2018年底启用的澳门至香港大桥，这是一项工程奇迹，桥梁横跨中国海30多公里，海底隧道长达6公里，设有人工岛。 注意：如果团体人数少于6人，澳门到香港的旅程可能改为乘船。 香港——抵达。入住酒店后，我们将乘坐天星小轮，横渡维多利亚港。我们将短暂漫步，欣赏对岸的灯光建筑。返回酒店区。晚餐已包含。入住酒店。"
							},
							{
								name: "13 - 周三. 香港.",
								description: "早餐后，我们将游览这座城市。在参观中，我们将了解金融中心，参观摩天大楼，参观历史博物馆，了解这座城市的历史（殖民时期、世界大战...），并参观星光大道，展示电影业的名人。我们将乘坐天星小轮，横渡维多利亚港，然后前往太平山顶（552米），从这里可以俯瞰整个城市。乘坐山顶缆车下山。参观后，下午自由活动并且入住酒店。"
							},
							{
								name: "14 - 周四. 香港.",
								description: "早餐后，我们"
							}
						],
						services_included: "西班牙语导游陪同的大巴游览，基本旅行保险，酒店和自助早餐。\n" +
							"包含接机服务\n" +
							"全景游览： 北京、西安、广州、澳门、香港\n" +
							"游览项目： 三轮车游览传统街区，长城（慕田峪段），都江堰，青城山和街子古镇，芦笛岩\n" +
							"船： 青城山湖上船游，漓江游船\n" +
							"夜间接送： 王府井大街，香港\n" +
							"门票： 故宫，景山公园，颐和园，长城（慕田峪段），兵马俑博物馆，小雁塔，中国书法演示，清真寺及鼓楼传统鼓乐表演（不一定能保证），都江堰灌溉系统，青城山圣山，巴沙苗寨，参观肇兴侗族村，参观贯洞侗族村，风雨桥，龙脊梯田，芦笛岩，古镇，陈家祠堂，历史博物馆\n" +
							"渡轮： 香港维多利亚港天星小轮\n" +
							"缆车： 长城缆车，青城山缆车，太平山顶缆车（下山）\n" +
							"高铁： 北京 - 西安，成都 - 从江\n" +
							"包含8次午餐： 北京，成都，成都，苗寨，程阳，漓江游船，广州，澳门\n" +
							"包含2次晚餐： 北京，香港"
					},
					{
						name: "---FALTA---",
						duration: "---FALTA---",
						days: [
							{
								name: "01 星期四. 上海.-",
								description: "抵达。欢迎来到中国！接机后前往酒店并享受自由时间。您将在下午收到关于行程开始的信息。"
							},
							{
								name: "02 星期五. 上海.-",
								description: "参观上海。我们将参观浦东地区，并乘坐渡轮横渡黄浦江。参观豫园、茶艺表演并漫步在新天地。包含午餐。我们将参观热闹的田子坊和外滩。自由活动时间。"
							},
							{
								name: "03 星期六. 上海 - 洛阳.-",
								description: "前往车站。乘火车前往洛阳，沿途欣赏美丽的风景。包含午餐。参观龙门石窟并乘船游览。随后漫步于洛阳古城。"
							},
							{
								name: "04 星期日. 洛阳 - 白马寺 - 三门峡 - 西安.-",
								description: "参观白马寺。继续前往三门峡，靠近黄河。参观宝轮寺和郭家博物馆。包含午餐。下午晚些时候抵达西安。"
							},
							{
								name: "05 星期一. 西安.-",
								description: "出发参观兵马俑博物馆，观赏成千上万的真人大小的兵马俑。随后参观西安，包括大雁塔、中国书法课程、穆斯林区、清真寺和鼓楼。"
							},
							{
								name: "06 星期二. 西安 - 成都.-",
								description: "乘坐高铁前往成都。参观熊猫繁育研究基地。包含午餐。在人民公园散步。"
							},
							{
								name: "07 星期三. 成都 - 都江堰 - 青城山 - 街子古镇 - 成都.-",
								description: "四川省全日游，欣赏美丽的风景和传统村庄。参观都江堰和青城山。包含午餐。参观街子古镇。"
							},
							{
								name: "08 星期四. 成都 - 苗族村寨 - 肇兴.-",
								description: "乘火车前往从江（火车上享用便当午餐），一座美丽的少数民族居住的城市。参观巴沙苗寨的木屋，以及侗族最重要的城镇肇兴。"
							},
							{
								name: "09 星期五. 肇兴 - 贯洞 - 程阳 - 龙胜 - 桂林.-",
								description: "这是旅程中最美丽和令人兴奋的阶段之一。我们将探索贯洞和程阳村庄，欣赏其美丽的木质建筑。包含午餐。在龙胜，我们将参观龙脊梯田。下午晚些时候抵达桂林。"
							},
							{
								name: "10 星期六. 桂林.-",
								description: "桂林一整天。参观芦笛岩，下午自由活动。住宿。"
							},
							{
								name: "11 星期日. 桂林 - 漓江游船 - 阳朔 - 黄姚 - 广州.-",
								description: "漓江游船。景色壮观，绿意盎然的山峦。包含午餐。抵达阳朔并漫步。前往黄姚，享受乡村环境并漫步。抵达广州。"
							},
							{
								name: "12 星期一. 广州.-",
								description: "我们在这座繁荣的南方城市进行约两个小时的游览，参观陈家祠堂，并游览河滨区。自由活动时间并包含午餐。我们的服务结束，请检查您的航班时间是否需要额外的住宿。"
							},
							{
								name: "13 星期二. 广州 - 珠海 - 澳门.-",
								description: "参观广州：市场、陈家祠堂和河滨漫步。自由活动时间并包含午餐。参观珠海，靠近中国南海。过境进入澳门，漫步赌场区。"
							},
							{
								name: "14 星期三. 澳门 - 香港.-",
								description: "包含澳门游览。包含午餐。我们将跨越连接香港与澳门的壮观大桥，横跨中国南海。抵达香港。抵达后，我们将乘坐天星小轮横渡维多利亚港。包含晚餐。"
							},
							{
								name: "15 星期四. 香港.-",
								description: "参观金融中心、历史博物馆、星光大道并乘坐渡轮横渡海湾。登上太平山顶，并乘缆车下山。下午自由活动。"
							},
							{
								name: "16 星期五. 香港.-",
								description: "早餐后，我们的服务结束。"
							}
						],
						services_included: "乘坐巴士旅行配有英语导游、基本旅行保险和自助早餐。\n" +
							"包含接机服务\n" +
							"游览： 成都的都江堰、青城山和街子古镇。\n" +
							"船： 龙门游船，青城山游船（成都）。\n" +
							"城市游览： 上海、西安。\n" +
							"夜间接送： 洛阳古城。\n" +
							"门票包括： 上海豫园和茶艺表演，洛阳白马寺，龙门石窟，三门峡宝轮寺，郭家博物馆，兵马俑博物馆，小雁塔，中国书法课程，清真寺，西安鼓楼的传统乐器表演（不一定能保证），都江堰的传统灌溉系统，成都青城山的圣山。\n" +
							"渡轮： 上海黄浦江。\n" +
							"缆车： 青城山缆车（成都）。\n" +
							"高铁： 上海 - 洛阳。\n" +
							"包含5次午餐或晚餐： 上海，洛阳，三门峡，成都，成都。\n" +
							"（附加服务）\n" +
							"游览： 芦笛岩。\n" +
							"船： 漓江游船。\n" +
							"城市游览： 广州。\n" +
							"门票包括： 巴沙苗寨，肇兴侗寨，广孝侗寨，程阳风雨桥，龙胜梯田，芦笛岩，黄姚古镇，广州陈家祠堂。\n" +
							"高铁： 成都 - 从江。\n" +
							"包含4次午餐或晚餐： 苗寨，程阳，漓江游船，广州。\n" +
							"城市游览： 广州、澳门、香港。\n" +
							"夜间接送： 香港湾（香港）。\n" +
							"门票包括： 广州陈家祠堂，香港历史博物馆。\n" +
							"渡轮： 香港湾天星小轮。\n" +
							"缆车： 香港山顶缆车（单程）。\n" +
							"包含3次午餐或晚餐： 广州，澳门，香港。"
					},
					{
						name: "---FALTA---",
						duration: "---FALTA---",
						options: [
							{
								name: "---FALTA---",
								description: "---FALTA---",
								days: [
									{
										name: "01 星期日. 北京.",
										description: "欢迎来到中国。接机后前往北京的酒店。下午前往著名的王府井大街。包含晚餐。"
									},
									{
										name: "02 星期一. 北京.",
										description: "前往令人印象深刻的长城（慕田峪段）游览。随后，我们继续参观北京的798艺术区和天坛。体验足部按摩和太极课。包含烤鸭晚餐。"
									},
									{
										name: "03 星期二. 北京.",
										description: "全天游览北京，我们将参观故宫、景山公园、煤山、天安门广场和颐和园。乘坐三轮车游览传统街区，称为胡同。包含午餐。"
									},
									{
										name: "04 星期三. 北京 - 南京.",
										description:"前往车站乘火车前往南京。参观中山陵和灵谷寺。自由活动和住宿。"
									},
									{
										name: "05 星期四. 南京 - 苏州 - 同里 - 上海.",
										description: "抵达美丽优雅的苏州，参观拙政园。包含午餐。之后，我们继续前往迷人的同里镇，并乘船游览。下午晚些时候抵达上海。"
									},
									{
										name: "06 星期五. 上海.",
										description: "参观上海。我们将参观浦东地区，并乘坐渡轮横渡黄浦江。参观豫园、茶艺表演和漫步在新天地。包含午餐。我们将参观热闹的田子坊和外滩。自由活动时间。"
									},
									{
										name: "07 星期六. 上海 - 东京.",
										description: "早餐后，送往机场搭乘飞往东京的航班。下午晚些时候，乘坐地铁前往新宿区。包含晚餐。"
									},
									{
										name: "08 星期日. 东京.",
										description: "五小时的城市游览。包含午餐。下午自由活动。"
									},
									{
										name: "09 星期一. 东京.",
										description: "早餐后，我们的服务结束。"
									}
								]
							},
							{
								name: "（选项 2）北京，上海和日本精华游",
								description: "前8天与选项1相同。",
								days: [
									{
										name: "09 星期一. 东京 - 富士山 - 河口湖.",
										description: "参观新仓山浅间公园，这里可以看到富士山的最佳景观。抵达河口湖，这是一座美丽的湖畔小镇，并乘船游览。乘坐巴士登上富士山。享用传统的日本晚餐。入住日式旅馆（Ryokan）。"
									},
									{
										name: "10 星期二. 河口湖 - いやしの里根場 - 丰田 - 京都.",
										description: "参观美丽的传统村庄いやしの里根場。随后，我们将欣赏白丝瀑布。在名古屋地区，我们将参观丰田博物馆。下午晚些时候抵达京都。"
									},
									{
										name: "11 星期三. 京都.",
										description: "京都城市游览。我们将参观岚山竹林。夜间前往祇园区，这里以艺伎闻名。"
									},
									{
										name: "12 星期四. 京都 - 奈良 - 大阪.",
										description: "在奈良，我们参观东大寺及其巨大的佛像。包含午餐。之后，我们将参观法隆寺。继续前往大阪，我们将漫步在灯火辉煌的道顿堀。"
									},
									{
										name: "13 星期五. 大阪.",
										description: "早餐后，我们的服务结束。"
									}
								]
							},
							{
								name: "（选项 3）中国和日本传奇游",
								description: "前12天与选项2相同。",
								days: [
									{
										name: "13 星期五. 大阪 - 姬路 - 后乐园 - 冈山.",
										description: "参观宏伟的姬路城。包含午餐。在冈山，我们将参观日本最美丽的花园之一后乐园，以及吉备津神社。"
									},
									{
										name: "14 星期六. 冈山 - 仓敷 - 广岛 - 严岛 - 广岛.",
										description: "参观仓敷，这里有水道和运河。抵达广岛后，我们将参观和平纪念公园。乘渡轮前往宫岛，参观部分建在海上的神社。返回酒店。包含晚餐。"
									},
									{
										name: "15 星期日. 广岛.",
										description: "早餐后，我们的服务结束。"
									}
								]
							}
						],
						services_included: "乘坐巴士旅行，配有英语导游，包含基本旅行保险和自助早餐。\n" +
							"包括抵达接送服务。\n" +
							"游览：北京的长城，慕田峪段；北京传统街区的三轮车游览。\n" +
							"船游：同里的船游。\n" +
							"城市游览：北京，上海，东京。\n" +
							"夜间转乘：北京的王府井大街，东京的新宿。\n" +
							"门票：北京的长城，慕田峪段；天坛；足底按摩；北京的太极课；故宫；景山公园；颐和园；南京的中山陵和灵谷寺；苏州的拙政园；上海的豫园及茶道演示；东京的地铁；增上寺，明治神宫，浅草寺。\n" +
							"渡船：上海的黄浦江。\n" +
							"缆车：北京的长城。\n" +
							"高铁：北京 - 南京。\n" +
							"包含的航班：上海 - 东京。\n" +
							"7顿午餐或晚餐：北京，北京，北京，苏州，上海，东京，东京。\n",
						services_additional: "船游：河口湖。\n" +
							"城市游览：京都。\n" +
							"夜间转乘：京都的祇园传统区，大阪的道顿堀。\n" +
							"门票：富士山的新仓山浅间公园；西湖根场的怀旧民俗村；丰田博物馆；天龙寺；京都的岚山竹林；伏见稻荷大社；京都御所；金阁寺；奈良的东大寺和法隆寺。\n" +
							"2顿午餐或晚餐：河口湖，奈良。\n" +
							"门票：姬路城；冈山的后乐园；严岛神社；广岛和平纪念馆。\n" +
							"渡船：广岛 - 宫岛。\n" +
							"2顿午餐或晚餐：姬路，广岛。\n"
					},
					{
						name: "---FALTA---",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 星期四. 上海",
								description: "欢迎来到中国。接机后送往酒店。欢迎来到上海，这座拥有超过1500万人口的国际大都市，是中国最大的商业港口，也是最具国际化的城市。"
							},
							{
								name: "02 - 星期五. 上海",
								description: "在我们包含的行程中，我们将参观黄浦江对岸的浦东地区。今天，它是金融区，其最著名的地标是东方明珠塔。我们将乘坐上海渡轮，欣赏城市两岸的壮丽景色。我们将穿越时光，漫步于老城区的街道，参观建于明朝时期的私人花园——豫园。我们将享受茶道演示，茶作为一种饮品，通过其传统、香气和味道，在中国文化中扮演着极其重要的角色。我们还将路过首都最时尚的街区之一，新天地。包含午餐。我们将游览田子坊，这是一片位于法租界区的老住宅区，以其狭窄且错综复杂的传统小巷、小工艺品店和艺术画廊而闻名。我们将在外滩结束游览，这里以其19世纪的欧洲风格建筑闻名。 在您的空闲时间，我们推荐您夜晚乘船游览，欣赏灯火辉煌的上海，这将是一段难忘的体验。"
							},
							{
								name: "03 - 星期六. 上海 - 洛阳",
								description: "前往车站，乘坐高铁（计划出发时间为7:17），用五个多小时的时间，穿越1000公里到达洛阳。包含午餐。我们将参观令人印象深刻的龙门石窟，在这里，沿着河岸的石壁上雕刻了超过10万尊佛像。我们还将包含一次乘船游览。之后，前往古城，通过其宏伟的城墙，享受这里丰富的传统活动和灯火辉煌的街道。"
							},
							{
								name: "04 - 星期日. 洛阳 - 白马寺 - 三门峡 - 西安",
								description:"E清晨，在洛阳郊外，我们将参观白马寺，这是一座庞大的寺院和学术中心，吸引了来自世界各地的佛教徒。接着，我们将前往三门峡，在这里我们将看到黄河，参观宝轮寺及其高耸的塔楼，并参观中国最好的博物馆之一：郭博物馆，里面展出了古郭国的葬车。包含午餐。我们继续向东行驶，经过华山脚下，这是一座神圣的山。下午抵达西安。"
							},
							{
								name: "05 - 星期一. 西安",
								description: "我们在这座迷人的城市里，这里曾是丝绸之路的起点。清晨，我们将参观兵马俑，这里埋藏着成千上万的真人大小的陶俑士兵。之后返回西安。我们将参观小雁塔，在这里我们将有机会参加一次简短的中国书法课程。随后，我们将在城墙内的市中心漫步，穿越穆斯林街区，并进入清真寺，观察在这个国家留下印记的多种文化和宗教。我们还将包含鼓楼的入场券，在那里我们将欣赏一场短暂的传统乐器表演（并非每次都能保证）。自由时间。"
							},
							{
								name: "06 - 星期二. 西安 - 临汾 - 王家大院 - 平遥",
								description: "我们乘坐现代化的高铁离开西安，三小时内到达临汾，从那里我们乘坐大巴前往王家大院，这座大院由一个富裕家族在17至19世纪之间建造。包含午餐。随后我们前往平遥，这是中国保存最完好的历史名城，拥有4000座古建筑，被联合国教科文组织列为世界遗产。我们将享受这座迷人的传统城市。我们将提供一张通票，可以进入所有的寺庙、大院和城墙。"
							},
							{
								name: "07 - 星期三. 平遥 - 五台山",
								description: "我们前往中国最令人激动的精神圣地之一：五台山。在绿树成荫的山脉和森林中，坐落着藏传佛教的中心之一，这里有超过40座古老的寺庙（以及近年来建造的相似数量的寺庙），住着大量的僧侣。我们将与导游一起参观南山寺。随后，您将有自由时间，可以寻找较少游客的寺庙，在尊重的前提下观察僧侣们的生活。包含晚餐并住宿。"
							},
							{
								name: "08 - 星期四. 五台山 - 雁门关 - 应县 - 悬空寺 - 大同",
								description: "沿着美丽的山间公路，我们继续前往雁门关。在不远处的蒙古边界附近的一个山口，我们在这里参观长城的一部分，在这个游客稀少的区域内，我们可以悠闲地散步。随后，我们继续前往应县，这里以其巨大的木塔而闻名，是一个宁静而美丽的村庄。包含午餐。然后，我们前往悬空寺，这是一座建在悬崖上的令人难以置信的建筑。傍晚抵达大同。您可以在这座拥有宏伟城墙的城市中漫步，这里已经重建了整个历史中心。"
							},
							{
								name: "09 - 星期五. 大同 - 云冈石窟 - 鸡鸣驿 - 北京",
								description: "在大同郊外，我们将参观云冈石窟，这是中国最令人印象深刻的石窟艺术圣地，有超过51,000尊雕像分布在252个石窟内，雕刻于5世纪。然后，我们继续前往鸡鸣驿，这是一座传统的中国小镇，保留了其宁静的省会生活。它最初作为皇帝信使的休息点而建，并由坚固的城墙保护。下午抵达北京。"
							},
							{
								name: "10 - 星期六. 北京",
								description: "在这一天里，我们将参观：故宫，世界遗产之一，也被称为“紫禁城”。我们还将有机会参观景山公园，在那里有保存完好的煤山，这是北京保存最完好的皇家花园之一。我们将登上山顶，如果天气晴朗，可以欣赏到紫禁城的全景，这一努力将是值得的。随后我们将前往天安门广场，这是世界上最大的广场之一，并从外部欣赏国家大剧院，北京的歌剧院。包含午餐。下午，我们将参观颐和园，这是清朝皇室的避暑花园。我们还包含一场三轮车（传统的三轮自行车）之旅，穿越北京的胡同（传统街区）。在这个区域，我们可以看到居民的典型生活方式，参观特色商店，甚至参观一个传统的四合院，一个四周围绕着庭院的传统住宅。返回酒店并住宿。"
							},
							{
								name: "11 - 星期日. 北京",
								description: "游览长城（慕田峪段），这是一项壮观而宏伟的建筑工程，其历史跨越2000多年。我们将包含乘坐缆车上下长城。返回北京途中，我们将在798艺术区停留，这里是一个位于前军事工厂的当代艺术区。接着，我们将参观天坛，这是中国最大的庙宇群，也是北京最原始和令人印象深刻的建筑之一。我们将了解中国传统医学，几百年来一直支持健康与脚部健康息息相关的理论。我们将享受一次足部按摩，放松身心。我们还将上一堂太极课。随后返回酒店并住宿。晚餐包含北京烤鸭。"
							},
							{
								name: "12 - 星期一. 北京 - 东京",
								description: "早餐后，送机前往机场，搭乘航班前往东京（航班包含）。 东京 - 欢迎来到日本！在完成边境和海关手续后，您的接待员（通常讲西班牙语，有时讲英语）将在机场等候您。乘坐集体交通工具（班车）送往酒店。 在18:30，我们将在酒店大堂与导游会面。我们将与团队成员见面，然后进行一次夜间漫步。在傍晚时分，我们将乘坐地铁（票包含），进入这个迷人的国家，前往新宿区。您将看到使用现代而高效的公共交通的巨大人流。接着，我们将步行探索这个区域，这里拥有热闹的夜生活。在当地餐厅享用欢迎晚餐（包含）。晚餐后，导游将陪同我们再次乘坐地铁返回酒店。 注意：有时根据旅行者的航班到达时间，地铁转乘和晚餐可能会安排在第二天下午或晚上。"
							},
							{
								name: "13 - 星期二. 东京",
								description: "上午，我们将进行为期五小时的导游带领的东京城市游览。这个城市的繁忙活动和超现代的街区与充满和平的区域共存。我们将短暂停留在增上寺，拍摄东京塔最美的照片。这里的亮点是数以百计的地藏菩萨石像，这些石像是孩子们的守护神，上面戴着毛线帽、围裙和风车。然后，我们将前往著名的涩谷十字路口，被誉为世界上最繁忙的十字路口。接着，我们将参观明治神宫，供奉明治天皇及其妻子的灵魂。我们将沿着优雅的表参道街继续前行，绕过皇宫及其花园最美的部分。我们将在著名的二重桥停留。我们还将穿过秋叶原区，这里被称为“电器城”，是日本动漫和漫画的中心。我们将经过相扑馆，日本的国技相扑比赛的场馆。最后，我们将在浅草结束我们的游览，参观浅草寺和著名的仲见世街。午餐将在当地餐馆享用（包含）。下午返回酒店，自由活动。"
							},
							{
								name: "14 - 星期三. 东京",
								description: "早餐后，服务结束。"
							}
						]
					}
				]
			},
			{
				name: "-",
				flag: "japan.png",
				data: [
					{
						name: "經典日本",
						duration: "08 天",
						options: [
							{
								name: "選項1：東京、京都和阿爾卑斯山 (全年週一)",
								days: [
									{
										name: "1天（週一)",
										description: "抵達東京，接送到飯店，晚上前往新宿（含晚餐）。"
									},
									{
										name: "2天（週二）",
										description: "東京市區遊覽（含午餐），下午自由活動。"
									},
									{
										name: "3天（週三）",
										description: "搭乘新幹線前往京都，京都會區遊覽，晚上前往祇園區。"
									},
									{
										name: "4天（週四)",
										description: "參觀妻籠、松本城堡，晚上抵達長野（含午餐）。"
									},
									{
										name: "5天（週五）",
										description: "參觀長野寺廟、地獄谷猴子公園、草津，然後入住伊香保溫泉（含傳統晚餐）。"
									},
									{
										name: "6天（週六）",
										description: "Visit Nikko temples, return to Tokyo. End of services."
									}
								]
							},
							{
								name: "O選項2：東京、京都、阿爾卑斯山和富士山",
								description: "第1-5天： 與選項1相同。",
								days: [
									{
										name: "6天（週六）",
										description: "參觀日光寺廟，返回東京。"
									},
									{
										name: "7天（主日）",
										description: "參觀鎌倉、江之島和小田原。"
									},
									{
										name: "8天（週一）",
										description: "參觀大涌谷、忍野八海、河口湖、富士山，返回東京。行程結束。 End of services."
									}
								]
							}
						],
						services_included: "接機服務。\n" +
							"市區遊覽。\n" +
							"高速列車票及多個寺廟和公園的門票。\n" +
							"選項1包含5次餐食。\n" +
							"選項2中有額外的船和門票服務。\n"
					}
				]
			},
			{
				name: "-",
				flag: "korea.png",
				data: [
					{
						name: "韩国快递",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 星期一. 首尔.",
								description: "欢迎来到韩国！接机后送往酒店，与导游会面。 傍晚时分，我们将开始探索这个迷人的国家。在导游的带领下，我们将乘坐现代高效的地铁（导游会教我们如何使用这种公共交通工具）前往市政厅，这是一座外形如海啸般的建筑。随后，我们将参观夜晚灯火辉煌的德寿宫，在这里我们可以一同感受韩国的传统与现代。之后，我们将步行前往明洞区，这里是韩国最大的购物和娱乐区，其名字意为“光明隧道”。包含晚餐（当地餐厅）。住宿。"
							},
							{
								name: "02 - 星期二. 首尔.",
								description: "今天我们将进行为期五小时的首尔深度游览。我们将参观昌德宫，这是一座联合国教科文组织列为世界遗产的宫殿群，位于一个大型公园内。接着，我们将漫步于北村韩屋村，这里是一个保留着传统小房屋的历史街区。随后，我们将参观战争纪念馆，这是一座令人印象深刻的战争博物馆，它向我们展示了韩国历经的各种冲突，特别是联合国参与的朝鲜战争。包含午餐（当地餐厅）。我们将继续前往江南区，这是首尔最繁忙的地区之一，拥有众多购物中心和娱乐场所。最后，我们将停在乐天世界塔，这座高达550米的建筑让您有自由时间选择登塔（门票不包含）或在其公园或豪华购物中心中漫步。返回酒店，自由活动。"
							},
							{
								name: "03 - 星期三. 首尔 - 水原 - 全州.",
								description: "今天我们将参观景福宫，这是一座拥有600年历史的宏伟宫殿，如果有机会，我们还将观看守卫交接仪式。随后，我们将离开首尔，向韩国南部进发。我们到达水原，这座古代的韩国首都，以其壮观的城墙而闻名，这些城墙已被列为世界遗产。我们将参观这座壮观的城墙及其防御工事，并参观华城行宫，这是一座夏季宫殿。包含午餐。接着，我们继续前往全州，在那里我们将参观其迷人的历史中心（韩屋村），这里拥有传统的小房子、商店和艺术画廊。"
							},
							{
								name: "04 - 星期四. 全州 - 海印寺 - 大邱 - 釜山.",
								description: "在被森林覆盖的壮丽山景中，我们到达了海印寺，这是一座建于802年的佛教寺庙，存放着八万大藏经，这是一套完整的佛教经典，雕刻在80,000块木板上。我们将参观寺庙和大藏经博物馆。接着，我们前往大邱，参观传统医学市场，这里以各种草药，尤其是人参闻名，并参观东方医学博物馆。包含午餐。之后，我们继续前往釜山，韩国的第二大城市，充满活力。抵达后，我们将在都山海云台综合体停留，这里有300多米高的摩天大楼和一个游艇码头。住宿。"
							},
							{
								name: "05 - 星期五. 釜山 - 海东龙宫寺 - 首尔.",
								description: "早上，我们将从釜山出发，参观位于海边的14世纪佛教寺庙——海东龙宫寺。然后返回釜山，参观联合国纪念公园，这里安葬着来自多个国家的人士。随后，我们将前往韩国最大的鱼市，位于南浦港附近。包含午餐。接着，我们将步行前往龙头山公园，参观其120米高的巨大塔楼。大约在下午5:30，我们将乘坐现代化的高铁，在大约三小时的旅程后返回首尔。抵达后，送往酒店，住宿。"
							},
							{
								name: "06 - 星期六. 首尔.",
								description: "在首尔自由活动一天，您可以自行探索这座城市或参加自选活动。住宿在首尔。"
							}
						],
						services_included: "包含中文导游的巴士游览，基本旅行保险，酒店住宿和自助早餐。\n" +
							"包括接机服务。\n" +
							"首尔全景游览。\n" +
							"夜间接送：首尔明洞区。\n" +
							"包含门票：地铁，昌德宫，首尔战争纪念馆，景福宫，韩屋村，佛教寺庙，海东龙宫寺，联合国纪念馆和龙头山公园。\n" +
							"高铁：釜山-首尔。\n" +
							"包含4顿午餐：首尔，水原，大邱，釜山。\n" +
							"包含1顿晚餐：首尔。\n"
					},
					{
						name: "---FALTA---",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 星期一. 首尔.",
								description: "欢迎来到韩国！接机后送往酒店，与导游会面。 傍晚时分，我们将开始探索这个迷人的国家。在导游的带领下，我们将乘坐现代高效的地铁（导游会教我们如何使用这种公共交通工具）前往市政厅，这是一座外形如海啸般的建筑。随后，我们将参观夜晚灯火辉煌的德寿宫，在这里我们可以一同感受韩国的传统与现代。之后，我们将步行前往明洞区，这里是韩国最大的购物和娱乐区，其名字意为“光明隧道”。包含晚餐（当地餐厅）。住宿。"
							},
							{
								name: "02 - 星期二. 首尔.",
								description: "今天我们将进行为期五小时的首尔深度游览。我们将参观昌德宫，这是一座联合国教科文组织列为世界遗产的宫殿群，位于一个大型公园内。接着，我们将漫步于北村韩屋村，这里是一个保留着传统小房屋的历史街区。随后，我们将参观战争纪念馆，这是一座令人印象深刻的战争博物馆，它向我们展示了韩国历经的各种冲突，特别是联合国参与的朝鲜战争。包含午餐（当地餐厅）。我们将继续前往江南区，这是首尔最繁忙的地区之一，拥有众多购物中心和娱乐场所。最后，我们将停在乐天世界塔，这座高达550米的建筑让您有自由时间选择登塔（门票不包含）或在其公园或豪华购物中心中漫步。返回酒店，自由活动。"
							},
							{
								name: "03 - 星期三. 首尔 - 水原 - 全州.",
								description: "今天我们将参观景福宫，这是一座拥有600年历史的宏伟宫殿，如果有机会，我们还将观看守卫交接仪式。随后，我们将离开首尔，向韩国南部进发。我们到达水原，这座古代的韩国首都，以其壮观的城墙而闻名，这些城墙已被列为世界遗产。我们将参观这座壮观的城墙及其防御工事，并参观华城行宫，这是一座夏季宫殿。包含午餐。接着，我们继续前往全州，在那里我们将参观其迷人的历史中心（韩屋村），这里拥有传统的小房子、商店和艺术画廊。"
							},
							{
								name: "04 - 星期四. 全州 - 海印寺 - 大邱 - 釜山.",
								description: "在被森林覆盖的壮丽山景中，我们到达了海印寺，这是一座建于802年的佛教寺庙，存放着八万大藏经，这是一套完整的佛教经典，雕刻在80,000块木板上。我们将参观寺庙和大藏经博物馆。接着，我们前往大邱，参观传统医学市场，这里以各种草药，尤其是人参闻名，并参观东方医学博物馆。包含午餐。之后，我们继续前往釜山，韩国的第二大城市，充满活力。抵达后，我们将在都山海云台综合体停留，这里有300多米高的摩天大楼和一个游艇码头。住宿。"
							},
							{
								name: "05 - 周五. 釜山 - 海东龙宫寺 - 釜山 - 釜山至下关渡轮.",
								description: "早上，我们将从釜山出发，参观位于海边的14世纪佛教寺庙——海东龙宫寺。然后返回釜山，参观联合国纪念公园，这里安葬着来自多个国家的人士。随后，我们将前往韩国最大的鱼市，位于南浦港附近。包含午餐。接着，我们将步行前往龙头山公园，参观其120米高的巨大塔楼。然后前往港口办理登船手续（注意：渡轮登船的最后办理时间为17:30）。预计釜山出发时间为晚上21点，穿越日本海。我们将在渡轮上过夜，双人舱内设有私人浴室。 注意：由于渡轮双人舱的容量有限，有时我们会乘坐快速渡轮，下午15:00从釜山出发，大约18:30到达福冈（日本），（边境检查后，在福冈自由活动并入住酒店）。"
							},
							{
								name: "06 - 周六. 釜山至下关渡轮 - 下关 - 岩国 - 严岛 - 广岛.",
								description: "早上8点抵达下关港口（早餐不包含），办理入境手续。我们将前往岩国，这是一座小城市，我们将在那里参观美丽而历史悠久的锦带桥。之后，我们继续前往宫岛，乘坐渡轮前往宫岛，这座被称为“人与神共存的岛屿”，以供奉海上守护神的严岛神社而闻名，部分神社建筑位于海上。我们将有时间参观神社，漫步于美丽的市中心并享用午餐。接着我们将继续前往广岛，这座城市因1945年原子弹轰炸而悲惨地闻名，原子弹彻底摧毁了这座城市。我们将参观和平纪念公园、原子弹爆炸圆顶和令人震撼的和平博物馆。包含晚餐。"
							},
							{
								name: "07 - 周日. 广岛 - 松山.",
								description: "今天我们将乘坐渡轮前往四国岛，这是一段非常愉快的两个半小时的旅程。通过这次旅行，我们将更加了解日本，这个由多个岛屿组成的国家。到达松山后，我们将乘坐缆车前往松山城，这是一座令人印象深刻的城堡。之后，我们将参观石手寺，这是一座美丽的佛教寺庙，也是一个朝圣的地方。包含午餐（当地餐厅）。在傍晚时分，我们将包含道后温泉的门票，这是一座建于19世纪的传统日式温泉。"
							},
							{
								name: "08 - 周一. 松山 - 琴平 - 高松 - 鸣门 - 神户.",
								description: "我们将前往琴平，参观供奉海员之神的神道教神社，这是建在象州山上的朝圣地，需要攀登785级台阶才能到达。之后，我们将在高松参观栗林公园，这是日本最美丽的花园之一。包含午餐（当地餐厅）。然后，我们前往鸣门，参观一座横跨大海的壮观桥梁，并步行在其玻璃地板上，观察45米高处海中形成的漩涡。我们的路线将继续穿越岛屿之间的宏伟桥梁，欣赏沿途的美丽景色。抵达神户，住宿。"
							},
							{
								name: "09 - 周二. 神户 - 大阪 - 高野山.",
								description: "在神户，我们将参观地震纪念博物馆，这座博物馆旨在纪念1995年袭击这座城市的悲剧性地震以及城市的完美重建。注意：在特定日期，地震博物馆的参观可能会被改为参观清酒博物馆或登上神户塔。随后我们前往大阪。然后，我们将离开城市，前往高野山，这是日本真言宗佛教最重要的中心。在这座神圣的山上，一个充满神秘和魔法的朝圣地，有120座寺庙和修道院。我们将参观奥之院，这是一处精神圣地，也是一个自然环境中巨大的墓地，拥有超过20万座墓碑。我们还将参观建于1593年的金刚峰寺。今晚我们将住在一个佛教寺院中。僧侣们将为我们提供一次禅宗冥想（阿字观）体验。包含晚餐（素食）。如果您愿意，您还可以在凌晨参加寺庙的宗教仪式。注意：在寺院住宿是一种宝贵的体验。房间简单，没有私人浴室，采用传统的日式榻榻米风格。浴室是公用的。"
							},
							{
								name: "10 - 周三. 高野山 - 京都.",
								description: "在寺庙享用日式早餐后，我们将一早出发前往京都，我们将在这座城市度过整整一天。京都是日本的前首都，从公元794年至1868年，接待了皇室的所在地。在第二次世界大战期间，这座城市是唯一没有遭到轰炸的大城市，因此保留了丰富的艺术遗产。1997年，旨在限制温室气体排放的京都议定书在这里签署。我们将参观城市的主要景点。我们将参观壮丽的伏见稻荷神社、京都御所和金阁寺，这座寺庙以其美丽的花园而闻名。"
							},
							{
								name: "11 - 周四. 京都 - 东京.",
								description: "早餐后，我们将乘坐新干线前往东京。我们的列车将于早上出发，仅需两个多小时即可到达两地之间的500公里。抵达东京后，傍晚时分，我们将乘坐地铁（票价包含）前往新宿区。您将看到大量使用现代高效公共交通工具的人群。在新宿，我们将置身于一个最具活力的夜生活区。包含晚餐（当地餐厅）。返回酒店。"
							},
							{
								name: "12 - 周五. 东京.",
								description: "上午，我们将安排一位导游带领我们进行长达五小时的城市游览。这座城市的繁忙和超现代的街区与宁静的区域并存。我们将在增上寺短暂停留，拍摄东京塔的最佳景观。值得注意的是，有数百个地藏菩萨像，这些石像是儿童的守护神，上面戴着毛线帽和围嘴，还有风车。接下来，我们将前往著名的涩谷路口，据说这是世界上最拥挤的十字路口。我们将继续参观明治神宫，供奉明治天皇及其皇后的灵位。我们的巴士随后将带我们沿着优雅的表参道街行驶，经过皇宫及其花园的最美丽部分。我们将在著名的二重桥停留参观。然后穿越秋叶原，“电器城”，这是日本动漫和漫画的中心，并经过两国国技馆，日本的相扑竞技场。最后，我们将在浅草结束行程，参观浅草寺和著名的仲见世街。包含午餐（当地餐厅）。返回酒店，自由活动。"
							},
							{
								name: "13 - 周六. 东京.",
								description: "早餐后，结束我们的服务。"
							}
						]
					}
				]
			}
		]
	},
	america: {
		name: "-",
		data: [
			{
				name: "arg",
				flag: "argentina.png",
				data: [
					{
						name: "伊瓜苏魔幻之旅",
						duration: "06天",
						days: [
							{
								name: "1 - 抵达伊瓜苏",
								description: "抵达伊瓜苏机场（IGU或IGR）后，您将被接送至酒店。剩下的时间自由安排，您可以选择休息或按自己的节奏探索这座城市。入住伊瓜苏。"
							},
							{
								name: "2 - 伊瓜苏瀑布（巴西侧）",
								description: "早餐后，您将享受一次前往壮观的伊瓜苏瀑布（巴西侧）的游览。此次游览将使您可以欣赏到瀑布的全景。下午自由活动，可选择参与其他自费活动或休息。入住伊瓜苏。"
							},
							{
								name: "3 - 伊瓜苏瀑布（阿根廷侧）+ 转机至布宜诺斯艾利斯 ",
								description: "在酒店享用早餐后，您将前往参观伊瓜苏瀑布（阿根廷侧），在那里您可以更近距离地感受瀑布的强大力量。您可以选择在鸟类公园停留，或参观梦境乐园（Dreamland），并包含一张景点门票。此外，您还将免费停留在Chocolate Caseiro手工艺中心。下午，您将被送往机场，搭乘飞往布宜诺斯艾利斯的航班。抵达后，接送至酒店。入住布宜诺斯艾利斯。"
							},
							{
								name: "4 - 布宜诺斯艾利斯市区游览及购物之旅",
								description: "早餐后，您将参加半天的布宜诺斯艾利斯市区游览，参观城市中最具代表性的景点。下午，您将享受购物之旅，参观皮革工厂、服装店以及其他当地产品商店。入住布宜诺斯艾利斯。"
							},
							{
								name: "5 - 布宜诺斯艾利斯自由活动日",
								description: "这一天自由安排，您可以自行探索布宜诺斯艾利斯。您可以享受在Puerto Madero的Puerto Cristal餐厅10%的折扣，享用美味晚餐。此外，若您愿意，还可以参观Puerto Madero赌场，赌场将提供免费入场和接送服务（限18岁以上客人）。入住布宜诺斯艾利斯。"
							},
							{
								name: "6 - 离开布宜诺斯艾利斯",
								description: "早餐后，您将有一些自由时间，之后会被送往机场，搭乘返程航班。服务结束。"
							}
						],
						services_included: "在伊瓜苏：\n" +
							"机场/酒店/机场接送。\n" +
							"2晚含早餐的住宿。\n" +
							"伊瓜苏瀑布游览（巴西侧和阿根廷侧）。\n" +
							"在鸟类公园停留或参观梦境乐园，并包含一张景点门票。\n" +
							"停留在Chocolate Caseiro手工艺中心。\n" +
							"在布宜诺斯艾利斯：\n" +
							"半私人接送服务，机场/酒店/机场接送。\n" +
							"3晚含早餐的住宿。\n" +
							"半天的市区游览（常规服务）。\n" +
							"在皮革和服装工厂的购物之旅。\n" +
							"Puerto Cristal餐厅10%折扣。\n" +
							"Puerto Madero赌场免费入场和接送服务。\n" +
							"酒店税。\n",
						services_additional: "伊瓜苏Rafain烤肉店晚餐秀（额外费用）."
					}
				]
			},
			{
				name: "br",
				flag: "brasil.png",
				data: [
					{
						name: "伊瓜苏魔幻之旅",
						duration: "06天",
						days: [
							{
								name: "1 - 抵达伊瓜苏",
								description: "抵达伊瓜苏机场（IGU或IGR）后，您将被接送至酒店。剩下的时间自由安排，您可以选择休息或按自己的节奏探索这座城市。入住伊瓜苏。"
							},
							{
								name: "2 - 伊瓜苏瀑布（巴西侧）",
								description: "早餐后，您将享受一次前往壮观的伊瓜苏瀑布（巴西侧）的游览。此次游览将使您可以欣赏到瀑布的全景。下午自由活动，可选择参与其他自费活动或休息。入住伊瓜苏。"
							},
							{
								name: "3 - 伊瓜苏瀑布（阿根廷侧）+ 转机至布宜诺斯艾利斯 ",
								description: "在酒店享用早餐后，您将前往参观伊瓜苏瀑布（阿根廷侧），在那里您可以更近距离地感受瀑布的强大力量。您可以选择在鸟类公园停留，或参观梦境乐园（Dreamland），并包含一张景点门票。此外，您还将免费停留在Chocolate Caseiro手工艺中心。下午，您将被送往机场，搭乘飞往布宜诺斯艾利斯的航班。抵达后，接送至酒店。入住布宜诺斯艾利斯。"
							},
							{
								name: "4 - 布宜诺斯艾利斯市区游览及购物之旅",
								description: "早餐后，您将参加半天的布宜诺斯艾利斯市区游览，参观城市中最具代表性的景点。下午，您将享受购物之旅，参观皮革工厂、服装店以及其他当地产品商店。入住布宜诺斯艾利斯。"
							},
							{
								name: "5 - 布宜诺斯艾利斯自由活动日",
								description: "这一天自由安排，您可以自行探索布宜诺斯艾利斯。您可以享受在Puerto Madero的Puerto Cristal餐厅10%的折扣，享用美味晚餐。此外，若您愿意，还可以参观Puerto Madero赌场，赌场将提供免费入场和接送服务（限18岁以上客人）。入住布宜诺斯艾利斯。"
							},
							{
								name: "6 - 离开布宜诺斯艾利斯",
								description: "早餐后，您将有一些自由时间，之后会被送往机场，搭乘返程航班。服务结束。"
							}
						],
						services_included: "在伊瓜苏：\n" +
							"机场/酒店/机场接送。\n" +
							"2晚含早餐的住宿。\n" +
							"伊瓜苏瀑布游览（巴西侧和阿根廷侧）。\n" +
							"在鸟类公园停留或参观梦境乐园，并包含一张景点门票。\n" +
							"停留在Chocolate Caseiro手工艺中心。\n" +
							"在布宜诺斯艾利斯：\n" +
							"半私人接送服务，机场/酒店/机场接送。\n" +
							"3晚含早餐的住宿。\n" +
							"半天的市区游览（常规服务）。\n" +
							"在皮革和服装工厂的购物之旅。\n" +
							"Puerto Cristal餐厅10%折扣。\n" +
							"Puerto Madero赌场免费入场和接送服务。\n" +
							"酒店税。\n",
						services_additional: "伊瓜苏Rafain烤肉店晚餐秀（额外费用）."
					}
				]
			},
			{
				name: "ecu",
				flag: "ecuador.png",
				data: [
					{
						name: "加拉帕戈斯魔幻之旅",
						duration: "4天3夜",
						days: [
							{
								name:"1 - 机场接机 + 达尔文研究站",
								description: "抵达巴尔特拉机场后，我们的导游将陪同您前往圣克鲁斯岛。下午，您将参观查尔斯·达尔文研究站和加拉帕戈斯国家公园解说中心，在那里您可以了解更多关于巨龟和鬣蜥的信息。晚上您将住宿在阿约拉港。"
							},
							{
								name: "2 - 龟湾 + 高原",
								description: "早上，您将探索美丽的龟湾，首先进行2.5公里的铺装道路徒步旅行，导游将介绍当地植物。首先，您将参观布拉瓦海滩，由于其强劲的海流和高达2米的海浪，这里非常适合冲浪者。随后，您将前往曼萨海滩，这里是放松和享受阳光的理想之地，拥有清澈的海水和洁白的沙滩。之后，您将乘坐共享交通工具从阿约拉港前往双子火山口，然后继续前往一家私人牧场享用午餐。下午，您将进行徒步旅行，观察巨大的加拉帕戈斯龟在自然栖息地中的生活，并探索一个熔岩隧道，之后返回港口。"
							},
							{
								name: "3 - 裂缝 + 德国人海滩",
								description: "根据导游安排的时间，您将在酒店大堂集合，步行到码头乘坐水上出租车前往埃斯特拉达角区域。在那里，您将进行短暂的徒步旅行，抵达裂缝，这是一个令人印象深刻的火山地形，适合在平静清澈的水中游泳，也适合喜欢潜水的人。之后，您将参观德国人海滩，这里因其白沙和透明的海水而广受欢迎，是在阿约拉港附近放松的理想之地。"
							},
							{
								name: "4 - 出发并前往机场",
								description: "享用完美味的早餐后，您将办理酒店退房手续，并被送往机场搭乘返程航班。服务结束。"
							}
						]
					},
					{
						name: "加拉帕戈斯探险之旅",
						duration: "5天4夜",
						days: [
							{
								name: "1 - 抵达加拉帕戈斯 + 查尔斯达尔文研究站与高原",
								description: "2月17日，抵达巴尔特拉机场后，我们的导游将带您参观查尔斯达尔文研究站和加拉帕戈斯国家公园解说中心，在那里您可以观察到陆龟和鬣蜥。然后，您将从阿约拉港前往双子火山口和一个私人牧场，您将有机会进行徒步旅行，观察巨龟在自然栖息地中的生活，并探索一个熔岩隧道。当天将返回港口，并入住豪华酒店。."
							},
							{
								name: "2 - 伊莎贝拉岛之旅 + 鲨鱼湾",
								description: "2月18日，您将清晨乘船前往伊莎贝拉岛，参观鲨鱼湾，这里以丰富的海洋生物而闻名，包括白鳍鲨、海狮、海鬣蜥、蓝脚鲣鸟、萨利轻脚蟹、海龟、鹈鹕和加拉帕戈斯唯一的企鹅。参观结束后，您将在附近的海湾进行浮潜，然后返回伊莎贝拉岛的码头。晚上入住豪华酒店。"
							},
							{
								name: "3 - 内格拉火山 + 圣克鲁斯岛",
								description: "2月19日，您将前往伊莎贝拉岛的高地，开始徒步前往内格拉火山，这是世界第二大火山口。如果您愿意，可以继续徒步前往小火山，观看喷气孔和壮观的熔岩地形。下午，返回圣克鲁斯岛住宿。"
							},
							{
								name: "4 - 北塞摩尔岛之旅",
								description: "2月20日，早餐后，您将前往北塞摩尔岛，进行徒步、游泳和浮潜。在岛上，您将看到圣树、蓝脚鲣鸟、叉尾鸥和海狮。您还将参观巴卡斯海滩，这里以其清澈的海水和洁白的沙滩而闻名。晚上返回酒店。"
							},
							{
								name: "5 - 离开加拉帕戈斯",
								description: "2月21日，您将在酒店享用早餐后有自由时间探索或放松。午餐后，您将被送往机场，搭乘返回利马的航班。"
							}
						],
						services_included: "4晚豪华酒店住宿，含早餐。\n" +
							"加拉帕戈斯专业导游。\n" +
							"游览和岛屿间的交通。\n",
						services_not_included: "往返利马至加拉帕戈斯的航班。\n" +
							"早餐以外的餐食。\n" +
							"加拉帕戈斯国家公园门票。\n" +
							"过境控制卡（TCT）。\n" +
							"其他未列出的服务，如船只交通、水上出租车等额外费用。\n",
						recommendations: "带上防晒霜、驱虫剂、泳衣和旅行保险。"
					}
				]
			},
			{
				name: "pe",
				flag: "peru.png",
				data: [
					{
						name: "利马快车",
						duration: "3天",
						description: "发现利马和帕拉卡斯3天之旅中的最佳体验，我们的独家旅行套餐！ 您是否在寻找结合历史、自然和文化的独特冒险？我们为您提供了完美的行程！沉浸在充满活力的利马市，探索神秘的巴耶斯塔斯群岛，并在帕拉卡斯的美景中放松，享受我们的3天利马和帕拉卡斯套餐。这个特别设计的行程适合那些希望同时体验两种美好事物的旅行者，融合了文化体验、前哥伦布时期的历史、壮丽的自然景观和美食佳肴。",
						days: [
							{
								name: "第一天 - 利马的文化探索",
								description: "欢迎来到利马！抵达豪尔赫·查韦斯国际机场后，我们的团队将在此等候，并带您前往米拉弗洛雷斯或圣伊西德罗的舒适酒店。稍事休息后，准备迎接充满历史和文化的一天。 从参观拉尔科博物馆开始您的冒险之旅，这是一颗隐藏的瑰宝，收藏了利马最大规模的前哥伦布时期艺术品。欣赏古代秘鲁文明的精美陶器、纺织品和金银物件。 在博物馆迷人的餐厅享用美味的秘鲁午餐，周围是美丽的花园。 通过参观黄金博物馆，发现秘鲁的历史财富，您将在这里看到具有重要文化价值的珠宝和文物。 在米拉弗洛雷斯著名的餐厅享用晚餐，并在拉尔科马尔放松地漫步，这是一个开放式购物中心，拥有太平洋的壮丽景色。"
							},
							{
								name: "第二天 - 帕拉卡斯的自然与冒险",
								description: "准备迎接激动人心的一天！我们一大早出发前往美丽的帕拉卡斯湾，这是一个因其丰富的生物多样性和令人叹为观止的景观而闻名的目的地。 到达帕拉卡斯后，您将登上一艘船，探索令人印象深刻的巴耶斯塔斯群岛。近距离观察海狮、洪堡企鹅和各种海鸟在其自然栖息地的生活。 游览之后，您将有机会探索帕拉卡斯国家保护区，这里是一个沙漠天堂，拥有如红海滩般独特的海滩和令人屏息的全景。 享受海景美味晚餐，并在帕拉卡斯一家温馨的酒店过夜，经过一天充满冒险的行程后，尽情放松。"
							},
							{
								name: "第三天 - 返回利马并做最后的点缀",
								description: "在帕拉卡斯度过了一个放松的夜晚后，我们将返回利马，为您的旅程画上圆满的句号。 早晨我们将返回利马，正好赶上在米拉弗洛雷斯或圣伊西德罗享用美味的午餐，品尝当地风味。 下午可以自由探索米拉弗洛雷斯。您可以沿着海滨步道漫步，参观肯尼迪公园，或在咖啡馆放松，欣赏海景。 在利马享用告别晚宴后，我们的团队将带您前往豪尔赫·查韦斯国际机场，乘坐返回航班。"
							}
						],
						services_included: "机场往返接送服务。\n" +
							"利马至帕拉卡斯的私人交通工具，往返服务。\n" +
							"拉尔科博物馆、黄金博物馆、巴耶斯塔斯群岛和帕拉卡斯国家保护区的门票和导游服务。\n" +
							"在帕拉卡斯一晚住宿，含早餐。\n" +
							"全程个性化服务。\n",
						services_not_included: "往返利马的机票。\n" +
							"行程中未提及的午餐和晚餐。\n"
					},
					{
						name: "利马和帕拉卡斯快车",
						duration: "4天",
						description: "发现利马和帕拉卡斯4天的最佳体验，我们的独家旅行套餐。 您是否在寻找结合历史、自然、文化和美食的独特体验？我们为您提供了完美的行程！沉浸在充满活力的利马市，探索神秘的巴耶斯塔斯群岛，在帕拉卡斯的美景中放松，并学习如何制作秘鲁著名的酸橘汁腌鱼和皮斯科酸酒。这个4天的利马和帕拉卡斯套餐特别为希望探索两个目的地最佳之处的旅行者设计，包括文化体验、前哥伦布时期的历史、壮丽的自然景观、美食佳肴和独特的烹饪课程。",
						days: [
							{
								name: "第一天：利马的文化探索",
								description: "欢迎来到利马！抵达豪尔赫·查韦斯国际机场后，我们的团队将在此等候，并带您前往米拉弗洛雷斯或圣伊西德罗的舒适酒店。稍事休息后，准备迎接充满历史和文化的一天。 从参观拉尔科博物馆开始您的冒险之旅，这是一颗隐藏的瑰宝，收藏了利马最大规模的前哥伦布时期艺术品。欣赏古代秘鲁文明的精美陶器、纺织品和金银物件。 在博物馆迷人的餐厅享用美味的秘鲁午餐，周围是美丽的花园。 通过参观黄金博物馆，发现秘鲁的历史财富，您将在这里看到具有重要文化价值的珠宝和文物。 在米拉弗洛雷斯著名的餐厅享用晚餐，并在拉尔科马尔放松地漫步，这是一个开放式购物中心，拥有太平洋的壮丽景色。"
							},
							{
								name: "第二天：秘鲁烹饪课和魔幻水路",
								description: "今天，您将学习如何制作一些秘鲁最具代表性的菜肴，并欣赏一个独特的夜间表演。 早上参加秘鲁烹饪课，学习制作著名的酸橘汁腌鱼和皮斯科酸酒，这些都是该国的招牌菜。在当地厨师的指导下，您将发现这些标志性菜肴背后的秘密，并在午餐时享用您自己的作品。 课后，您可以自由探索米拉弗洛雷斯。您可以沿着海滨步道漫步，参观肯尼迪公园，或在咖啡馆放松，欣赏海景。 晚上，在魔幻水路享受一个难忘的表演，这是世界上最大的喷泉公园，您可以欣赏到灯光、水和音乐的展示。"
							},
							{
								name: "第三天：帕拉卡斯的自然与冒险",
								description: "准备迎接激动人心的一天！我们一大早出发前往美丽的帕拉卡斯湾，这是一个因其丰富的生物多样性和令人叹为观止的景观而闻名的目的地。 到达帕拉卡斯后，您将登上一艘船，探索令人印象深刻的巴耶斯塔斯群岛。近距离观察海狮、洪堡企鹅和各种海鸟在其自然栖息地的生活。 游览之后，您将有机会探索帕拉卡斯国家保护区，这里是一个沙漠天堂，拥有如红海滩般独特的海滩和令人屏息的全景。 享受海景美味晚餐，并在帕拉卡斯一家温馨的酒店过夜，经过一天充满冒险的行程后，尽情放松。"
							},
							{
								name: "第四天：返回利马并做最后的点缀",
								description: "在帕拉卡斯度过了一个放松的夜晚后，我们将返回利马，为您的旅程画上圆满的句号。 早晨我们将返回利马，正好赶上在米拉弗洛雷斯或圣伊西德罗享用美味的午餐，品尝当地风味。 下午可以自由探索米拉弗洛雷斯。您可以沿着海滨步道漫步，参观肯尼迪公园，或在咖啡馆放松，欣赏海景。 在利马享用告别晚宴后，我们的团队将带您前往豪尔赫·查韦斯国际机场，乘坐返回航班。"
							}
						],
						services_included: "机场往返接送服务。\n" +
							"利马至帕拉卡斯的私人交通工具，往返服务。\n" +
							"拉尔科博物馆、黄金博物馆、巴耶斯塔斯群岛、帕拉卡斯国家保护区和魔幻水路的门票和导游服务。\n" +
							"秘鲁烹饪课和午餐。\n" +
							"在帕拉卡斯一晚住宿，含早餐。\n" +
							"全程个性化服务。\n",
						services_not_included: "往返利马的机票。\n" +
							"行程中未提及的午餐和晚餐。\n"
					}
				]
			},
			{
				name: "usa",
				flag: "usa.png",
				data: [
					{
						name: "纽约市",
						duration: "9天8夜",
						days: [
							{
								name: "第一天：10月5日，星期六",
								description: "抵达纽约市。 办理酒店入住手续。 自由活动，休息或探索城市。"
							},
							{
								name: "第二天：10月6日，星期日",
								description: "自由女神像游船门票。 建议时间：上午10:00。 乘坐渡轮前往自由岛。 参观博物馆并进入自由女神像基座。 剩下的时间自由探索炮台公园及周边地区。"
							},
							{
								name: "第三天：10月7日，星期一",
								description: "波士顿一日游（从纽约出发）。 出发时间：上午6:00。 参观波士顿的标志性景点，如芬纽尔厅、昆西市场、灯塔山和哈佛大学。 预计返回纽约时间：晚上8:00。"
							},
							{
								name: "第四天：10月8日，星期二",
								description: "上城和下城曼哈顿游。 开始时间：上午9:00。 游览上曼哈顿，途经中央公园、林肯中心、哈林区和第五大道。 继续前往下曼哈顿，停留在格林威治村、苏荷区、唐人街、小意大利、华尔街、911纪念馆和炮台公园。 时长：4小时。 下午自由活动，继续探索城市。"
							},
							{
								name: "第五天：10月9日，星期三",
								description: "华盛顿一日游（从纽约出发）。 出发时间：上午6:00。 参观阿灵顿国家公墓、五角大楼、白宫、林肯纪念堂等。 参观航空航天博物馆：预定时间为下午2:00至3:30。 预计返回纽约时间：晚上9:00。"
							},
							{
								name: "第六天：10月10日，星期四",
								description: "反差之旅。 开始时间：上午8:00。 探索新泽西、布朗克斯、皇后区和布鲁克林的街区。 可选择在游览结束时步行穿越布鲁克林大桥。 时长：5小时。 下午自由活动，探索城市。"
							},
							{
								name: "第七天：10月11日，星期五",
								description: "纽约自由活动日。 推荐：参观中央公园、时代广场或大都会博物馆或现代艺术博物馆。"
							},
							{
								name: "第八天：10月12日，星期六",
								description: "反差之旅（选项2）。 如果您决定重复游览或未在星期四参加此游览，这是另一个机会。"
							},
							{
								name: "第九天：10月13日，星期日",
								description: "离开纽约。 办理酒店退房手续。 转送至机场。"
							}
						],
						hotels: ["爱迪生酒店", "纽约时代广场喜来登酒店", "曼哈顿时代广场中央汉普顿酒店（含早餐）"],
						notes: "一些游览并不在同一家酒店结束，因此游客可能需要自行返回酒店。\n" +
							"游览集合点：纽约时代广场喜来登酒店、RIU广场纽约时代广场酒店和纽约客酒店。接送时间根据游览的不同而有所变化。"
					},
					{
						name: "迪士尼世界行程安排",
						duration: "---FALTA---",
						days: [
							{
								name: "第1天",
								description: "抵达奥兰多。 白天从机场到所选酒店的共享接送服务。 办理所选酒店的入住手续。 入住奥兰多。"
							},
							{
								name: "第2至第5天",
								description: "参观迪士尼乐园（每天一个园区）：动物王国，魔法王国，好莱坞影城，Epcot未来世界。 入住奥兰多。"
							},
							{
								name: "第5和第6天",
								description: "参观环球影城和冒险岛（含园区间通票）。 入住奥兰多。"
							},
							{
								name: "第7天",
								description: "自由活动日。 入住奥兰多。"
							},
							{
								name: "第8天",
								description: "办理酒店退房手续。 白天共享接送服务至机场。 离开奥兰多。"
							}
						],
						hotels: ["迪士尼全明星体育酒店", "奥兰多湖布埃纳维斯塔原住客栈酒店（含早餐）", "奥兰多湖布埃纳维斯塔汉普顿酒店（含早餐）"]
					}
				]
			},
			{
				name: "usa cruise",
				flag: "usa.png",
				data: [
					{
						name: "游轮 - 选项 1 拿骚，巴哈马",
						duration: "5天4夜",
						departure: "劳德代尔堡",
						route: "拿骚，巴哈马 / 迪士尼漂流岛（私人岛屿）/ 灯塔角迪士尼瞭望岛（私人岛屿）",
						services_included: "游轮，全餐，茶，咖啡，牛奶，自动饮料机饮料，表演，每日娱乐，健身房等公共区域的使用，税费，港口费用。",
						services_not_included: "机票，汽水，新鲜榨汁，援助和取消保险，短途旅行，Wi-Fi，接送服务，购物，小费（每人每晚14.50美元），赌场，水疗服务，以及任何未列为包含的项目，酒精饮料。"
					},
					{
						name: "游轮 - 选项 1 拿骚，巴哈马",
						duration: "5天4夜",
						departure: "卡纳维拉尔港",
						route: "拿骚，巴哈马 / 迪士尼漂流岛（私人岛屿）/ 灯塔角迪士尼瞭望岛（私人岛屿）",
						services_included: "游轮，全餐，茶，咖啡，牛奶，自动饮料机饮料，表演，每日娱乐，健身房等公共区域的使用，税费，港口费用。",
						services_not_included: "机票，汽水，新鲜榨汁，援助和取消保险，短途旅行，Wi-Fi，接送服务，购物，小费（每人每晚14.50美元），赌场，水疗服务，以及任何未列为包含的项目，酒精饮料。"
					}
				],
			}
		]
	},
	europe: {
		name: "-",
		data: [
			{
				name: "-",
				data: [
					{
						name: "---FALTA---",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 星期一. 巴勒莫 - 切法卢 - 卡斯特尔布诺 - 蒂达里斯 - 米拉佐",
								description: "我们将从巴勒莫出发，沿着第勒尼安海的海岸行驶，欣赏沿途美丽的风景。第一站是切法卢，一个迷人的海滨城市。它的诺曼大教堂以其原始建筑和马赛克而著称，已被列为世界文化遗产。历史中心非常美丽，有狭窄的小巷、海景以及热闹的商业和旅游生活。午餐后，我们继续前往岛屿的内陆，抵达卡斯特尔布诺，这是一个位于群山中的小而迷人的小镇，让我们感受到乡村的宁静，这里有城墙、城堡和中心的别致街道。接着，我们再次沿着海岸前往蒂达里斯，它坐落在一座俯瞰大海的高山上，圣母朝圣地蒂达里圣殿在此，因其黑色圣母像和壮丽的景色而闻名。圣殿附近，还可以看到古希腊城邦的遗址。然后，我们继续前往米拉佐，一个面向火山群岛（埃奥利群岛）且充满活力的港口城市。"
							},
							{
								name: "02 - 星期二. 米拉佐 - 利帕里 - 伏尔卡诺 - 陶尔米纳",
								description: "今天将是充满刺激的一天，我们将探索埃奥利群岛中的两座岛屿，这些岛屿因其持续的火山活动而被列为世界遗产。我们将乘坐渡轮前往利帕里，这是埃奥利群岛中最大的岛屿，也是最宜人的城市，拥有其历史中心、城堡和热闹的旅游氛围。您将有时间享用午餐和自由漫步。随后，我们将乘坐新的渡轮，不到30分钟即可到达伏尔卡诺，岛上的硫磺味弥漫整个岛屿，并有活跃的火山喷气孔。这个岛屿被其火山口主导，我们推荐体验硫磺温泉浴（据说这些泥浆对皮肤有益），这是一种独特的体验。之后，我们将再次乘坐渡轮返回米拉佐（超过一小时的航程），然后继续前往陶尔米纳，在傍晚时分抵达。 注意：在夏季或特定日期，住宿可能会安排在墨西拿而不是陶尔米纳。"
							},
							{
								name: "03 - 星期三. 陶尔米纳 - 卡尔塔吉罗内 - 卡萨尔古罗马别墅 - 巴勒莫",
								description: "在埃特纳火山脚下，美丽的城市陶尔米纳被丘吉尔形容为“地球上最美丽的地方”。您将有时间漫步在充满活力的老城区；古希腊剧院主宰着令人难忘的风景。上午晚些时候，我们继续向岛屿中心的卡尔塔吉罗内出发，这座城市因其丰富的巴洛克遗产而被列为世界遗产。我们将安排一段小火车观光之旅，了解城市历史并参观其主要景点。卡尔塔吉罗内是西西里岛的陶瓷之都，其众多商店和工艺坊可见一斑，其中最著名的景点是由142个台阶组成的阶梯，每个台阶都装饰着不同的瓷砖。午餐后，我们前往卡萨尔古罗马别墅（包含门票），这座别墅是世界遗产。在罗马帝国时期，别墅是农业开发的象征。在卡萨尔，保存的不仅仅是4世纪的建筑结构，还因为其数量和质量丰富的马赛克装饰，使其成为罗马世界中最美丽的作品之一。穿过岛屿中部的山区，我们继续前往巴勒莫，在傍晚时分抵达。"
							},
							{
								name: "04 - 星期四. 巴勒莫",
								description: "我们将安排一位当地导游带领参观巴勒莫市。亮点包括大教堂（包含门票），其风格多样，内部有皇家墓葬；诺曼王宫（旧皇宫），以及城市的喷泉、市场和花园。在参观期间，我们还将前往蒙雷阿莱（包含门票），其大教堂因其内部令人惊叹的金色马赛克装饰而被列为世界文化遗产。下午自由活动。 注意：有时，如果游客数量非常有限，参观可能不是私人形式，而是与当地公司的常规游览一起进行。 我们的服务结束。 请检查您的航班时间，以防需要额外的住宿夜晚。"
							}
						],
						services_included: "包含西班牙语和葡萄牙语导游的巴士游、基础旅行保险、酒店住宿和自助早餐。\n" +
							"全景游览: 巴勒莫\n" +
							"短途旅行: 在巴勒莫的蒙雷阿莱\n" +
							"门票包括: 卡萨尔的罗马别墅、巴勒莫大教堂、蒙雷阿莱大教堂\n" +
							"火车游览: 卡尔塔吉罗内\n" +
							"乘渡轮游览: 埃奥利亚群岛"
					},
					{
						name: "巴塞罗那和瓦伦西亚",
						duration: "---FALTA---",
						days: [
							{
								name: "第1天 - 星期一 / 星期四. 马德里 - 萨拉戈萨 - 波夫莱特 - 巴塞罗那",
								description: "我们从马德里出发前往阿拉贡，穿越卡斯蒂利亚的土地。我们将在萨拉戈萨短暂停留，参观皮拉尔圣母大教堂。午餐后，继续前往波夫莱特修道院，这座修道院被城墙环绕，坐落在美丽的环境中，是世界上保存最完好的中世纪修道院之一（包含门票）。之后前往巴塞罗那，傍晚时分抵达。"
							},
							{
								name: "第2天 - 星期二 / 星期五. 巴塞罗那",
								description: "上午，我们将安排一次巴塞罗那市的全景游览，这座城市被认为是最有趣的欧洲首都之一，面向地中海，以高迪及其现代主义建筑闻名，还不能忘记它的兰布拉大道以及在那里举办过的世界博览会和奥运会的记忆。下午自由活动。 晚上，我们将提供前往Maremagnum的接送服务，这是一个现代的海滨休闲和餐饮中心，靠近科隆广场，您可以在这个充满活力的区域散步和用餐。"
							},
							{
								name: "第3天 - 星期三 / 星期六. 巴塞罗那 - 佩尼斯科拉 - 瓦伦西亚 - 马德里",
								description: "我们沿着地中海沿岸向南行驶。我们将在佩尼斯科拉停留，这是一座被美丽海滩环绕的小型中世纪城墙城市。自由时间散步。之后前往瓦伦西亚，这座港口城市位于莱万特海岸，我们将在午餐时间到达。您是否想尝试一下他们著名的海鲜饭呢？大约在下午4:30，我们出发，短暂停留在瓦伦西亚的艺术与科学城，然后继续前往马德里。预计到达时间为晚上8:30至9:00左右。 注意： 如果从瓦伦西亚到马德里的旅客数量非常有限，可能会改为乘坐高铁前往。"
							}
						],
						services_included: "提供西班牙语和葡萄牙语导游的长途汽车旅行、基础旅行保险、酒店和自助早餐。\n" +
							"全景游览：波夫莱特、巴塞罗那。\n" +
							"夜间接送：巴塞罗那的Maremagnum。\n" +
							"门票：波夫莱特修道院。\n"
					},
					{
						name: "巴斯克地区",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 周六. 马德里 - 塞戈维亚 - 佩德拉萨 - 毕尔巴鄂",
								description: "我们出发前往卡斯蒂利亚地区，穿越群山，欣赏美丽的风景。我们的第一站是塞戈维亚。环绕城市时，我们到达了圣马科斯草地的观景台，从那里可以看到塞戈维亚城堡及其城墙的最佳景色，这也是沃尔特·迪斯尼创作《睡美人》中巫婆城堡的灵感来源。随后，我们在塞戈维亚的罗马水道桥旁停车，该水道桥建于公元1世纪，一直使用到1973年，现在被联合国教科文组织列为世界文化遗产。您将有自由时间在老城区漫步，参观主广场，了解大教堂和阿尔卡萨城堡。 我们继续沿着小路行驶，欣赏前往佩德拉萨途中的村庄和风景，这里被认为是西班牙最美丽的村庄之一。这个中世纪的围墙小镇有鹅卵石街道、历史建筑、主广场和可追溯到13世纪的堡垒。您将有时间在这里享用午餐。下午，我们继续前往巴斯克地区，抵达毕尔巴鄂。我们建议您在老城区散步，这里因其众多的酒馆和小吃吧而闻名，您可以品尝到著名的“小吃”（pintxos）。"
							},
							{
								name: "02 - 周日. 毕尔巴鄂 - 葡萄牙特 - 格尔尼卡 - 圣塞巴斯蒂安",
								description: "今天我们探索毕尔巴鄂及其河口，重点参观古根海姆博物馆及其令人印象深刻的外部建筑，老城区及其热闹的街道和大教堂。我们还将参观位于葡萄牙特的比斯开桥（包含门票），它被联合国教科文组织列为世界文化遗产，并且是世界上第一座为行人和车辆设计的跨运桥。您将有自由时间散步和午餐。我们推荐里韦拉市场，它提供典型的巴斯克美食摊位，结构类似于河边的船只。 午餐后，我们前往格尔尼卡，这里是西班牙内战期间最严重屠杀之一的发生地，毕加索的画作《格尔尼卡》就是以此为题材。在这里，您会看到格尔尼卡树，在中世纪时期，巴斯克地区的自治权就是在这棵树下授予的。我们继续前往翁达里比亚（丰特拉比亚），一个拥有沙滩、城墙、海洋氛围和历史中心的地方，其狭窄的鹅卵石街道格状布局令人惊叹。您有时间探索历史中心。 最后，我们前往圣塞巴斯蒂安，傍晚时分抵达。我们建议您探索“老城区”，那里以其热闹的小吃吧和充满活力的氛围而闻名，是美食爱好者的热门目的地。每个酒吧都有其独特的特色和风格，品尝不同的小吃是一种令人兴奋和美味的体验。"
							},
							{
								name: "03 - 周一. 圣塞巴斯蒂安 - 布尔戈斯 - 马德里",
								description: "早晨在圣塞巴斯蒂安，我们将乘坐伊格尔多山的缆车，在那里您可以欣赏到海湾的壮丽景色。之后，您将有自由时间享受大海滩或在大道上散步。午餐后，我们将沿着风景秀丽的高速公路前往卡斯蒂利亚，抵达布尔戈斯。您将有时间参观历史中心，其中的布尔戈斯大教堂是一件哥特式风格的瑰宝。然后，我们继续前往马德里，傍晚时分到达。 我们的服务结束。 请检查您的航班时间，以确定是否需要额外的一晚住宿。"
							}
						],
						services_included: "西班牙语和葡萄牙语导游的巴士游、基础旅行保险、酒店住宿和自助早餐。\n" +
							"全景游览：毕尔巴鄂。\n" +
							"接送：塞戈维亚的圣马科斯草地观景台。\n" +
							"门票：葡萄牙特的比斯开桥。\n" +
							"缆车：圣塞巴斯蒂安的伊格尔多山。\n"
					},
					{
						name: "加泰罗尼亚和法国",
						duration: "---FALTA---",
						days: [
							{
								name: "第1天 - 星期二. 巴塞罗那 - 赫罗纳 - 贝萨卢 - 科利乌尔 - 卡尔卡松",
								description: "我们向加泰罗尼亚北部进发。抵达赫罗纳，我们将有时间探索这座城市，其壮丽的历史中心被中世纪建筑以及罗马、阿拉伯和犹太文化的影响所主导。我们建议参观位于城市高处的宏伟的哥特式建筑——圣玛利亚大教堂，以及古老的犹太区，其狭窄而充满魅力的街道。接着，我们继续前往中世纪小镇贝萨卢，在那里我们有时间享用午餐，并漫步其街道、壮观的石砌堡垒桥、城堡、犹太浴场和古老的朝圣者医院。午餐后，我们继续前往法属加泰罗尼亚地区。 抵达科利乌尔，一个充满魅力的小海滨城镇，有沙滩、港口、狭窄的街道和一座建在古罗马城堡遗址上的宏伟城堡，曾是马略卡国王的宫廷所在地。如果你愿意，还可以参观诗人安东尼奥·马查多的墓地。傍晚时分，抵达卡尔卡松，这被认为是欧洲最好的城墙城市。我们建议你参观夜间被灯光照亮的城墙，并在其数百家“小城”内的小餐馆之一享用晚餐。"
							},
							{
								name: "第2天 - 星期三. 卡尔卡松 - 纳博讷 - 马赛",
								description: "上午在卡尔卡松。在我们的导游陪同下，我们将参观其宏伟的伯爵城堡（包含门票）及其中世纪城墙。之后，你将有自由时间漫步。在午餐后，我们将继续前往纳博讷，在那里我们将有时间探索这座历史悠久的奥克西塔尼亚城市及其风景如画的历史中心。傍晚时分，我们抵达法国第二大城市马赛。我们建议在充满活力的旧港漫步，这里有许多小餐馆。"
							},
							{
								name: "Day 3 - Thursday. Marseille - Aix en Provence - Avignon - Pont du Gard - Barcelona",
								description: "今天我们将探索普罗旺斯地区。在普罗旺斯地区艾克斯（普罗旺斯艾克斯），画家保罗·塞尚的出生地，我们将有时间在其壮丽的历史中心漫步，欣赏其狭窄的小街道、喷泉和充满魅力的广场。然后我们继续前往阿维尼翁，在那里我们将有时间在这座坐落在罗讷河畔的有城墙的城市里步行并享用午餐，这里曾是天主教教皇的所在地，成为了第二个罗马，直到1791年并入法国。其宏伟的教皇宫展现了其历史过去。接着我们前往加尔桥（包含门票），这是一个令人印象深刻的罗马渡槽，联合国教科文组织世界遗产，也是世界上最著名和保存最完好的罗马古迹之一。我们继续返回加泰罗尼亚的路线。傍晚时分抵达巴塞罗那。"
							},
							{
								name: "第4天 - 星期五. 巴塞罗那",
								description: "早餐后，我们的服务结束。"
							}
						],
						services_included: "提供西班牙语和葡萄牙语导游的长途汽车旅行、基础旅行保险、酒店和自助早餐。\n" +
							"全景游览：巴塞罗那。\n" +
							"门票：卡尔卡松伯爵城堡、加尔桥罗马渡槽。\n"
					},
					{
						name: "波尔图和里斯",
						duration: "---FALTA---",
						days: [
							{
								name: "第1天 - 星期六. 马德里 - 罗德里戈市 - 波尔图",
								description: "清晨从马德里出发前往卡斯蒂利亚。我们将在四柱观景台短暂停留，这里可以欣赏到阿维拉城墙的壮丽景色。在接近葡萄牙边境的地方，我们停留在罗德里戈市，漫步游览其大教堂、城墙和中世纪街道。随后我们继续前往葡萄牙。到达波尔图并入住。我们将进行这座葡萄牙第二大城市的全景游览，波尔图以其葡萄酒闻名。游览结束时，我们将参观一家百年酒庄。之后自由活动时间。 注意： 如果马德里和波尔图之间的乘客人数非常少，该段可能会改为乘飞机进行，此情况下将取消罗德里戈市的停留。"
							},
							{
								name: "第2天 - 星期日. 波尔图 - 科英布拉 - 巴塔利亚 - 纳扎雷 - 奥比多斯 - 里斯本n",
								description: "我们向南旅行。自由时间参观科英布拉，这是葡萄牙第三大城市，以其古老的大学和迷人的陡峭街道而闻名。之后，我们将在巴塔利亚修道院停留，这是一座令人印象深刻的中世纪建筑，埋葬着一些国王。随后在纳扎雷，一座渔业和海滨小镇，有时间享用午餐和散步。我们安排了乘坐“电梯”到达老城区，那里可以欣赏到大海的壮丽景色。前往里斯本途中，我们将停留在奥比多斯，这个葡萄牙最美丽的村庄之一，以其白色的街道和满是鲜花的景致而闻名。下午晚些时候到达里斯本。"
							},
							{
								name: "第3天 - 星期一. 里斯本",
								description: "上午，我们将进行城市全景游览，了解葡萄牙的七山之城，狭窄的街道、瓷砖、“桑恸”和大西洋的光芒。我们还将参观贝伦区，15世纪伟大的葡萄牙航海家们正是从这里出发，横渡了大洋。下午自由活动，您可以在巴里奥阿尔托及其观景台漫步。如果您愿意，还可以选择前往辛特拉、卡斯凯什和埃斯托里尔的小镇游览。晚上，我们从充满活力的 Restauradores 广场（那里有酒吧和咖啡馆）返回酒店。"
							},
							{
								name: "第4天 - 星期二. 里斯本",
								description: "早餐后，结束我们的服务。"
							}
						],
						services_included: "带西班牙语和葡萄牙语导游的长途汽车旅行、基础旅行保险、酒店和自助早餐。\n" +
							"波尔图和里斯本的全景游览。\n" +
							"夜间交通：从里斯本 Restauradores 广场返回。\n" +
							"波尔图酒窖的入场费。\n" +
							"纳扎雷老城区的缆车乘坐。\n"
					},
					{
						name: "北欧三角",
						duration: "---FALTA---",
						days: [
							{
								name: "第1天 - 星期日. 奥斯陆",
								description: "欢迎光临！自由活动。在傍晚时分，您将通过酒店接待处的告示牌收到有关行程开始的信息。"
							},
							{
								name: "第2天 - 星期一. 奥斯陆 - 厄勒布鲁 - 斯德哥尔摩",
								description: "我们将安排当地导游带领您进行挪威首都奥斯陆的全景游览。这座城市将充满活力的现代建筑与古典建筑相结合，拥有优美的自然环境和大型雕塑公园。奥斯陆被评为“欧洲绿色之都”。游览结束后，您将有自由活动时间。 之后前往瑞典。旅途中，您将欣赏到森林和湖泊的美丽景色。我们会在厄勒布鲁停留，这是一个位于美丽湖畔的历史名城，有一座城堡和美丽的历史中心。傍晚时分抵达瑞典首都斯德哥尔摩，这是一座美丽的城市，位于波罗的海群岛，由众多桥梁和渡轮相连，以其丰富的历史、令人印象深刻的建筑以及现代与传统的融合而闻名。"
							},
							{
								name: "第3天 - 星期二. 斯德哥尔摩",
								description: "我们将带您游览这座美丽的首都，这座城市建立在14座相连的岛屿上。我们将参观老城（Gamla Stan），或称“桥间之城”，这是充满活力的老城区，拥有许多著名建筑，如位于Stortorget广场旁的诺贝尔博物馆，它向诺贝尔奖得主致敬，还有大教堂和皇家宫殿。在可能的情况下，我们将从Fjällgatan观景台欣赏壮观的城市景色。自由活动时间。我们推荐参观斯德哥尔摩的博物馆和公园。您还可以选择参观市政厅和瓦萨沉船博物馆。"
							},
							{
								name: "第4天 - 星期三. 斯德哥尔摩 - 赫尔辛堡 - 赫尔辛格 - 哥本哈根",
								description: "我们向南行驶。下午早些时候，在瑞典海滨活跃城市赫尔辛堡稍作停留，然后乘坐渡轮从瑞典前往丹麦的赫尔辛格。在赫尔辛格自由活动，这座城市因莎士比亚的《哈姆雷特》而闻名，其中最为著名的是克伦堡城堡。之后继续前往哥本哈根，傍晚时分抵达。"
							},
							{
								name: "第5天 - 星期四. 哥本哈根",
								description: "上午，我们将安排当地导游带领您进行丹麦首都的全景游览。哥本哈根以其生活质量、对可持续发展的重视和丰富的文化生活而闻名。我们将参观“小美人鱼”雕像，充满色彩的历史建筑的新港（Nyhavn），丹麦王室居住的阿美琳堡宫外观，市政厅广场，以及趣伏里公园区域。下午自由活动。"
							},
							{
								name: "第6天 - 星期五. 哥本哈根",
								description: "早餐后，行程结束。"
							}
						],
						services_included: "提供西班牙语和葡萄牙语导游的长途汽车旅行、基础旅行保险、酒店和自助早餐。\n" +
							"全景游览：奥斯陆、斯德哥尔摩、哥本哈根。\n" +
							"渡轮：瑞典到丹麦。\n"
					}
				]
			},
			{
				name: "-",
				data: [
					{
						name: "意大利之美",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 星期五. 罗马 - 特尔莫利 - 蒙特圣安杰洛 - 特拉尼 - 巴里",
								description: "欢迎来到您的欧洲之旅！今天我们前往亚得里亚海，穿越亚平宁山脉的壮丽景色。我们停下来参观圣乔万尼因维内雷修道院，这是一座建于11世纪的美丽修道院，建在古罗马神庙的遗址上。抵达特尔莫利，这是一座美丽的海滨小镇，古城墙环绕着沙滩，13世纪的城堡和罗马式大教堂尤其引人注目。午餐时间和自由漫步。接着我们继续前往特拉尼，亚得里亚海的明珠，这座美丽的城市拥抱大海，并展现其粉红色的罗马式大教堂。抵达巴里，在前往酒店之前有时间游览重要的港口、海滨长廊和老城区的小巷。"
							},
							{
								name: "02 - 星期六. 巴里 - 布林迪西 - 莱切 - 塔兰托 - 布林迪西",
								description: "早餐后，我们有时间在巴里老城区漫步，这是一座自罗马时代前就有人居住的港口城市。它狭窄的街道、教堂和热闹的氛围使它成为一个非常特别的城市。然后我们继续前往布林迪西，一个被城墙围绕的港口城市，您可以在海边散步，参观令人印象深刻的罗马柱，并漫步于老城区。我们的下一站是莱切，一个以其令人印象深刻的巴洛克建筑而闻名的迷人城市，这使它获得了“南方佛罗伦萨”的绰号。历史中心充满了装饰华丽的巴洛克建筑和教堂。时间漫步和午餐。在下午，我们参观塔兰托，意大利南部的一座充满活力的城市，有其阿拉贡城堡，古区和大教堂。晚上回到布林迪西，住宿。"
							},
							{
								name: "03 - 星期日. 布林迪西 - 阿尔贝罗贝洛 - 马泰拉 - 那不勒斯",
								description: "今天我们将参观两个壮观而独特的城镇。阿尔贝罗贝洛，一个以其独特的圆锥形房屋（称为“特鲁利”）而闻名的迷人村庄。这些独特的结构，以其堆叠的石头屋顶，被联合国教科文组织列为世界遗产，并被认为是意大利农村建筑的杰出范例。我们随后继续前往位于巴西利卡塔地区的马泰拉，以其古老的历史中心和“马泰拉石屋”而闻名，这些古老的房屋和建筑雕刻在岩石中，可追溯到几千年前。这个地方是联合国教科文组织的世界遗产，也是世界上最古老的连续有人居住的人类定居点之一。时间漫步和午餐。下午，我们穿越巴西利卡塔和坎帕尼亚，穿越美丽的山地景观。下午晚些时候抵达那不勒斯。自由时间。"
							},
							{
								name: "04 - 星期一. 那不勒斯 - 卡普里岛 - 索伦托 - 萨莱诺",
								description: "在那不勒斯自由活动，然后乘船前往卡普里岛，在这里您可以选择乘船前往白洞或乘坐缆车到卡普里首都。午餐后，我们再次登船前往索伦托，这是一座坐落在悬崖脚下的迷人旅游小镇。漫步时间。我们继续前往萨莱诺湾。在萨莱诺住宿。"
							},
							{
								name: "05 - 星期二. 萨莱诺 - 保拉 - 墨西拿 - 陶尔米纳",
								description: "我们向“靴子”南部进发。穿越奇连托国家公园的南意大利迷人景观。我们到达保拉，位于第勒尼安海岸，参观圣弗朗切斯科·迪保拉圣所，并有自由时间在其美丽的历史中心午餐。我们继续前往圣乔瓦尼别墅，在这里我们乘坐短途渡轮前往西西里岛。到达墨西拿，在这里我们将有时间在大教堂旁散步。继续前往陶尔米纳。"
							},
							{
								name: "06 - 星期三. 陶尔米纳",
								description: "在埃特纳火山脚下，美丽的陶尔米纳被丘吉尔称为世界上最美丽的地方。时间漫步于充满活力的古城区；其希腊剧院主导着令人难忘的景观。您还可以享受海滩。午餐和晚餐包括在内。"
							},
							{
								name: "07 - 星期四. 陶尔米纳 - 诺托 - 锡拉库萨 - 阿格里真托",
								description: "我们前往诺托，一个静止在时间中的小城市，西西里的巴洛克珠宝。然后我们继续前往锡拉库萨，这个城市曾因其力量和辉煌而一度成为雅典和罗马的竞争对手。时间参观其壮丽的历史中心并享用午餐。我们继续前往阿格里真托，在大巴上我们将看到壮丽的神殿谷。晚餐包括在内。"
							},
							{
								name: "08 - 星期五. 阿格里真托 - 马萨拉 - 埃里切 - 巴勒莫",
								description: "在当地导游的带领下，我们参观了神殿谷，这个令人印象深刻的考古遗址被认为是最完整的希腊神殿（包括门票）。我们前往马萨拉，这是一个历史悠久的城市，充满了气氛和著名的葡萄酒；午餐时间。我们继续沿着马萨拉的盐田前往埃里切，这是一个俯瞰大海的腓尼基起源的小镇。时间漫步。我们继续前往巴勒莫。晚餐包括在内。"
							},
							{
								name: "09 - 星期六. 巴勒莫 - 蒙雷阿莱 - 夜航： 早餐后参观巴勒莫",
								description: "包括转移到美丽的小村庄（现在是城市郊区）蒙雷阿莱，其美丽的阿拉伯-诺曼大教堂（包括门票），联合国教科文组织的世界遗产，因其覆盖内部的金色马赛克而令人印象深刻。西西里首府是一座充满对比的城市，有阿拉伯人、诺曼人和巴洛克辉煌的故事。亮点包括大教堂（包括门票）和诺曼王宫及其帕拉蒂娜教堂。参观结束时的自由时间。傍晚乘坐现代夜渡轮。双人舱住宿，带浴室。"
							},
							{
								name: "10 - 星期日. 那不勒斯 - 庞贝 - 卡西诺 - 罗马： 清晨到达那不勒斯",
								description: "（早餐不包括在内），然后继续前往庞贝，在那里您可以欣赏这座被维苏威火山灰掩埋的罗马城市遗址（包括当地导游的门票和参观）。之后，我们参观那不勒斯，这是意大利最迷人的城市之一，也是欧洲最古老和持续有人居住的城市之一。那不勒斯的历史中心是欧洲最大之一，并被联合国教科文组织列为世界遗产。漫步和午餐时间。继续前往卡西诺，您可以参观二战期间最艰难战役之一发生的巨大修道院。晚上抵达罗马。"
							},
							{
								name: "11 - 星期一. 罗马",
								description: "早餐后，服务结束。"
							}
						],
						services_included: "配有西班牙语和葡萄牙语导游的巴士之旅，基本旅行保险，酒店和自助早餐。\n" +
							"全景游览：阿格里真托、巴勒莫、庞贝。\n" +
							"转移：巴勒莫的蒙雷阿莱。\n" +
							"门票：阿格里真托神庙谷的入场券和参观，巴勒莫大教堂，蒙雷阿莱大教堂，庞贝。\n" +
							"渡轮：那不勒斯/卡普里/索伦托，圣乔瓦尼/墨西拿在西西里岛，巴勒莫/那不勒斯。\n" +
							"陶尔米纳：包含1顿午餐。\n" +
							"陶尔米纳、阿格里真托、巴勒莫：包含3顿晚餐"
					},
					{
						name: "西班牙与法国经典游",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 星期二 / 星期三 / 星期日. 巴塞罗那",
								description: "欢迎。接送到酒店，之后自由活动。下午，您将收到关于行程开始的信息，或者通过酒店接待处的宣传牌了解。"
							},
							{
								name: "02 - 星期三 / 星期四 / 星期一. 巴塞罗那 - 马德里",
								description: "早上，我们将安排一次巴塞罗那的全景游览。您将欣赏到这座充满活力的城市。自由漫步时间。13:00从巴塞罗那出发前往马德里。在高速公路服务区停留享用午餐。大约21:00抵达马德里。"
							},
							{
								name: "03 - 星期四 / 星期五 / 星期二. 马德里",
								description: "早上，我们安排了马德里市的全景游览，参观该市最具代表性的纪念碑。其余时间自由活动。您也可以选择参观历史悠久的托莱多城。"
							},
							{
								name: "04 - 星期五 / 星期六 / 星期三. 马德里 - 圣塞瓦斯蒂安 - 波尔多",
								description: "我们一大早从马德里出发，途经卡斯蒂利亚大地和巴斯克地区的美丽风景。中午时分抵达圣塞瓦斯蒂安。午餐和自由漫步时间，可以参观老城区或美丽的贝壳海滩。然后继续前往法国。下午晚些时候到达波尔多。我们建议在这个联合国教科文组织遗产城市的中心漫步。"
							},
							{
								name: "05 - 星期六 / 星期日 / 星期四. 波尔多 - 布卢瓦 - 巴黎",
								description: "穿越法国乡村，葡萄酒之地：波尔多、干邑。在卢瓦尔河谷，我们在布卢瓦停留，这是一座坐落在河边的城市，拥有一座城堡，是卢瓦尔河谷建筑的最佳代表之一。时间漫步和午餐。继续前往巴黎，我们在下午晚些时候到达。"
							},
							{
								name: "06 - 星期日 / 星期一 / 星期五. 巴黎",
								description: "早上，我们将前往凡尔赛宫，在那里您将有自由时间漫步其美丽的花园，或者如果您愿意，可以参观宫殿内部（对于周一的参观团，此次转移将改为周二，因为宫殿周一关闭）。午餐时间返回巴黎，然后前往蒙马特波西米亚区，这里有小餐馆和露天艺术家。午餐后，我们带领您进行一次巴黎的全景游览，由当地导游讲解。返回酒店。您还可以选择参加一场综艺表演。"
							},
							{
								name: "07 - 星期一 / 星期二 / 星期六. 巴黎",
								description: "巴黎自由日。您可以选择进行拉丁区的观光和塞纳河游船的可选活动。"
							},
							{
								name: "08 - 星期二 / 星期三 / 星期日. 巴黎",
								description: "早餐后，我们的服务结束。"
							}
						],
						services_included: "配有西班牙语和葡萄牙语导游的巴士之旅，基本旅行保险，酒店和大陆早餐。\n" +
							"接机服务。\n" +
							"全景游览：巴塞罗那、马德里、巴黎。\n" +
							"游览：凡尔赛（不包括宫殿门票）。\n" +
							"转移：巴黎的蒙马特区。\n"
					},
					{
						name: "甜美的法国之旅",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 星期三. 巴黎 - 吉维尼 - 鲁昂 - 阿罗芒什 - 卡昂",
								description: "清晨从巴黎出发，前往诺曼底。我们在吉维尼停留，参观莫奈的故居及其壮丽的花园（门票已包含）；我们将讨论印象派艺术，其中心在诺曼底。随后，我们到达诺曼底的首府鲁昂，漫步于中世纪街道和大教堂周围。您将有时间漫步和享用午餐。之后前往阿罗芒什，在其海滩上，我们缅怀第二次世界大战期间在法国的登陆行动。接着，我们继续前往卡昂，有时间在市中心漫步。 注意：从11月到3月，莫奈故居将关闭，故不在吉维尼停留。"
							},
							{
								name: "02 - 星期四. 卡昂 - 圣米歇尔山 - 圣马洛 - 迪南 - 雷恩",
								description: "一大早，我们出发前往被称为“奇迹”的圣米歇尔山。您将有时间参观被海水环绕的城市和修道院。随后，我们前往布列塔尼的圣马洛，其坚固的城墙保卫着这座城市。您将有时间享用午餐。下午，我们将在充满魅力的布列塔尼小镇迪南漫步。继续前往布列塔尼的首府雷恩，这里有着令人印象深刻的历史中心。"
							},
							{
								name: "03 - 星期五. 雷恩 - 昂热 - 图尔 - 维朗德里 - 昂布瓦斯",
								description: "我们出发前往昂热；有兴趣的游客可以参观保存着最大《启示录》中世纪挂毯的城堡。然后，我们前往卢瓦尔河谷：图尔，卢瓦尔河岸的区域首府。您将有时间漫步和享用午餐。下午，我们参观以花园闻名的维朗德里宫（花园门票已包含）。随后，前往昂布瓦斯，这是一座位于卢瓦尔河畔的中世纪风情小城。晚餐包含在内并住宿。"
							},
							{
								name: "04 - 星期六. 昂布瓦斯 - 舍农索 - 尚博尔 - 利摩日",
								description: "探索卢瓦尔河地区的深度之旅。清晨在昂布瓦斯漫步，前往克洛吕斯庄园，这里是达·芬奇度过生命最后三年的地方。我们将参观建于河上的舍农索城堡（门票已包含）。接着，参观卢瓦尔河最大的城堡尚博尔（公园门票已包含）。随后，我们前往法国中部的利摩日，一座因其瓷器闻名的城市。建议您在其历史中心漫步，那里以圣艾蒂安大教堂为特色。"
							},
							{
								name: "05 - 星期日. 利摩日 - 拉斯科 - 萨尔拉 - 贝纳克 - 洛卡马杜尔",
								description: "我们从利摩日出发，前往多尔多涅，这是法国中部另一条著名的历史河流。少有地区能拥有如此众多的迷人村庄和难忘的风景。我们到达拉斯科，参观博物馆和洞穴复制品（门票已包含），在这里我们将了解史前艺术及其宏伟的洞穴壁画。接着，我们参观被联合国教科文组织列为世界遗产的萨尔拉，这里保留了保存完好的文艺复兴时期历史中心。时间漫步和午餐。在贝纳克，我们参观一座俯瞰河流的中世纪城堡（门票已包含）。最后，我们来到洛卡马杜尔，这是一座美丽的中世纪村庄，其重要的圣殿“悬挂”在悬崖上；建议您步行走下十字架之路，并搭乘电梯返回。晚餐包含在内。"
							},
							{
								name: "06 - 星期一. 洛卡马杜尔 - 帕迪拉克 - 科隆热拉鲁日 - 圣埃米利永 - 波尔多",
								description: "我们前往令人印象深刻的帕迪拉克洞穴，乘船游览75米深的地下河流（门票和游船票已包含）。我们在建于红色石头的风景如画的小村庄科隆热拉鲁日停留，这是圣雅各之路上的一个村庄。午餐时间。午餐后，我们前往波尔多葡萄酒产区中心的圣埃米利永。时间漫步。下午晚些时候到达波尔多。 注意：从11月到次年3月底或因恶劣天气等原因，帕迪拉克洞穴可能关闭。"
							},
							{
								name: "07 - 星期二. 波尔多 - 巴黎",
								description: "波尔多的整个市中心被联合国教科文组织列为世界遗产。您会被“水镜”所震撼，并在老城区漫步，这里有着步行街和11世纪的大教堂。我们将安排参观（自助导览，提供西班牙语、法语或英语语音讲解器）波尔多葡萄酒城，这是一座令人印象深刻的互动博物馆，让您了解葡萄酒的历史及其酿造过程。在博物馆的观景台上，您还可以品尝到一杯葡萄酒。之后，您将有时间在加龙河岸边的餐馆区自由漫步和享用午餐。午餐后，大约13:00，我们将陪伴您前往火车站，乘坐现代化的高速列车。您将在短短两个多小时内完成500公里的路程，从波尔多抵达巴黎。抵达巴黎火车站并转乘至酒店。"
							},
							{
								name: "08 - 星期三. 巴黎",
								description: "早餐后，服务结束。"
							}
						],
						services_included: "配有西班牙语和葡萄牙语导游的巴士之旅，基本旅行保险，酒店和自助早餐。\n" +
							"游船：帕迪拉克洞穴。\n" +
							"门票：吉维尼的莫奈故居及花园，维朗德里宫花园，舍农索城堡，尚博尔城堡公园，拉斯科洞穴博物馆及复制品，贝纳克城堡，帕迪拉克洞穴，波尔多葡萄酒城。\n" +
							"高速列车：波尔多 - 巴黎（经济舱）。\n" +
							"包含2顿晚餐：昂布瓦斯，洛卡马杜尔。\n"
					},
					{
						name: "西班牙北部和法国之旅",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 星期二. 巴塞罗那 - 赫罗纳 - 贝萨卢 - 科利尤尔 - 卡尔卡松",
								description: "欢迎参加Europamundo之旅！我们前往加泰罗尼亚北部。赫罗纳，这座城市以其壮丽的历史街区为特色，中世纪建筑以及罗马、阿拉伯和犹太人的遗迹。我们建议参观位于城市上部的圣玛丽亚大教堂，这是一座令人印象深刻的哥特式建筑，以及古老的犹太区，那里的街道狭窄而美丽。接着，我们前往小型中世纪城市贝萨卢；午餐后有时间在其街道上漫步，这里有令人印象深刻的石头防御桥、城堡、犹太浴场和旧朝圣者医院。午餐后，我们继续前往法国加泰罗尼亚。科利尤尔是一个充满魅力的海滨小镇，有着美丽的海滩、港口、街道以及建在古罗马兵营遗址上的令人印象深刻的城堡，曾是马略卡国王的宫廷。如果您愿意，可以参观诗人安东尼奥·马查多的墓地。下午晚些时候，我们到达卡尔卡松，被认为是欧洲最好的城墙城市。我们建议晚上去看看被照亮的城墙，并在“La cité”的数百家小餐馆之一用餐。"
							},
							{
								name: "02 - 星期三. 卡尔卡松 - 纳博讷 - 马赛",
								description: "在卡尔卡松的早晨，我们与导游一起参观其令人印象深刻的孔代尔城堡（包括门票）及其中世纪城墙。随后有自由时间漫步。午餐后，我们将继续前往纳博讷，在那里我们将有时间探索这个具有历史意义的奥克西塔尼亚城市及其迷人的历史中心。下午晚些时候，我们到达法国的第二大城市马赛；我们建议您在其充满活力的老港区漫步，这里有许多小餐馆。"
							},
							{
								name: "03 - 星期四. 马赛 - 阿尔勒 - 莱博城 - 阿维尼翁 - 里昂",
								description: "村庄、风景、历史：今天我们将参观普罗旺斯一些最美丽的地方。阿尔勒，一座世界遗产城市，展现了其罗马和中世纪时期的遗产，让我们想起梵高。在莱博城，我们有机会参观这个小中世纪小镇，并参观卢米埃尔采石场，这是一个令人印象深刻的地下艺术、图像和声音秀，位于古老的采石场中。午餐和漫步时间在阿维尼翁，有着巨大的教皇宫。我们继续前往里昂，在那里我们可以漫步于被联合国教科文组织列为世界遗产的老城区，并包括乘坐缆车登上富维耶圣母院，这是一座巨大的圣殿，俯瞰城市的壮观景色。 注意：如果由于关闭或人群过多无法获得卢米埃尔采石场的门票，将作为替代安排前往庞特加德罗马高架渡槽。"
							},
							{
								name: "04 - 星期五. 里昂 - 图尔努斯 - 博纳 - 萨维尼城堡 - 第戎",
								description: "我们将离开里昂前往勃艮第。我们在图尔努斯停留，这是一座宜人的小镇，有着巨大的石门和宏伟的修道院。然后我们前往博纳。在勃艮第葡萄酒之都，您将有时间漫步和享用午餐。您可以参观葡萄酒博物馆或了解历史悠久的医院。之后，我们参观位于这个葡萄酒产区中心的萨维尼城堡，建于15世纪，里面有一些非凡的博物馆（摩托车、赛车、飞机和直升机博物馆）。继续前往第戎，勃艮第的首府，有着热闹的街道和众多中世纪和文艺复兴时期建筑组成的壮丽城市景观，其中以公爵宫殿为突出。"
							},
							{
								name: "05 - 星期六. 第戎 - 贝桑松 - 科尔马 - 里克威尔 - 里博维莱 - 斯特拉斯堡",
								description: "我们仍在勃艮第地区，在贝桑松停留，这是一座具有罗马起源的城市，有着美丽的历史中心和17世纪的城堡，俯瞰着这座城市。我们继续前往阿尔萨斯，这是一个长期以来在法国和德国之间争夺的法国地区，保留着自己的德国语言方言“阿尔萨斯语”，以及具有强烈德国传统的建筑和风俗。在科尔马停留，这是一个具有独特建筑风格的城市，有着彩色木结构房屋和穿越城市的运河。它以其白葡萄酒和美食而闻名。我们将有时间悠闲地漫步和享用午餐。下午，我们参观阿尔萨斯最美丽的两个村庄：里克威尔，一个中世纪村庄，鹅卵石铺成的街道和彩色的房屋，看起来像是童话中的场景；以及里博维莱，它有着历史悠久的中心和俯瞰这个小镇的废墟城堡。下午晚些时候到达斯特拉斯堡。我们建议在大教堂区和小法兰西区漫步，这里充满了迷人的地方和小餐馆。"
							},
							{
								name: "06 - 星期日. 斯特拉斯堡 - 弗赖堡 - 特里贝格 - 根根巴赫 - 巴登巴登",
								description: "在斯特拉斯堡度过一些时光，这是阿尔萨斯的首府。该市是欧洲议会的所在地。其历史中心非常美丽，有着风景如画的中世纪街道，令人印象深刻的哥特式大教堂，运河和鲜花。我们将包括乘船游览小法兰西的运河。在早上晚些时候，我们前往德国，探索黑森林的美丽山地景观和村庄。在弗赖堡，我们有时间享用午餐并在这座大学城的中世纪中心及其大教堂漫步。在非常美丽的风景中，我们继续前往特里贝格，这是黑森林中最具风景的村庄之一，以其布谷鸟钟而闻名。我们将包括参观特里贝格瀑布，这是德国最高的瀑布，沿着美丽的自然小径进入。我们继续前往根根巴赫，这是一个有着美丽中央广场的小镇。在巴登巴登住宿，这是一座吸引了来自欧洲各地贵族和富裕阶层的温泉城市；其赌场、建筑和公园提醒我们这一点。"
							},
							{
								name: "07 - 星期一. 巴登巴登 - 施派尔 - 海德堡 - 法兰克福",
								description: "在巴登巴登享受一些时光，在前往莱茵兰-普法尔茨州之前，您可以在其公园里漫步。在施派尔，这座城市位于莱茵河岸边，其伟大的罗马式大教堂被列为世界遗产；您可以参观它并在老城区漫步，其中的阿尔特穆恩策塔尤为引人注目。我们继续前往海德堡，乘坐缆车（包括门票）登上其令人印象深刻的城堡（包括进入城堡外部公园、酒窖和药房博物馆），在那里可以俯瞰这座城市的壮丽景色。之后，您将有时间在马克特广场漫步和享用午餐。下午晚些时候，我们到达法兰克福，这是一座大型金融城市，有着现代摩天大楼、老城区、莱茵河上的景色以及其闻名的夜生活。"
							},
							{
								name: "08 - 星期二. 法兰克福 - 卢森堡 - 兰斯 - 巴黎",
								description: " 今天我们穿越卢森堡大公国前往法国，这是一个小型国家，使用德语（卢森堡方言）和法语。到达这个结合了丰富历史和作为金融中心的现代性以及欧洲机构所在地的国际化城市。除了被列为世界遗产的老城区外，卢森堡大公宫和圣母大教堂的防御工事也很突出。午餐后，我们前往法国。在香槟产区的中心，我们在兰斯停留；有时间参观其令人印象深刻的哥特式大教堂，这是许多法国国王加冕的地方。傍晚时分到达巴黎。"
							},
							{
								name: "09 - 星期三. 巴黎",
								description: "早餐后，我们的服务结束。"
							}
						],
						services_included: "配有西班牙语和葡萄牙语导游的巴士之旅，基本旅行保险，酒店和自助早餐。\n" +
							"游船：在斯特拉斯堡的小法兰西区。\n" +
							"门票：卡尔卡松城堡，莱博城的卢米埃尔采石场，萨维尼中世纪城堡，特里贝格瀑布，海德堡城堡（包括药房和酒窖）。\n" +
							"缆车：里昂，海德堡。\n"
					},
					{
						name: "英国和法国之旅",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 星期二 / 星期六. 巴黎",
								description: "欢迎光临。接机后前往酒店，随后自由活动。下午您将收到关于行程开始的相关信息，也可以通过酒店前台的通知板了解。"
							},
							{
								name: "02 - 星期三 / 星期日. 巴黎",
								description: "我们参观法国宏伟的凡尔赛宫，以及其美丽的花园（门票已包含）。之后返回巴黎，我们前往蒙马特区，并乘坐（包含）缆车上山，这个波希米亚风格的街区因其画家、艺术家和歌舞表演而闻名。午餐包含在内。午餐后，跟随当地导游进行城市全景游，了解巴黎的壮丽大道和地标。游览结束后返回酒店。晚上可选择观看一场歌舞秀（自费）。"
							},
							{
								name: "03 - 星期四 / 星期一. 巴黎 - 鲁昂",
								description: "前往埃菲尔铁塔，我们已包含登上这个宏伟的铁制纪念碑的二层门票。之后，我们将在塞纳河上进行约一个小时十五分钟的游船之旅。之后有自由活动时间。下午4点左右，我们从巴黎出发前往诺曼底。到达鲁昂后，有时间在这个诺曼底首府的历史中心漫步，这是一个充满活力的城市，其起源可追溯到罗马时代，拥有迷人的中世纪建筑和令人印象深刻的哥特式大教堂。 注意：有时埃菲尔铁塔的排队时间较长，可能会根据组织安排在不同时间（或周日）登塔。"
							},
							{
								name: "04 - 星期五 / 星期二. 鲁昂 - 坎特伯雷 - 伦敦",
								description: "在鲁昂市中心散步。之后，我们前往法国北部的加来，在那里我们乘坐现代化的渡轮穿越英吉利海峡（包含午餐）。到达英格兰后，我们在坎特伯雷停留，这里是英国的宗教首都，拥有非常迷人的历史中心。到达伦敦后，在前往酒店之前，您将在塔桥区有自由时间，这是伦敦的象征之一。接着送至酒店并住宿。"
							},
							{
								name: "05 - 星期六 / 星期三. 伦敦",
								description: "我们将探索伦敦。我们安排了当地导游的全景游览，以了解伦敦的历史、议会区、泰晤士河及其充满活力的街道。我们将在白金汉宫附近停留，观看著名的“换岗仪式”（在举行的日子）。黄昏时分，我们将在皮卡迪利集合，步行参观莱斯特广场、中国城和苏活区，体验其独特的氛围、剧院和夜生活。晚餐包含在内，之后返回酒店。"
							},
							{
								name: "06 - 星期日 / 星期四. 伦敦 - 剑桥 - 约克 - 达勒姆",
								description: "我们从伦敦出发前往英格兰北部。在剑桥，我们有时间欣赏其美丽的学院。午餐时间后，我们继续前往约克，其大教堂、氛围、罗马起源的城墙及其繁忙的购物街道将给您留下非常愉快的记忆。之后我们继续向北。到达达勒姆，这是一座充满活力的小型中世纪城市，大教堂和城堡相对而立。"
							},
							{
								name: "07 - 星期一 / 星期五. 达勒姆 - 阿尼克 - 爱丁堡",
								description: "我们前往苏格兰。我们在阿尼克停留，这是一座风景如画的城市，有美丽的花园和大型中世纪城堡，部分《哈利波特》电影就是在这里拍摄的。随后我们继续前往爱丁堡，中午抵达。我们来到了苏格兰的首府，也是北欧最活跃的城市之一；其纪念性中心已被联合国教科文组织列为世界遗产；城堡俯瞰着城市，其陡峭的街道和公园。包含当地导游的全景游览。"
							},
							{
								name: "08 - 星期二 / 星期六. 爱丁堡 - 皮特洛赫里 - 因弗内斯 - 尼斯湖 - 格拉斯哥",
								description: "今天我们将体验苏格兰北部的壮丽风景（苏格兰高地）。在充满魅力的皮特洛赫里小镇散步和喝咖啡。经过苏格兰北部的因弗内斯，这里非常靠近神秘的尼斯湖。午餐后，我们参观厄克特城堡（包含门票），之后乘船游览尼斯湖的深水。我们还将经过奥古斯都堡及其船闸系统和位于本尼维斯山脚下的旅游城市威廉堡，本尼维斯是英国的最高峰。我们将穿越高原返回南部，这里常年可见积雪，然后绕过洛蒙德湖。晚上到达格拉斯哥，在市中心稍作漫步后住宿。 非常重要的注意事项：11月15日至2月底期间到达爱丁堡的团队，由于日照时间短且道路上常有积雪问题，本行程将按以下方式进行： 从爱丁堡出发前往皮特洛赫里，这是苏格兰中心一个风景如画的小镇，以威士忌生产而闻名。我们将在该地区参观一个传统的威士忌酿酒厂（门票已包含）。随后前往斯特灵，这里有着令人印象深刻的历史中心，由大型城堡主宰，这是苏格兰最重要的城堡之一，曾见证许多历史事件，包括战役和几位国王的加冕。预订和门票已包含。午餐后，我们停下来拍摄凯尔派斯，这两座巨大的水马雕塑是苏格兰神话中的象征。然后继续前往格拉斯哥，在市中心漫步并入住。"
							},
							{
								name: "09 - 星期三 / 星期日. 格拉斯哥",
								description: "早餐后，我们的服务结束。"
							}
						],
						notes: "非常重要的注意事项：11月15日至2月底期间到达爱丁堡的团队，由于日照时间短且道路上常有积雪问题，本行程将按以下方式进行：\n" +
							"从爱丁堡出发前往皮特洛赫里，这是苏格兰中心一个风景如画的小镇，以威士忌生产而闻名。我们将在该地区参观一个传统的威士忌酿酒厂（门票已包含）。随后前往斯特灵，这里有着令人印象深刻的历史中心，由大型城堡主宰，这是苏格兰最重要的城堡之一，曾见证许多历史事件，包括战役和几位国王的加冕。预订和门票已包含。午餐后，我们停下来拍摄凯尔派斯，这两座巨大的水马雕塑是苏格兰神话中的象征。然后继续前往格拉斯哥，在市中心漫步并入住。",
						services_included: "配有西班牙语和葡萄牙语导游的巴士之旅，基本旅行保险，酒店和自助早餐。\n" +
							"包含接机服务。\n" +
							"全景游览：巴黎、伦敦、爱丁堡。\n" +
							"游览：巴黎的凡尔赛宫。\n" +
							"游船：巴黎的塞纳河游船，尼斯湖游船。\n" +
							"夜间接送：伦敦塔桥，伦敦苏活区。\n" +
							"接送：巴黎蒙马特区，伦敦大英博物馆/卡姆登。\n" +
							"门票：巴黎的凡尔赛宫和埃菲尔铁塔，厄克特城堡。\n" +
							"渡轮：加来（法国）- 多佛（英国）。\n" +
							"缆车：巴黎蒙马特区。\n" +
							"包含2次午餐：巴黎，英吉利海峡。\n" +
							"包含1次晚餐：伦敦。\n"
					}
				]
			},
			{
				name: "-",
				data: [
					{
						name: "中文",
						duration: "---FALTA---",
						days: [
							{
								name: "第1天 - 星期六. 马德里",
								description: "欢迎来到马德里！接机前往酒店。 下午，我们将进行一个城市全景游览，参观城市中最具代表性的纪念碑。晚上，我们将安排到马约尔广场的接送，您可以在这里的传统酒馆享用晚餐和葡萄酒。"
							},
							{
								name: "第2天 - 星期日. 马德里 - 托莱多 - 马德里",
								description: "我们将安排一次到历史名城托莱多的游览，这座城市被塔霍河环绕。其中世纪街道讲述着基督徒、犹太人和穆斯林的故事。下午在马德里自由活动。今晚，如果您愿意，我们可以安排一个可选的弗拉门戈表演。"
							},
							{
								name: "第3天 - 星期一. 马德里 - 萨拉戈萨 - 巴塞罗那",
								description: "我们穿越卡斯蒂利亚的土地前往阿拉贡。在萨拉戈萨短暂停留，参观皮拉尔圣母大教堂。抵达巴塞罗那后，乘坐巴士进行短暂的全景游览，熟悉这座面向地中海的加泰罗尼亚首都，因高迪及其现代主义建筑、兰布拉大道及曾经举办的世界博览会和奥运会的记忆而闻名。 注： 有时，游览可能会在次日早晨进行。"
							},
							{
								name: "第4天 - 星期二. 巴塞罗那 - 法国蔚蓝海岸",
								description: "在巴塞罗那自由活动，您可以漫步于哥特区、兰布拉大道，参观大教堂。中午12点，我们出发前往法国和蔚蓝海岸，穿越法国普罗旺斯。途中停留午餐。傍晚抵达尼斯。 注： 某些日期，住宿可能在尼斯附近的一个区域。"
							},
							{
								name: "第5天 - 星期三. 蔚蓝海岸 - 摩纳哥 - 威尼斯",
								description: "今天早上，我们游览蔚蓝海岸；我们将在尼斯稍作停留，享受英国人漫步道的美景。我们在壮丽的景色中穿越，前往独立的摩纳哥公国；在路上，我们了解传统香水制作工艺。然后，我们沿着意大利里维埃拉继续行驶，在阿尔卑斯山与地中海的交汇处欣赏壮丽景色。傍晚抵达威尼斯。住宿在梅斯特雷地区。"
							},
							{
								name: "第6天 - 星期四. 威尼斯",
								description: "早上，我们将安排乘船前往威尼斯的圣马可广场地区，这是一座由100多个岛屿组成的城市，这些岛屿通过400多座桥梁连接。我们将安排当地导游步行带您游览这座水城的区域。下午自由活动。如果您愿意，您可以享受一次乘坐贡多拉的体验。"
							},
							{
								name: "第7天 - 星期五. 威尼斯 - 维罗纳 - 加尔达湖 - 因斯布鲁克",
								description: "我们离开威尼斯前往维罗纳，在那里我们有时间参观罗密欧与朱丽叶的城市及其重要的罗马圆形剧场。之后，我们沿着加尔达湖岸边行驶，这是阿尔卑斯山最美丽的湖泊之一。我们将在美丽的村庄马尔切西内停留，从那里乘船到达利莫内，一个被壮丽的山脉景色包围的风景如画的小村庄。然后，我们前往奥地利。下午中段抵达因斯布鲁克，并有时间探索其美丽的历史中心。"
							},
							{
								name: "第8天 - 星期六. 因斯布鲁克 - 慕尼黑 - 雷根斯堡 - 布拉格",
								description: "早餐后，我们前往德国巴伐利亚地区。我们在慕尼黑停留，巴伐利亚的首都，以其巴洛克建筑、传统啤酒馆和美丽的公园而闻名。建议您参观玛丽恩广场（城市主广场）和大教堂。午餐时间。之后，我们前往雷根斯堡，这是一座位于多瑙河畔的小镇，其中世纪中心被列为世界文化遗产。时间漫步。然后，我们前往捷克共和国。傍晚抵达布拉格。"
							},
							{
								name: "第9天 - 星期日. 布拉格",
								description: "早上，我们将安排布拉格的全景游览，这座城市是欧洲最美丽的城市之一，亮点包括城堡、市政厅广场和壮观的查理大桥。我们在城市中心安排一个夜间接送，您可以在这里的许多传统啤酒馆享用晚餐。"
							},
							{
								name: "第10天 - 星期一. 布拉格 - 莱德尼采 - 杰尔 - 布达佩斯",
								description: "我们清晨从布拉格出发。在摩拉维亚地区，我们参观莱德尼采，这座城市被联合国教科文组织列为世界遗产。时间参观其花园并欣赏其城堡。穿越斯洛伐克后，我们进入匈牙利。杰尔，午餐时间并漫步四河之城。之后前往布达佩斯，下午中段抵达。晚上，我们将安排到步行街区的接送服务，这里有许多小型传统餐馆，许多都有音乐。"
							},
							{
								name: "第11天 - 星期二. 布达佩斯",
								description: "我们将安排一次横跨多瑙河的全景游览。我们将参观布达的老城区和佩斯的购物区。下午自由活动，享受这座多瑙河之城的魅力。"
							},
							{
								name: "第12天 - 星期三. 布达佩斯 - 布拉迪斯拉发 - 维也纳",
								description: "清晨从布达佩斯出发。我们前往布拉迪斯拉发，斯洛伐克的首都，这是一座沿着多瑙河的城市，拥有迷人的历史中心。时间参观城市并享用午餐。午餐后，我们继续前往维也纳。到达后，我们将安排一次当地导游带领的维也纳全景游览。晚上，我们将安排到市政厅广场的接送服务。"
							},
							{
								name: "第13天 - 星期四. 维也纳",
								description: "在奥地利首都自由活动。您可以自由地享受这座美丽城市的魅力。时间漫步并发现难忘的地方。"
							},
							{
								name: "第14天 - 星期五. 维也纳",
								description: "早餐后，行程结束。"
							}
						],
						services_included: "提供西班牙语和葡萄牙语导游的长途汽车旅行、基础旅行保险、酒店和自助早餐。\n" +
							"接机服务。\n" +
							"全景游览：马德里、巴塞罗那、威尼斯、布拉格、布达佩斯、维也纳。\n" +
							"游览：托莱多在马德里。\n" +
							"船：特隆凯托/圣马可广场/特隆凯托在威尼斯，马尔切西内/利莫内/马尔切西内在加尔达湖。\n" +
							"夜间接送：马德里的马约尔广场，布拉格的历史中心，布达佩斯的历史中心，维也纳的市政厅广场。\n" +
							"门票：威尼斯的穆拉诺玻璃工厂。\n"
					},
					{
						name: "欧洲之美",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 周五. 维也纳。",
								description: "欢迎光临。接机后前往酒店，之后自由活动。下午，您将在酒店接待处的公告板上收到有关旅行开始的信息。"
							},
							{
								name: "02 - 周六. 维也纳。",
								description: "我们安排了一次由当地导游带领的城市全景游览。您将会看到维也纳的宏伟大道、美丽的宫殿、大型公园和历史中心。下午自由活动。晚上，我们将安排前往格林青区的交通，这里是一个并入城市的古老葡萄酒村，以其传统酒馆而闻名。在其中一家酒馆享用晚餐（包含）。"
							},
							{
								name: "03 - 周日. 维也纳 - 布达佩斯。",
								description: "经过大约3小时的行程，我们到达匈牙利首都布达佩斯。我们首先前往热闹的瓦茨街区。午餐包含。随后，我们乘坐游船在多瑙河上游览城市，欣赏河岸上的“布达”古城和对岸的“佩斯”现代城的壮丽景色。之后，我们将开始被誉为“多瑙河之王”的城市全景游览。自由活动时间。晚上可以选择参加一个包括晚餐的马扎尔表演（可选）。"
							},
							{
								name: "04 - 周一. 布达佩斯 - 布拉迪斯拉发 - 布拉格。",
								description: "我们一早从布达佩斯出发前往布拉迪斯拉发，斯洛伐克的首都，这是一座位于多瑙河畔、具有吸引力的历史中心的城市。自由时间游览城市并享用午餐。之后，我们继续前往捷克共和国，抵达布拉格。傍晚，我们将前往老城区广场区域，这是一个充满活力和游客活动的地方，是了解布拉格的绝佳地点。晚餐包含。"
							},
							{
								name: "05 - 周二. 布拉格。",
								description: "今天我们将探索这座欧洲巴洛克风格的首都。我们将进行一次由当地导游带领的全景游览，导游将向我们介绍这座城市并展示其主要的纪念碑；在游览期间，我们将参观布拉格城堡（包含门票）。之后，我们安排了一次在伏尔塔瓦河上的精彩游船。午餐包含。下午自由活动。"
							},
							{
								name: "06 - 周三. 布拉格 - 慕尼黑 - 因斯布鲁克。",
								description: "我们一早出发，前往德国巴伐利亚州。中午抵达慕尼黑，有时间在其历史中心漫步并享用午餐。下午，我们前往奥地利，抵达因斯布鲁克。在这座阿尔卑斯山心脏地带的蒂罗尔首府漫步。今晚，我们将安排一场典型的晚餐和充满色彩、节奏与欢乐的蒂罗尔民俗表演。"
							},
							{
								name: "07 - 周四. 因斯布鲁克 - 威尼斯。",
								description: "穿越美丽的阿尔卑斯山景观，我们前往意大利。在威尼斯，我们安排了一次船只接送到圣马可广场区域，并在那里进行了一次步行导游游览这座运河之城。您还可以欣赏穆拉诺玻璃制造工艺。午餐包含。之后自由活动——或许您可以体验一次贡多拉之旅？住宿在梅斯特雷地区。"
							},
							{
								name: "08 - 周五. 威尼斯 - 卢加诺 - 卢塞恩 - 苏黎世。",
								description: "我们沿着波河谷出发，前往瑞士，进入阿尔卑斯湖区的美丽风景。到达卢加诺，您可以在瑞士意大利语区的首府湖畔漫步。午餐后，我们穿越圣哥达隧道到达阿尔卑斯山。下午中时抵达卢塞恩。在“狮子纪念碑”旁停留，并在这座美丽的瑞士城市——四森林州湖的湖畔享有自由时间。傍晚到达苏黎世，沿湖散步。晚餐包含。"
							},
							{
								name: "09 - 周六. 苏黎世 - 斯特拉斯堡 - 巴黎。",
								description: "我们从苏黎世出发，前往法国阿尔萨斯地区，参观其首府斯特拉斯堡，这是一座美丽的城市，结合了法国和德国的影响。自由时间游览这座城市。午餐包含。然后，我们继续前往巴黎，当天结束时到达。"
							},
							{
								name: "10 - 周日. 巴黎。",
								description: "参观凡尔赛宫（包含门票）及其美丽的花园。之后，我们返回巴黎，前往蒙马特区，乘坐缆车（包含）上山，欣赏这个以画家、艺术家和夜总会而闻名的波西米亚区。午餐包含，随后开始与当地导游的城市全景游览，了解其令人惊叹的街道和纪念碑。返回酒店。您还可以选择参加一场歌舞表演（可选）。"
							},
							{
								name: "11 - 周一. 巴黎 - 鲁昂。",
								description: "我们前往埃菲尔铁塔，包括登上这座令人印象深刻的金属建筑第二层的门票。之后，我们在塞纳河上航行，大约一小时十五分钟的小型游船。之后是自由时间。下午4点左右，我们离开巴黎前往诺曼底。在鲁昂，有自由时间在这座拥有悠久历史、始于罗马时期的城市的历史中心漫步，这里拥有迷人的中世纪建筑和令人印象深刻的哥特式大教堂。"
							},
							{
								name: "12 - 周二. 鲁昂 - 坎特伯雷 - 伦敦。",
								description: "在鲁昂市中心漫步的自由时间。之后，我们前往法国北部的加来，搭乘现代化的渡轮穿越英吉利海峡。午餐包含。抵达英国后，我们在坎特伯雷停留，这里是英国的宗教首都，拥有非常吸引人的历史中心。到达伦敦后，在前往酒店之前，您将在塔桥区域自由活动，这里是伦敦的标志性象征之一。接送至酒店并入住。"
							},
							{
								name: "13 - 周三. 伦敦。",
								description: "我们将了解伦敦。我们安排了一次由当地导游带领的全景游览，了解其历史、议会区域、泰晤士河以及充满活力的街道。我们将在白金汉宫旁停留，观看著名的“卫兵换岗”（在举行换岗的日子）。傍晚时分，我们将在皮卡迪利的集合点会合，步行游览莱斯特广场、唐人街和苏活区，体验其氛围、剧院和娱乐。晚餐包含，之后返回酒店。"
							},
							{
								name: "14 - 周四. 伦敦。",
								description: "上午，我们参观大英博物馆，这是世界上主要和最古老的博物馆之一，在这里可以看到来自世界各地文化的遗迹。午餐时，我们将前往卡姆登区，这里以其商店和市场而闻名，您可以看到来自世界各地的文化物品。下午自由活动。 行程结束。"
							}
						],
						services_included: "包含西班牙语和葡萄牙语导游的巴士之旅、基本旅行保险、酒店住宿和自助早餐。\n" +
							"包括接机服务。\n" +
							"全景游览城市：维也纳、布达佩斯、布拉格、威尼斯、巴黎、伦敦。\n" +
							"游览：巴黎的凡尔赛宫。\n" +
							"游船：布达佩斯的多瑙河、布拉格的伏尔塔瓦河、威尼斯的Tronchetto/圣马可广场/Tronchetto、巴黎的塞纳河。\n" +
							"夜间接送：维也纳的格林青区、布拉格的历史中心、伦敦的塔桥和苏活区。\n" +
							"包括的接送服务：巴黎的蒙马特区，伦敦的大英博物馆/卡姆登。\n" +
							"包括的门票：布拉格城堡、蒂罗尔表演、威尼斯的穆拉诺玻璃工厂、巴黎的凡尔赛宫和埃菲尔铁塔。\n" +
							"渡轮：加来（法国）-多佛（英国）。\n" +
							"缆车：巴黎的蒙马特。\n" +
							"6次包含的午餐：布达佩斯、布拉格、威尼斯、斯特拉斯堡、巴黎、英吉利海峡。\n" +
							"5次包含的晚餐：维也纳、布拉格、因斯布鲁克、苏黎世、伦敦。\n"
					},
					{
						name: "童话之旅",
						duration: "---FALTA---",
						days: [
							{
								name: "01 周四. 罗马。",
								description: "欢迎光临。接机后前往酒店，之后自由活动。"
							},
							{
								name: "02 周五. 罗马。",
								description: "意大利首都全景游览，最后到达梵蒂冈圣彼得广场。晚上前往特拉斯提弗列区。"
							},
							{
								name: "03 周六. 罗马。",
								description: "自由活动日。"
							},
							{
								name: "04 周一. 罗马 - 佛罗伦萨。",
								description: "我们前往托斯卡纳。在佛罗伦萨，我们会在米开朗琪罗瞭望台停留，欣赏这座城市的美丽景色，然后进行全景游览。下午自由活动。"
							},
							{
								name: "05 周二. 佛罗伦萨 - 威尼斯。",
								description: "我们离开佛罗伦萨向北出发，穿越亚平宁山脉。乘船前往威尼斯市中心，步行游览这座水城，您还可以观赏传统的玻璃吹制工艺。住宿在梅斯特雷。"
							},
							{
								name: "06 周三. 威尼斯 - 卢加诺 - 苏黎世。",
								description: "今天我们将在瑞士欣赏壮丽的自然风光，穿越湖泊和阿尔卑斯山之间的小镇。途经卢加诺和卢塞恩。傍晚抵达苏黎世。"
							},
							{
								name: "07 周四. 苏黎世 - 巴塞尔 - 科尔马 - 里克威尔 - 里博维莱 - 斯特拉斯堡。",
								description: "在瑞士的莱茵河边享用午餐，这里是德国和法国的历史边界。我们参观巴塞尔，欣赏其美丽的历史中心和大教堂。进入法国后，我们将探索阿尔萨斯地区的核心，这里有其独特的个性和深厚的日耳曼传统。我们将停在美丽的里克威尔、里博维莱和首府斯特拉斯堡。"
							},
							{
								name: "08 周五. 斯特拉斯堡 - 弗赖堡 - 特里贝格 - 根根巴赫 - 巴登巴登。",
								description: "我们将乘船游览斯特拉斯堡的“小法兰西”运河。然后深入德国的黑森林及其精心维护的小镇：弗赖堡、特里贝格（我们将在此参观德国最高的瀑布）、根根巴赫，最后入住巴登巴登温泉小镇。"
							},
							{
								name: "09 周六. 巴登巴登 - 施派尔 - 海德堡 - 法兰克福。",
								description: "我们将沿着莱茵河畔的葡萄园和中世纪小镇前往法兰克福。我们将停在以古老大教堂而闻名的施派尔。乘坐海德堡的缆车，从中欣赏壮观的景色，并参观城堡、酒窖和博物馆。下午抵达法兰克福。自由活动时间。"
							},
							{
								name: "10 周日. 法兰克福 - 游船 - 科赫姆 - 科隆 - 多特蒙德。",
								description: "今天我们将探索莱茵河和摩泽尔河谷。我们将乘船游览莱茵河最美丽的部分。在摩泽尔河畔的科赫姆享用午餐。沿河继续前往科隆，有时间参观其宏伟的标志性大教堂。住宿在多特蒙德。"
							},
							{
								name: "11 周一. 多特蒙德 - 童话之路 - 汉诺威。",
								description: "我们将踏上充满魔力的旅程，重温格林兄弟的童话故事，沿着童话之路前行。我们将经过马尔堡（参观拉普un策尔塔，门票已包含），穿过汉赛尔与格蕾特的森林，参观波勒的灰姑娘城堡，沿着吹笛人的足迹来到哈梅林。我们还将停在有古老修道院的小镇霍斯特。住宿在汉诺威。"
							},
							{
								name: "12 周二. 汉诺威 - 戈斯拉尔 - 奎德林堡 - 柏林。",
								description: "我们将探索德国最美丽的地区之一，以其中世纪的小镇而闻名，并停在戈斯拉尔和奎德林堡，这些都是典型的木结构房屋和美丽广场的历史小镇。下午抵达柏林。"
							},
							{
								name: "13 周三. 柏林。",
								description: "柏林首都全景游览，包括参观大屠杀纪念碑和柏林墙纪念博物馆。"
							}
						],
						services_included: "包括西班牙语和葡萄牙语导游的巴士旅行，基本旅行保险和自助早餐。\n" +
							"包括接机服务。\n" +
							"游船： 威尼斯的Tronchetto/圣马可广场/Tronchetto，莱茵河谷。\n" +
							"全景游览： 罗马、佛罗伦萨、威尼斯、柏林。\n" +
							"夜间接送： 罗马特拉斯提弗列区。\n" +
							"米开朗琪罗瞭望台： 佛罗伦萨。\n" +
							"门票： 威尼斯的穆拉诺玻璃工厂、特里贝格瀑布、海德堡城堡（包括药房和酒窖）、特伦德尔堡的拉普un策尔塔、大屠杀纪念碑、柏林墙博物馆。\n" +
							"缆车： 海德堡。"
					},
					{
						name: "大西洋欧洲之旅",
						duration: "---FALTA---",
						days: [
							{
								name: "01 周四. 圣米歇尔山 - 圣马洛 - 迪南 - 雷恩。",
								description: "我们在法国诺曼底上岸，前往法国奇观之一的岩石小岛圣米歇尔山。随后，我们进入布列塔尼，在城墙环绕的圣马洛停留，并在迪南漫步。晚上入住雷恩，这里的首府。"
							},
							{
								name: "02 周五. 雷恩 - 昂热 - 图尔 - 维朗德里 - 昂布瓦斯。",
								description: "今天，我们将探索卢瓦尔河谷，沿途有许多有趣的小镇和城堡。我们在昂热停留，这座城市位于河畔。然后继续前往图尔，地区的首府。下午，我们将参观维朗德里宫殿的花园，然后前往另一个河谷城市，昂布瓦斯住宿。晚餐包含。"
							},
							{
								name: "03 周六. 昂布瓦斯 - 舍农索 - 香波堡 - 巴黎。",
								description: "今天我们将更深入地了解卢瓦尔河谷。在昂布瓦斯漫步后，我们将进入舍农索城堡。然后前往河谷中最大的城堡香波堡。下午抵达巴黎。"
							},
							{
								name: "04 周日. 巴黎。",
								description: "早餐后，结束服务。"
							},
							{
								name: "05 周一. 巴黎 - 坎特伯雷 - 伦敦。",
								description: "我们前往法国北部，在那里我们乘坐渡轮穿越英吉利海峡进入英国。停靠坎特伯雷，这个地方在英国文化、历史和宗教上具有重要意义。继续前往伦敦。傍晚抵达。"
							},
							{
								name: "06 周二. 伦敦。",
								description: "早上伦敦全景游览，晚上在莱斯特广场、唐人街和苏荷区漫步。"
							},
							{
								name: "07 周三. 伦敦。",
								description: "自由一天，探索这座城市。"
							},
							{
								name: "08 周四. 伦敦 - 剑桥 - 约克 - 达勒姆。",
								description: "我们前往英格兰北部的美丽大学城剑桥。然后继续前往约克，在那里我们可以漫步于市中心并参观令人震撼的大教堂。我们将入住中世纪小城达勒姆。"
							},
							{
								name: "09 周五. 达勒姆 - 阿尼克 - 爱丁堡。",
								description: "早餐后，我们前往阿尼克，这里有一个风格像哈利·波特的城堡。然后进入苏格兰。在苏格兰首府爱丁堡，这座被联合国教科文组织列为世界遗产的城市，我们进行全景游览。安排住宿。"
							},
							{
								name: "10 周六. 爱丁堡 - 皮特洛赫里 - 因弗内斯 - 尼斯湖 - 格拉斯哥。",
								description: "今天，我们将在苏格兰北部进行一次激动人心的风景之旅。经过皮特洛赫里和因弗内斯湖泊后，我们到达神秘的尼斯湖。我们将参观中世纪的厄克特城堡，然后乘船游览尼斯湖的幽深水域。沿着湖泊和小山村的公路，我们最终抵达格拉斯哥。 由于天气原因，冬季路线有所变化，将在皮特洛赫里停留两次，参观威士忌酒厂，斯特林（含城堡门票）和凯尔比斯，然后到达格拉斯哥。"
							},
							{
								name: "11 周日. 格拉斯哥 - 凯恩瑞安 - 贝尔法斯特 - 都柏林。",
								description: "We head south to take a ferry to Northern Ireland. Time in Belfast, the capital of this country belonging to the United Kingdom, which reminds us of its difficult coexistence between Catholics and Protestants. Continue to the Republic of Ireland. Arrival in Dublin at the end of the day."
							},
							{
								name: "12 周一. 都柏林。",
								description: "都柏林全景游览。下午自由活动。"
							},
							{
								name: "13 周二. 都柏林 - 卡纳封 - 利物浦。",
								description: "早上，我们乘坐渡轮从爱尔兰到达英国。经过三个多小时的航程，我们抵达威尔士。我们将参观卡纳封，其宏伟的城堡，然后继续前往利物浦，这里是披头士的发源地。"
							},
							{
								name: "14 周三. 利物浦 - 埃文河畔斯特拉特福 - 牛津 - 朴茨茅斯。",
								description: "我们参观莎士比亚的故乡埃文河畔斯特拉特福。然后，我们前往牛津，这里的生活和最近的历史都围绕着其著名的大学。接着前往朴茨茅斯登船，前往法国。夜宿船上。"
							},
							{
								name: "15 周四. 圣米歇尔山 - 富热尔 - 沙特尔 - 巴黎。",
								description: "我们在法国诺曼底上岸，前往法国奇观之一的岩石小岛圣米歇尔山。在布列塔尼，我们将停靠在中世纪城市富热尔。随后，我们将参观世界遗产沙特尔的宏伟哥特式大教堂。继续前往巴黎，结束服务。"
							}
						],
						services_included: "包括西班牙语和葡萄牙语导游的巴士旅行，基本旅行保险和自助早餐。\n" +
							"游船： 在尼斯湖。\n" +
							"全景游览： 伦敦、爱丁堡、都柏林。\n" +
							"夜间接送： 伦敦的苏荷区。\n" +
							"门票： 厄克特城堡（尼斯湖）。\n" +
							"渡轮： 加来（法国）-多佛尔（英国），苏格兰 - 爱尔兰，爱尔兰 - 威尔士，朴茨茅斯（英国）-法国。",
						services_additional: "门票： 维朗德里宫花园、舍农索城堡、香波堡公园。\n" +
							"1次午餐或晚餐： 昂布瓦斯。"
					},
					{
						name: "现代欧洲",
						duration: "---FALTA---",
						days: [
							{
								name: "01 - 周一. 里斯本。",
								description: "欢迎光临。接机后前往酒店，之后自由活动。下午，您将在酒店接待处的公告板上收到有关旅行开始的信息。"
							},
							{
								name: "02 - 周二. 里斯本。",
								description: "上午，里斯本全景游览，这座葡萄牙的七丘之城，拥有狭窄的街道、瓷砖和大西洋的光芒。我们将参观贝伦区，这是15世纪葡萄牙航海家出发的地方。下午自由活动，可以探索上城区及其观景台。还可以选择自费游览辛特拉、卡斯凯什和埃斯托里尔。晚上，从恢复者广场返回酒店。"
							},
							{
								name: "03 - 周三. 里斯本 - 埃什特雷莫什 - 卡塞雷斯 - 马德里。",
								description: "早晨从里斯本出发前往马德里，途经阿连特茹地区，并在埃什特雷莫什停留。随后进入西班牙，参观卡塞雷斯，在那里您可以享用午餐并参观其历史中心。当天傍晚到达马德里。"
							},
							{
								name: "04 - 周四. 马德里。",
								description: "马德里全景游览，参观市中心、马约尔广场、大道和丽池公园。包括参观圣地亚哥伯纳乌球场。下午自由活动，晚上前往马约尔广场。含晚餐。"
							},
							{
								name: "05 - 周五. 马德里 - 圣塞瓦斯蒂安 - 波尔多。",
								description: "前往圣塞瓦斯蒂安，漫步于贝壳海滩。含午餐。然后进入法国，傍晚到达波尔多。建议游览市中心。"
							},
							{
								name: "06 - 周六. 波尔多 - 尚博尔 - 巴黎。",
								description: "穿越卢瓦尔河地区。参观尚博尔城堡（含门票）。含午餐。下午到达巴黎。"
							},
							{
								name: "07 - 周日. 巴黎。",
								description: "参观凡尔赛宫（含门票），随后乘坐缆车前往蒙马特区。含午餐，并与当地导游一起进行巴黎全景游览。返回酒店。可选择参加歌舞秀。"
							},
							{
								name: "08 - 周一. 巴黎 - 鲁昂。",
								description: "参观埃菲尔铁塔（含二层门票）并乘船游览塞纳河。然后前往鲁昂，探索其历史中心。由于组织原因，可能会重新安排参观埃菲尔铁塔的时间。"
							},
							{
								name: "09 - 周二. 鲁昂 - 坎特伯雷 - 伦敦。",
								description: "漫步于鲁昂，然后乘渡轮穿越英吉利海峡到达英国。参观坎特伯雷，随后抵达伦敦，并在塔桥区自由活动。入住酒店。"
							},
							{
								name: "10 - 周三. 伦敦。",
								description: "与当地导游一起进行伦敦全景游览，参观议会大厦、泰晤士河和白金汉宫。漫步于皮卡迪利广场、莱斯特广场、唐人街和苏活区。含晚餐。"
							},
							{
								name: "11 - 周四. 伦敦 - 科尔切斯特 - 渡轮。",
								description: "参观大英博物馆，然后前往卡姆登区。出发前往科尔切斯特，并搭乘渡轮进行夜间航行。含晚餐。."
							},
							{
								name: "12 - 周五. 渡轮 - 沃伦丹 - 阿姆斯特丹。",
								description: "抵达荷兰，参观沃伦丹，然后前往阿姆斯特丹，进行城市全景游览。前往伦勃朗广场。含晚餐。"
							},
							{
								name: "13 - 周六. 阿姆斯特丹 - 哈默林 - 柏林。",
								description: "从阿姆斯特丹前往柏林，途中在哈默林停留。含午餐。傍晚到达柏林。"
							},
							{
								name: "14 - 周日. 柏林。",
								description: "柏林导览游，包括历史中心、勃兰登堡门和大屠杀纪念馆。晚餐在坎特街区享用。"
							},
							{
								name: "15 - 周一. 柏林。",
								description: "早餐后结束行程。"
							}
						],
						services_included: "包含西班牙语和葡萄牙语导游的巴士之旅、基本旅行保险、酒店住宿和自助早餐。\n" +
							"包括接机服务。\n" +
							"全景游览城市：里斯本、马德里、巴黎、伦敦、阿姆斯特丹、柏林。\n" +
							"游览：巴黎的凡尔赛宫。\n" +
							"游船：巴黎的塞纳河游船。\n" +
							"夜间接送：里斯本的恢复者广场，马德里的马约尔广场，伦敦的塔桥和苏活区，阿姆斯特丹的伦勃朗广场，以及柏林的坎特街区。\n" +
							"包括的接送服务：马德里的丽池公园，巴黎的蒙马特区，伦敦的大英博物馆/卡姆登。\n" +
							"包括的门票：马德里的圣地亚哥伯纳乌球场，尚博尔城堡公园，巴黎的凡尔赛宫和埃菲尔铁塔，阿姆斯特丹的钻石工厂，柏林墙博物馆和大屠杀纪念馆。\n" +
							"渡轮：加来（法国） - 多佛（英国），哈里奇（英国） - 鹿特丹（荷兰）。\n" +
							"缆车：巴黎的蒙马特。\n" +
							"5次包含的午餐：圣塞瓦斯蒂安、尚博尔、巴黎、英吉利海峡、哈默林。\n" +
							"5次包含的晚餐：马德里、伦敦、渡轮科尔切斯特沃伦丹、阿姆斯特丹、柏林。\n",
					},
					{
						name: "北欧之珠",
						duration: "15天",
						days: [
							{
								name: "01 - 周二. 哥本哈根。",
								description: "欢迎光临。接机后前往酒店，之后自由活动。"
							},
							{
								name: "02 - 周三. 哥本哈根 - 罗斯托克 - 什切青。",
								description: "从斯堪的纳维亚南部的盖泽出发，我们乘坐渡轮横渡波罗的海前往德国。在罗斯托克自由活动，这里是波罗的海最大的德国港口，拥有美丽的市中心。我们继续前往波兰，在什切青过夜，这是一座位于奥得河边的历史悠久的大学城，拥有美丽的城堡。自由活动时间。"
							},
							{
								name: "03 - 周四. 什切青 - 比得哥什 - 托伦 - 华沙。",
								description: "穿越波兰西部的宁静地区，我们首先停在比得哥什，这是一座历史悠久的城市，连接着维斯瓦河和奥得河；其美丽的公园和运河非常适合散步。然后我们继续前往被列为世界遗产的哥特式城墙城市托伦。晚上到达波兰首都华沙。"
							},
							{
								name: "04 - 周五. 华沙。",
								description: "华沙全景游览，参观波兰首都。"
							},
							{
								name: "05 - 周六. 华沙 - 奥尔什丁内克 - 埃尔布隆格运河 - 马尔堡 - 格但斯克。",
								description: "我们将探索波兰北部。在奥尔什丁内克参观民俗建筑露天博物馆。然后前往埃尔布隆格运河及其博物馆。在马尔堡，参观令人印象深刻的条顿骑士团中世纪城堡。住宿在格但斯克，建议您在其美丽的历史中心漫步。"
							},
							{
								name: "06 - 周日. 格但斯克 - 索波特 - 渡轮。",
								description: "在格但斯克，我们将参观欧洲团结中心和新近开幕的二战博物馆，提醒我们这场战争对国家的可怕影响。随后，我们前往邻近的索波特，这是一座美丽的波罗的海度假胜地，并乘坐渡轮前往瑞典。享受旅程。"
							},
							{
								name: "07 - 周一. 渡轮 - 尼纳斯港 - 斯德哥尔摩。",
								description: "大约中午12点抵达瑞典尼纳斯港。继续前往斯德哥尔摩。午餐时间到达。下午自由活动，建议您在其历史中心漫步并参观博物馆。"
							},
							{
								name: "08 - 周二. 斯德哥尔摩。",
								description: "斯德哥尔摩全景游览，这座美丽的首都建在14个岛屿上。"
							},
							{
								name: "09 - 周三. 斯德哥尔摩 - 渡轮，夜宿船上。",
								description: "斯德哥尔摩自由活动一天。傍晚登船，乘坐渡轮夜航前往芬兰。"
							},
							{
								name: "10 - 周四. 图尔库 - 纳坦利 - 赫尔辛基。",
								description: "在渡轮甲板上欣赏芬兰海岸线，成百上千的岛屿映入眼帘。抵达图尔库，享有自由活动时间。随后在纳坦利停留，这是一个美丽的典型北欧村庄。继续前往赫尔辛基，下午自由活动。"
							},
							{
								name: "11 - 周五. 赫尔辛基。",
								description: "乘船前往苏奥缅林纳群岛，这里被堡垒保护，如今是联合国教科文组织的世界遗产。自由活动时间。"
							},
							{
								name: "11 - 周五. 赫尔辛基 - 塔林。",
								description: "游览完苏奥缅林纳群岛后，下午我们乘坐渡轮前往爱沙尼亚。在离开赫尔辛基时，您可以欣赏沿海和海洋风光。抵达爱沙尼亚首都塔林。"
							},
							{
								name: "12 - 周六. 塔林。",
								description: "塔林全景游览，参观爱沙尼亚首都。下午自由活动。"
							}
						],
						services_included: "包括西班牙语导游的巴士旅行，基本旅行保险和自助早餐。\n" +
							"包含：\n" +
							"接机服务。\n" +
							"游船：前往赫尔辛基的苏奥缅林纳群岛。\n" +
							"全景游览：华沙，斯德哥尔摩。\n" +
							"门票：奥尔什丁内克民俗建筑露天博物馆，埃尔布隆格运河博物馆，马尔堡城堡，格但斯克欧洲团结中心和二战博物馆。\n" +
							"渡轮：盖泽（丹麦）/ 罗斯托克（德国），索波特（波兰）/ 尼纳斯港（瑞典），斯德哥尔摩（瑞典）/ 图尔库（芬兰）。\n",
						services_additional: "额外服务：\n" +
							"游船：前往赫尔辛基的苏奥缅林纳群岛。\n" +
							"全景游览：塔林。\n" +
							"渡轮：赫尔辛基（芬兰）/ 塔林（爱沙尼亚）。\n" +
							"全景游览：里加。\n" +
							"接送：萨拉斯皮尔斯纪念馆。\n" +
							"门票：图赖达城堡。\n" +
							"1次午餐或晚餐：图赖达。\n"
					},
					{
						name: "奇幻斯堪的纳维亚",
						duration: "16天",
						days: [
							{
								name: "01 - 周四. 华沙。",
								description: "欢迎光临。接机后前往酒店，之后自由活动。"
							},
							{
								name: "02 - 周五. 华沙。",
								description: "华沙全景游览，参观波兰首都。"
							},
							{
								name: "03 - 周六. 华沙 - 奥尔什丁内克 - 埃尔布隆格运河 - 马尔堡 - 格但斯克。",
								description: "我们将探索波兰北部。在奥尔什丁内克参观民俗建筑露天博物馆。然后前往埃尔布隆格运河及其博物馆。在马尔堡，参观令人印象深刻的条顿骑士团中世纪城堡。住宿在格但斯克，建议您在其美丽的历史中心漫步。"
							},
							{
								name: "04 - 周日. 格但斯克 - 索波特 - 渡轮。",
								description: "在格但斯克，我们将参观欧洲团结中心和新近开幕的二战博物馆，提醒我们这场战争对国家的可怕影响。随后，我们前往邻近的索波特，这是一座美丽的波罗的海度假胜地，并乘坐渡轮前往瑞典。享受旅程。"
							},
							{
								name: "05 - 周一. 渡轮 - 尼纳斯港 - 斯德哥尔摩。",
								description: "大约中午12点抵达瑞典尼纳斯港。继续前往斯德哥尔摩。午餐时间到达。下午自由活动，建议您在其历史中心漫步并参观博物馆。"
							},
							{
								name: "06 - 周二. 斯德哥尔摩。",
								description: "斯德哥尔摩全景游览，这座美丽的首都建在14个岛屿上。"
							},
							{
								name: "06 - 周二. 斯德哥尔摩 - 莫拉。",
								description: "早餐后，进行城市全景游览。之后自由活动，漫步于这座岛屿之城。下午4点左右出发前往莫拉，这是一座位于湖泊之间的小城，有着美丽的步道。"
							},
							{
								name: "07 - 周三. 莫拉 - 富纳斯达伦 - 罗罗斯 - 特隆赫姆。",
								description: "在湖泊和森林之间，我们前往挪威。停留在受欢迎的富纳斯达伦滑雪胜地。在被誉为挪威最美丽的村庄之一的罗罗斯享用午餐。抵达特隆赫姆，时间充裕，可以在这座挪威第三大城市漫步，探访其历史可追溯到维京时代的教堂、桥梁和繁华的夜生活。"
							},
							{
								name: "08 - 周四. 特隆赫姆 - 克里斯蒂安松 - 克韦内斯 - 大西洋之路 - 莫尔德。",
								description: "今天我们将欣赏令人叹为观止的峡湾风光；一些峡湾将通过渡轮穿越，另一些将沿着被誉为挪威最美公路的大西洋之路行驶。准备好相机！我们还将参观克里斯蒂安松，这是一座美丽的城市，坐落在令人惊叹的自然环境中，参观克韦内斯木质教堂，并在莫尔德过夜，这是一座充满活力的峡湾城市。"
							},
							{
								name: "09 - 周五. 莫尔德 - 盖朗厄尔 - 布里克斯达尔冰川 - 洛恩。",
								description: "新的一天，继续探索世界遗产的挪威峡湾美景。我们将乘坐渡轮，看到白雪覆盖的山峰，并在壮观的鹰之瞭望台停留。在盖朗厄尔享有自由活动时间，这里是世界上最美的风景区之一。之后我们将前往布里克斯达尔，参观令人印象深刻的北欧冰川。住宿在小镇洛恩。晚餐包含。"
							},
							{
								name: "10 - 周六. 洛恩 - 卑尔根。",
								description: "沿着峡湾的边缘，穿过山脉和渡轮，我们在午餐时间抵达卑尔根。我们将乘坐缆车前往弗洛伊恩山，在那里可以欣赏到城市的壮丽景色。自由活动。"
							},
							{
								name: "11 - 周日. 卑尔根 - 埃德菲尤尔 - 沃灵瀑布 - 盖洛 - 奥斯陆。",
								description: "我们向挪威内陆进发，在进入高原冰雪覆盖的湖泊地区之前，停留在小镇埃德菲尤尔，这里位于其峡湾边缘，参观壮观的沃灵瀑布。在盖洛滑雪胜地享用午餐。傍晚抵达挪威首都奥斯陆。推荐在市中心散步。"
							},
							{
								name: "12 - 周一. 奥斯陆 - 渡轮。",
								description: "早餐后，进行奥斯陆全景游览，自由活动，享受这座城市。午餐后，登上夜间渡轮前往丹麦。夜宿船上。"
							},
							{
								name: "13 - 周二. 渡轮 - 哥本哈根。",
								description: "早晨抵达哥本哈根，登岸后进行丹麦首都的全景游览。下午自由活动。"
							},
							{
								name: "14 - 周三. 哥本哈根 - 罗斯托克 - 什切青。",
								description: "从斯堪的纳维亚南部的盖泽出发，我们乘坐渡轮横渡波罗的海前往德国。在罗斯托克停留，这是波罗的海最大的德国港口，拥有美丽的市中心。我们继续前往波兰，在什切青过夜，这是一座位于奥得河边的历史悠久的大学城，拥有美丽的城堡。自由活动时间。"
							},
							{
								name: "15 - 周四. 什切青 - 比得哥什 - 托伦 - 华沙。",
								description: "奥得河；其美丽的公园和运河非常适合散步。然后我们继续前往被列为世界遗产的哥特式城墙城市托伦。晚上到达波兰首都华沙。"
							},
							{
								name: "16 - 周五. 华沙。",
								description: "早餐后，结束服务。"
							}
						],
						services_included: "包括西班牙语导游的巴士旅行，基本旅行保险和自助早餐。\n" +
							"包含：\n" +
							"接机服务。\n" +
							"全景游览：华沙，斯德哥尔摩。\n" +
							"门票：奥尔什丁内克民俗建筑露天博物馆，埃尔布隆格运河博物馆，马尔堡城堡，格但斯克欧洲团结中心和二战博物馆。\n" +
							"渡轮：索波特（波兰）/ 尼纳斯港（瑞典）。\n",
						services_additional: "全景游览：斯德哥尔摩，奥斯陆。\n" +
							"门票：克韦内斯木质教堂。\n" +
							"渡轮：靠近克里斯蒂安松的峡湾，罗姆斯达尔峡湾，斯托尔峡湾。\n" +
							"缆车：卑尔根弗洛伊恩山缆车。\n" +
							"1次午餐或晚餐：洛恩。\n" +
							"全景游览：奥斯陆，哥本哈根。\n" +
							"渡轮：奥斯陆 / 哥本哈根。\n" +
							"渡轮：盖泽（丹麦）/ 罗斯托克（德国）。\n"
					}
				]
			}
		]
	}
}

export {
    awards as awards_cn,
    about_us as about_us_cn,
    destinations as destinations_cn,
    tours as tours_cn,
    footer as footer_cn,
    gastronomy as gastronomy_cn,
	menu as menu_cn,
	world_destinations as world_destinations_cn,
}