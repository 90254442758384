// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inquiries_container__KRZDj {
    margin: 100px 128px;
    text-align: left;
    color: var(--dark-text);
    font-family: "Mulish Variable", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-user-select: none;
            user-select: none;
}

.inquiries_container__KRZDj>div:nth-of-type(2) {
    flex-basis: 50%;
}

.inquiries_container__KRZDj>div:last-of-type {
    flex-basis: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.inquiries_titleContainer__Q2-IT>div:first-of-type,
.inquiries_titleContainer__Q2-IT>div:last-of-type {
    color: var(--light-gold);
}

.inquiries_titleContainer__Q2-IT>div:nth-of-type(2) {
    color: #FFFFFF;
    font-size: 38px;
    font-family: "Quicksand Variable", sans-serif;
    font-weight: 700;
}

@media (max-width: 768px) {
    .inquiries_container__KRZDj>div:first-of-type,
    .inquiries_container__KRZDj>div:last-of-type {
        display: none;
    }

    .inquiries_container__KRZDj {
        display: flex;
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/inquiries/index.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,0CAA0C;IAC1C,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;;IAEI,wBAAwB;AAC5B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,6CAA6C;IAC7C,gBAAgB;AACpB;;AAEA;IACI;;QAEI,aAAa;IACjB;;IAEA;QACI,aAAa;QACb,uBAAuB;IAC3B;AACJ","sourcesContent":[".container {\n    margin: 100px 128px;\n    text-align: left;\n    color: var(--dark-text);\n    font-family: \"Mulish Variable\", sans-serif;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    user-select: none;\n}\n\n.container>div:nth-of-type(2) {\n    flex-basis: 50%;\n}\n\n.container>div:last-of-type {\n    flex-basis: 40%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n}\n\n.titleContainer>div:first-of-type,\n.titleContainer>div:last-of-type {\n    color: var(--light-gold);\n}\n\n.titleContainer>div:nth-of-type(2) {\n    color: #FFFFFF;\n    font-size: 38px;\n    font-family: \"Quicksand Variable\", sans-serif;\n    font-weight: 700;\n}\n\n@media (max-width: 768px) {\n    .container>div:first-of-type,\n    .container>div:last-of-type {\n        display: none;\n    }\n\n    .container {\n        display: flex;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `inquiries_container__KRZDj`,
	"titleContainer": `inquiries_titleContainer__Q2-IT`
};
export default ___CSS_LOADER_EXPORT___;
