import {about_us_es, awards_es, destinations_es, tours_es, footer_es} from './es/index';
import {about_us_en, awards_en, destinations_en, tours_en, footer_en} from './en/index';
import {about_us_cn, awards_cn, destinations_cn, tours_cn, footer_cn} from './cn/index';


const peru_name = ["Perú", "秘鲁", "Peru", "秘鲁"];

const SPANISH_INDEX = 0;
const ENGLISH_INDEX = 1;
const CHINESE_INDEX = 2;

const LANGUAGE_INDEXES = {
    spanish: SPANISH_INDEX,
    english: ENGLISH_INDEX,
    chinese: CHINESE_INDEX
};

const data = {
    about_us: [about_us_es, about_us_en, about_us_cn],
    awards: [awards_es, awards_en, awards_cn],
    destinations: [destinations_es, destinations_en, destinations_cn],
    tours: [tours_es, tours_en, tours_cn],
    footer: [footer_es, footer_en, footer_cn]
}

function getCurrentLanguageIndex() {
    const languageIndex = localStorage.getItem("language_index");
    if (languageIndex === null) {
        return CHINESE_INDEX;
    }
    return isNaN(parseInt(languageIndex)) ? ENGLISH_INDEX : languageIndex; // Default to English if not set
}

function getString(string, index = -1) {
    const languageIndex = getCurrentLanguageIndex();

    if(index === -1) {
        return data[string][languageIndex];
    }
    return data[string][languageIndex][index];
}

export {
    peru_name,
    getString,
    LANGUAGE_INDEXES
};