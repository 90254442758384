import React, {useState} from "react";
import MainLoading from "../../components/main_loading";
import Footer from "../../components/footer";
import Inquiries from "../../components/inquiries";
import Welcome from "../../components/welcome";
import Highlights from "../../components/highlights";
import Destinations from "../../components/destinations";


export default function Home() {
    const [loaded, setLoaded] = useState(false);
    return (
      <div>
          {!loaded ?
              <MainLoading setLoaded={setLoaded}/>
          :
              <>
                  <Welcome/>
                  <Highlights/>
                  <Destinations/>
                  {/*<Inquiries/>*/}
                  <Footer/>
              </>
          }
      </div>
    );
}