const about_us = {
    title: "About Us",
    name: "Group Tay's",
    description: "Founded in 1998, Group Tay's, which includes Tay's World and Tay's Mundo, was established by our founder, Alicia Tay, who, motivated by her own experience in the tourism sector, set out to raise the quality of travel services. We specialize in providing enriching travel experiences for educators and academics, connecting them with global destinations and fostering cross-cultural understanding. The surname Tay is a tribute to the family heritage, in particular to Jorge Tay, an immigrant who was awarded a medal of honor for his outstanding contributions."
};

const awards = [
    {title: "World's Best Cultural Destination", description: "Peru has been awarded \"World's Best Cultural Destination\" at the World Travel Awards. With its impressive archaeological sites such as Machu Picchu, the Nazca Lines and the sacred city of Caral, Peru is a paradise for history and culture lovers. Immerse yourself in the legacy of millenary civilizations and experience the magic of the past!"},
    {title: "Best Tourist Attraction in the World", description: "Machu Picchu has been named the \"World's Best Tourist Attraction\" on multiple occasions. This amazing archaeological site, located high in the Andes, is a breathtaking wonder for all visitors. Walk through its ancient terraces and discover the secrets of the Inca civilization in this magical destination."},
    {title: "Best Adventure Destination in the World", description: "Peru has been awarded as the \"World's Best Adventure Destination\" thanks to its incredible geography and diversity of activities. From hiking the Inca Trail and surfing the beaches of Mancora, to sandboarding the dunes of Huacachina and rafting the Urubamba River, Peru is the perfect place for adrenaline lovers."},
    {title: "Best Historical Destination", description: "Peru has earned the title of \"Best Historical Destination\" for its numerous well-preserved archaeological sites. From the ruins of Chan Chan, the world's largest mud city, to the mysterious Kuelap Fortress, every corner of the country is full of stories to discover. Relive history in the heart of South America!"},
    {title: "Best Birding Destination", description: "Peru is a birdwatcher's paradise and has been recognized as the \"Best Birding Destination\". With more than 1,800 species of birds, including hummingbirds, condors and endemic birds, Peru offers incredible opportunities for birdwatchers - get ready for a spectacle of color and song!"},
    {title: "Best Gastronomic Destination in South America", description: "In addition to the global awards, Peru has been named the \"Best Gastronomic Destination in South America\". Its cuisine fuses indigenous traditions with European, Asian and African influences, creating a unique culinary experience. Taste the richness of flavors that have put Peru on the world's gastronomic map!"}
]

const destinations = [
    {title: "Cuzco", description: "Marvel at Cuzco, the heart of the ancient Inca Empire, a city that is itself a living work of art. Splendid in its mix of Inca and colonial architecture, every street and square tells stories of a glorious past. Discover the spectacular Temple of the Sun, Qoricancha, and explore the ruins of Sacsayhuaman, where history is palpable."},
    {title: "Machu Picchu", description: "Don't miss the crown jewel, Machu Picchu, a wonder of the world that rises majestically amidst misty mountains, offering breathtaking views and a magical atmosphere that can rarely be experienced in a lifetime. This sacred sanctuary, accessible from Cuzco via a spectacular train ride or by following the unforgettable Inca Trail, promises an adventure that is, without a doubt, unforgettable. Cuzco and Machu Picchu together offer not only a window into the Inca past, but also a profound experience that touches the soul of every visitor."},
    {title: "Puno", description: "Discover splendid Puno, the gateway to mystical Lake Titicaca, the highest navigable lake in the world. Immerse yourself in a rich culture and witness vibrant festivities that are simply unforgettable. Puno is not just a destination, it is a living cultural wonder where past and present meet in every dance and tradition."},
    {title: "Lima", description: "Peru's vibrant capital, Lima is a splendid mosaic of history, culture and modernity. From the majesty of its historic center to the modern waterfront of Miraflores and Barranco, Lima offers an unparalleled urban experience. Delight in its renowned gastronomy, which will take you on a spectacular culinary journey."},
    {title: "Paracas - Ica", description: "A marine oasis on the coast of the Peruvian desert, Paracas is simply wonderful. With its impressive National Reserve harboring an amazing biological diversity and the mysterious Nazca Lines nearby, Paracas is perfect for those looking to combine adventure and relaxation in a unique natural environment. \n" +
            "\n" +
            "A short distance away, Ica invites you to discover its vast vineyards and taste pisco, the emblematic Peruvian liquor. The city is also the gateway to the mysterious Huacachina Lagoon, a spectacular oasis surrounded by huge sand dunes where you can practice sandboarding and buggy rides."},
    {title: "Iquitos", description: "Beyond its boundaries, embark on exciting ecological excursions: navigate mysterious rivers, observe pink dolphins, and connect with indigenous communities that will enrich your understanding of the Amazon ecosystem. Iquitos offers an essential and spectacular experience for adventurers and nature lovers."},
    {title: "Huaraz", description: "An adventurer's paradise, Huaraz offers a spectacular natural experience with its towering snow-capped peaks and turquoise lagoons in the Huascaran National Park. It is an ideal destination for trekking, mountaineering and nature lovers, promising unforgettable adventures on every trail."}
]

const tours = [
    {title: "Cusco Enchantment Program + Sacred Valley Tour", d: "3D", n: "2N", days: [
            {title: "Day 1: Machu Picchu Excursion", description: "Reception in Cusco and transfer from the airport to the Train Station, where passengers can  take the train to Machu Picchu. \n" +
                    "During the journey, you will appreciate the beautiful vegetation characteristic of the High  Jungle and majestic snow-capped mountains, pass through tunnels, and finally arrive at Aguas  Calientes station. From there, we will go to the Bus Station where we will board the tourist  bus that will take us to the Machu Picchu citadel (approximately 30 minutes journey).  Subsequently, a guided tour of Machu Picchu will be provided for about 2 hours. Within the  Archaeological Site, we will visit the most important places such as plazas, temples,  warehouses, stairways, water sources, terraces, watchtowers, cemeteries, as well as the  Temple of the Sun, the Intiwatana (Sun Dial), the Temple of the Three Windows, among other  attractions. After the guided tour, you will have time for lunch at a tourist restaurant. At the  scheduled time, board the return train and transfer to your hotel in Cusco." },
            {title: "Day 2: Sacred Valley of the Incas Excursion", description: "Breakfast at the Hotel.\n This tour starts with the pick-up of passengers from their hotel at around 7:30 am. Then, we  head to the Sacred Valley (1 ½ hours journey) to visit the most important places in the  Valley. We will begin by visiting the archaeological center of Pisac, from where you can  appreciate the impressive terraces from the Inca period and the town itself. Continuing the  journey through the Sacred Valley of the Incas, we make a stop for a buffet lunch at a tourist  restaurant. After lunch (about 30 minutes journey), we will enjoy the fortress of  Ollantaytambo and observe the agricultural areas, highlighting the cultivation of corn  without irrigation, as well as Andean tubers and cereals. Then we will move to the viewpoint  of Chinchero, the last point of the tour. This town is a beautiful example of mestizo and  colonial style. At the indicated time, return to Cusco, arriving at approximately 6:00 pm."},
            {title: "Day 3: AM City Tour", description: "Breakfast at the Hotel.\n Between 08:00 hrs and 08:20 hrs, our transfer will pick you up at your hotel to take you to the  starting point of your tour. The tour begins at the Cusco Cathedral, followed by a short walk  through the streets of the historic center towards Qoricancha or Temple of the Sun,  considered an indigenous religious sanctuary. Then, we will board the transport that will take  us to Sacsayhuaman, located 3km from the city of Cusco, offering us a panoramic view of the  city. Next, we will visit Qenqo, which means labyrinth and is located one kilometer from  Sacsayhuaman. Afterwards, we will explore Puca Pucara, which means \"red fortress\" in  Quechua. Finally, we will visit Tambomachay. The tour concludes in the city of Cusco at  approximately 1:45 pm."},
        ]},
    {
        title: "Cusco Charm", d: "4D", n: "3N", days: [
            {
                title: "Day 1: City Tours Excursion",
                description: "Reception in Cusco and transfer from the airport to the hotel of your choice. \n Between 12:45 hrs and 1:00 hrs our transfer will visit you at your Hotel to transfer you to the  starting point of your Tour. The tour begins at the Cathedral of Cusco. Next, we will take a  short walk through the streets of the historic center towards the Qoricancha or Temple of the  Sun, considered an indigenous religious sanctuary. Then we will board the transportation that  will take us to Sacsayhuaman located 3km away. from the city of Cusco, Sacsayhuaman offers  us a panoramic view of the city of Cusco Then we will go to Qenqo, which means labyrinth, it  is located one kilometer from Sacsayhuaman, then we will visit Puca Pucara: coming from two  Quechua words that mean Puca ( red) Pucara (fortress) “red fortress. Finally we will visit  Tambomachay. Ending in the city of Cusco at approximately 6:30 p.m."
            },
            {
                title: "Day 2: Mountain of Colors",
                description: "• 04:30 hrs: Pick up from your hotel, start of the tour to the Full Day Montaña de Colores. • 05:00 hrs: Transfer by tourist bus to the Cusipata district.\n" +
                    "• 06:30 hrs: Arrival in Chirihuani (Cusipata), breakfast and break.\n" +
                    "• 08:30 hrs: Arrival in Phulawasipata.\n" +
                    "• 08:45 hrs: Start of the walk to the Vinicunca Colored Mountain.\n" +
                    "• 10:30 hrs: Exploration as photographs in Vinicunca, review and explanation by the guide. • 11:30 hrs: Walk to Phulawasipata and return to Cusipata.\n" +
                    "• 2:00 p.m.: Free time for lunch (included).\n" +
                    "• 3:00 p.m.: Departure time of the bus from Cusipata to Cusco.\n" +
                    "• 4:30 p.m.: Arrival in the city of Cusco."
            },
            {
                title: "Day 3: Machu Picchu Excursion",
                description: "Breakfast at the hotel. \n At the indicated time, transfer from your Hotel to the train station where your trip to Machu  Picchu begins by train of your choice. During the tour you will appreciate the beautiful  vegetation characteristic of the High Jungle and majestic snow-capped mountains, you will go  through tunnels and finally arrive at the Aguas Calientes station. We will go to the bus station  where we will board the tourist transport bus that will take us to the citadel of Machupicchu.  ¸ (½ hour trip approx.) Later you will be guided inside Machu Picchu for approx. 2 hours, within  the Archaeological site we will visit the most important places such as the Plazas, temples,  tanks, stairs, water sources, platforms and surveillance posts, cemeteries, as well as the  temple of the sun, the Intiwatana (Sun Clock) the temple of the three windows among other  charms. After the guided tour you will have time for lunch at a tourist restaurant. At the  indicated time, board the return train and transfer to your hotel in Cusco."
            },
            {
                title: "Day 4: Airport transfer", description: "Breakfast at the hotel\n" +
                    "At the indicated time, departure transfer.\n" +
                    "End of services."

            }
        ]
    },
    {
        title: "Taquile Dream (Puno)", d: "3D", n: "2N", days: [
            {title: "Day 1: PM Exc. CHULLPAS DE SILLUSTANI", description: "Arrival in the city of Puno and transfer to the chosen hotel.\n They are monumental pre-Inca funerary buildings, some of which  are up to 12 meters high. Carved in volcanic stone, La Chullpa del Lagarto, La de los 12 angles. The  entire archaeological complex is located on the shores of Lake UMAYO, which underground  communicates with Lake Titicaca. At the indicated time, return to Puno and transfer to your Hotel."},
            {title: "Day 2: Exc. UROS Y TAQUILE", description: "07:00 Hrs. Transfer from the hotel to the port, to board our motor boat and visit the floating islands  of Los Uros, which are approximately 30 minutes away. After a brief visit (approx. 40 minutes), we will  continue the trip to the Natural Island of Taquile. Whose residents are mainly dedicated to agriculture,  crafts and fishing. The locals continue to live according to their ancient Inca traditions and laws with  customs, religious and folklore rituals such as: Marriage (Silvinacuy), payment to the land (Pacha  mama).\n" +
                "12.00 hrs. (half day), Typical lunch on the island.\n" +
                "After lunch: Passengers will have two free hours to visit the town's craft center and take photographs  with panoramic views and then return to the city of Puno (to their respective hotels).\n"},
            {title: "Day 3: Airport transfer", description: "Breakfast at the hotel.\n At the indicated time, transfer to the airport or bus station."}
        ]
    },
    {
        title: "Adventure in the Amazon Jungle", d: "3D", n: "2N", days: [
            {title: "Day 1: Iquitos - Ceiba Tops", description: "Arrival at the airport or hotel and transfer to the Explorama pier. A 40km boat ride along the Amazon  River takes you to Ceiba Tops, where air-conditioned rooms with private hot-water bathrooms provide  jungle-home-like comfort. In the afternoon, enjoy a hike through the jungle surrounded by pristine  forest, home to the giant ceiba trees, after which the lodge is named. After dinner, take a night walk  to observe insects, frogs, tarantulas and listen to the sounds of the jungle.\n" +
                    "• Meals: Lunch, Dinner\n" +
                    "• Accommodation: Luxury Lodge in the Jungle\n"},
            {title: "Day 2: Ceiba Tops", description: "Early morning bird watching by boat and sunrise over the Amazon River. Return to Ceiba Tops for  breakfast. Visit to the meeting point of two rivers, the gray and pink rivers. Next, visit a small Yagua  indigenous community where the guide will explain the Yagua culture. Enjoy demonstrations of local  crafts and blowgun hunting techniques. In the afternoon, take a fishing trip and, weather permitting,  enjoy a sunset over the Amazon River.\n" +
                    "• Meals: Breakfast, Lunch, Dinner\n" +
                    "• Accommodation: Luxury Lodge in the Jungle"},
            {title: "Day 3: Ceiba Tops - Iquitos", description: "Travel by boat to a riverside town where the guide will explain the culture of the people of the Peruvian  Amazon River and visit the town school. Return to Iquitos, then to Lima or to the hotel of your choice.\n" +
                    "•  Meals: Breakfast, Lunch (if your flight is in the afternoon)\n" +
                    "Note: The itinerary may change due to weather conditions."}
        ]
    }
]

const footer = {
    contact_us: "Contact Us",
    about_us: "About Us",
    destinations: "Destinations",
    email_us: "Email Us",
}

export {
    awards as awards_en,
    about_us as about_us_en,
    destinations as destinations_en,
    tours as tours_en,
    footer as footer_en
}